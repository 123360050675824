import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as actions from './actions'

export type State = {
	isOpen: boolean
}

export const initialState: State = {
	isOpen: false,
}

export default reducerWithInitialState(initialState)
	.case(actions.toggleDrawer, (state) => {
		return {
			...state,
			isOpen: !state.isOpen,
		}
	})
	.case(actions.changeDrawer, (state, payload) => {
		return {
			...state,
			...payload,
		}
	})
