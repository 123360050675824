import * as React from 'react'
import NavBar from '../../components/NavBar/index'
import MachinePage from '../MachinePage'

function DemoMachinePage() {
	return (
		<>
			<NavBar
				doLogout={() => {
					alert('サンプルページです')
				}}
				onClickMenu={() => {
					alert('サンプルページです')
				}}
			/>
			<MachinePage />
		</>
	)
}

export default DemoMachinePage
