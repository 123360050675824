import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTrigger } from '../../store/HouseContainer/operations'
import { getTriggers } from '../../store/TriggerById/selectors'
import { House } from '../../types'
import TriggerList from './components/TriggerList'

type Props = {
	house: House
	handleCreateTrigger: (sensorId: number) => void
}

const TriggerListContainer = (props: Props) => {
	const triggers = useSelector((state) => getTriggers(state, props.house.id))
	const dispatch = useDispatch()

	return (
		<TriggerList
			triggers={triggers}
			handleDeleteTrigger={(triggerId: number) => {
				dispatch(deleteTrigger(triggerId))
			}}
			handleCreateTrigger={props.handleCreateTrigger}
		/>
	)
}

export default TriggerListContainer
