import {
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import _, { range } from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import { getEventHeating24 } from '../../store/EventById/selectors'
import { updateOrCreateEventHeating } from '../../store/HouseContainer/operations'
import { getActiveSubControl } from '../../store/SubControlById/selectors'
import { Control, EventHeating, House, Scenario } from '../../types'
import EditHeatingEventForm from './components/EditHeatingEventForm'

type Props = {
	house: House
	scenario: Scenario
	control: Control
}

function HeatingManageTab({ scenario, control }: Props) {
	const activeSubc = useSelector((s) => getActiveSubControl(s, control))
	const eventById = useSelector((s) =>
		getEventHeating24(s, scenario.eventIds, activeSubc),
	)

	return (
		<Container>
			<div style={{ marginTop: '8px' }}>
				<div>
					<TableContainer component={Paper}>
						<Table>
							<TableBody>
								{range(24).map((h) => (
									<EventHeatingTableRow
										key={h}
										h={h}
										ev={eventById[h]}
										scenarioId={scenario.id}
										subControlId={activeSubc.id}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</div>
		</Container>
	)
}

type RowProps = {
	h: number
	ev: EventHeating
	scenarioId: number
	subControlId: number
}
const EventHeatingTableRow = (props: RowProps) => {
	const [open, setOpen] = React.useState(false)
	const dispatch = useDispatch()
	const { h, ev, scenarioId, subControlId } = props

	const enableStyle = ev.enabled ? {} : { background: '#DDD' }

	return (
		<TableRow style={{ ...enableStyle }}>
			<TableCell>
				{h}時〜{h + 1}時
			</TableCell>
			<TableCell>{ev.tmpAbove}℃</TableCell>
			<TableCell>
				<Button variant="outlined" onClick={() => setOpen(true)}>
					<SettingsIcon />
				</Button>
				<Modal open={open} closeModal={() => setOpen(false)}>
					<EditHeatingEventForm
						initialValues={_.omit(
							{ ...ev, scenarioId: scenarioId, subControlId },
							['id'],
						)}
						disabled={false}
						handleSubmit={(field) => {
							dispatch(updateOrCreateEventHeating(field, ev.id))
							setOpen(false)
						}}
					/>
				</Modal>
			</TableCell>
		</TableRow>
	)
}

export default HeatingManageTab
