import { Computer, MobileFriendly } from '@mui/icons-material'
import {
	Alert,
	Box,
	Card,
	CardContent,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material'
import * as React from 'react'
import { hardwareSpecLink, softwareSpecLink } from '../../../constants'
import theme from '../../../theme'
import Init from '../../App/Init'

type Props = {}

function AboutPage(_props: Props) {
	return (
		<div
			style={{
				width: 'auto',
				display: 'block',
				marginLeft: theme.spacing(3),
				marginRight: theme.spacing(3),
			}}
		>
			<Init />
			<Grid container justifyContent="center" alignItems="center">
				<Grid item xs={12} sm={10} lg={12}>
					<Typography
						variant="h3"
						style={{ fontSize: '2rem', margin: '1rem 0' }}
					>
						PocketFarmについて
					</Typography>
				</Grid>
				<Grid item xs={12} sm={5}>
					<Box>
						<Typography variant="h4">動作環境</Typography>
						<List>
							{['Android', 'iOS', 'Windows', 'MacOS'].map((hard, i) => (
								<ListItem key={hard}>
									<ListItemIcon>
										{i >= 2 ? <Computer /> : <MobileFriendly />}
									</ListItemIcon>
									<ListItemText>{hard}:GoogleChrome97以上</ListItemText>
								</ListItem>
							))}
						</List>
					</Box>
				</Grid>
				<Grid item xs={12} sm={5}>
					<Box>
						<Typography variant="h4">仕様書</Typography>
						<Typography>
							<List>
								<ListItem>
									<a href={softwareSpecLink} color="primary">
										ポケットファームwebシステム画面・機能仕様書
									</a>
								</ListItem>
								<ListItem>
									<a href={hardwareSpecLink} color="primary">
										環境制御装置ポケットファーム仕様書
									</a>
								</ListItem>
							</List>
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Box>
						<Typography variant="h4">料金表</Typography>
						<Card>
							<CardContent style={{ textAlign: 'center' }}>
								<Typography variant="h6" style={{ fontWeight: 'bold' }}>
									ポケットファームクラウドサービス
								</Typography>
								<Typography variant="h4">月額2,500円(税込)</Typography>
								<Alert severity="info">
									<Typography variant="caption">
										環境制御装置ポケットファーム1台につき1契約必要となります。
										ログの保存期間は最大で2年間となります
									</Typography>
								</Alert>
								<Box sx={{ textAlign: 'right' }}>
									<Typography style={{ textAlign: 'right' }} variant="caption">
										(2020年11月1日制定)
									</Typography>
								</Box>
							</CardContent>
						</Card>
					</Box>
				</Grid>
			</Grid>
		</div>
	)
}

export default AboutPage
