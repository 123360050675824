import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { DayLogById } from '../../types'
import * as actions from './actions'

export type State = { [id: string]: DayLogById }

export const initialState: State = {}

export default reducerWithInitialState(initialState).case(
	actions.receiveLogs,
	(state, action) => {
		return { ...state, [action.label]: action.day }
	},
)
