import { Box, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { CategoryIcon } from '../../components/MachineTable/MachineIcon'
import { getControl } from '../../store/ControlById/selectors'
import { PartLog } from '../../types'
import { categoryStr } from '../../utils'
import { hexPinCode, toBitPins, toHms } from '../../utils/businessUtil'

export type Props = {
	plog: PartLog
}

export default function PartLogCard({ plog }: Props) {
	const control = useSelector((s) => getControl(s, plog.alog.controlId))
	const actionLog = plog.alog

	const runRate = Math.floor(
		plog.alog.endRate || 0 - (plog.alog.startRate || 0),
	)

	return (
		<>
			<div
				className="line"
				style={{
					paddingLeft: `${plog.alog.startRate}%`,
					fontSize: '4px',
				}}
			>
				<div
					className="line-body"
					style={{
						width: `${runRate}%`,
						background: 'gray',
						borderRadius:
							plog.timing === 'start' ? '8px 0 0 8px' : '0 8px 8px 0',
					}}
				>
					{runRate}
				</div>
			</div>
			<LogBox
				className="plog-card"
				data-tag={actionLog.tag}
				data-cancelled={actionLog.cancelled}
				data-progress={actionLog.progress}
				data-timing={plog.timing}
			>
				<div className="head">
					<CategoryIcon
						category={control.category}
						stepRate={6}
						style={{ width: '24px', height: '24px' }}
					/>
					<img
						src={`https://avatars.dicebear.com/api/jdenticon/${plog.alog.id}.svg`}
						alt={plog.id}
					/>
					<Typography className={'labe'}>
						{categoryStr[control.category]}
						{control.label}
					</Typography>

					<Typography className={'time'}>P {toHms(plog.time)}</Typography>
					<Typography className={'time-schedule'}>
						S {toHms(plog.scheduleTime)}
					</Typography>
					<Typography className={'time'}>
						C {toHms(plog.alog.createdAt)}
					</Typography>
					<Typography className={'time-schedule'}>
						U {toHms(plog.alog.updatedAt)}
					</Typography>
				</div>
				<div className="tail">
					<Typography className={'rt'}>{plog.alog.runningTime}s</Typography>
					<Typography className={'rate'}>{plog.rate}</Typography>
					<Typography className={'pins'}>
						<LinePinView pin={plog.pin} />
					</Typography>
				</div>
			</LogBox>
		</>
	)
}

const LinePinView = ({ pin }: { pin?: number }) => {
	if (typeof pin !== 'number') return <span>-</span>
	return (
		<Box style={{ display: 'grid', gridTemplateColumns: '100px 100px' }}>
			<span>[{hexPinCode(pin)}]</span>
			<div>
				{toBitPins(pin).map((b, i) => (
					<span key={i}>{b ? '1' : '0'}</span>
				))}
			</div>
		</Box>
	)
}

const LogBox = styled.div`
	border: solid 0.5px black;
	width: 100%;
	overflow: scroll;
	padding: 4px;
	margin: 10px 0;
	.head {
		column-gap: 4px;
		display: grid;
		/* grid-template-areas: 'icon labe tm-r tm-s rate pins'; */
		grid-template-columns: 30px 20px 120px 120px repeat(4, 120px);
	}
	.tail {
		display: grid;
		grid-template-columns: 50px 100px 1fr;
	}
	.time-schedule {
		color: #666;
	}

	.trig-time {
		font-size: 0.8rem;
		color: gray;
	}

	&[data-timing='start'] {
		margin-right: 1rem;
		.rate::after {
			content: '→';
		}
	}
	&[data-timing='end'] {
		margin-left: 1rem;
		.rate::before {
			content: '→';
		}
	}
`
