import * as React from 'react'
import { connect } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import CreateControlForm from '../../components/ControlForm/CreateControlForm'
import EditModeButton from '../../components/EditModeButton'

import PinState from '../../components/PinState'
import Modal from '../../components/Modal'
import { getControls } from '../../store/ControlById/selectors'
import { getHouse } from '../../store/HouseById/selectors'
import { createControl } from '../../store/HouseContainer/operations'
import { toggleEditControl } from '../../store/SettingContainer/operations'
import { Control, CreateControlField, House, State } from '../../types'
import { t } from '../../locale'
import ControlRow from './ControlRow'

type OProps = {
	houseId: number
}

type Props = {
	house: House
	controls: Control[]
	disabled: boolean
	editMode: boolean

	createControl: (control: CreateControlField) => void
	toggleEditControl: () => void
}

function ControlList(props: Props) {
	const [open, setOpen] = React.useState(false)

	return (
		<div>
			<div style={{ display: 'flex' }}>
				<Typography variant="h5" style={{ flex: 1 }}>
					機器一覧
				</Typography>
				<Button onClick={() => setOpen(true)}>
					<AddIcon /> 追加
				</Button>
				<EditModeButton
					handleClick={props.toggleEditControl}
					editMode={props.editMode}
					props={{
						'data-test': 'control-edit-btn',
					}}
				/>
			</div>
			{props.controls.length === 0 && (
				<Typography variant="subtitle1">
					{t.control.base}がありません
				</Typography>
			)}
			<List component="nav">
				{props.controls.map((control, i) => (
					<ControlRow key={i} keyi={`${i}`} control={control} />
				))}
			</List>
			<PinState house={props.house} />
			<Modal open={open} closeModal={() => setOpen(false)}>
				<CreateControlForm
					disabled={props.disabled}
					handleSubmit={(v) => {
						props.createControl({
							...v,
							houseId: props.house.id,
						})
						setOpen(false)
					}}
				/>
			</Modal>
		</div>
	)
}

const ms = (state: State, op: OProps) => ({
	house: getHouse(state, op.houseId),
	controls: getControls(state, op.houseId),
	disabled: state.Network.isLoading,
	editMode: state.SettingContainer.editControl,
})

const conn = connect(ms, {
	createControl,
	toggleEditControl,
})

export default conn(ControlList)
