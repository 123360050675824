import styled from 'styled-components'
import { useGraphConf } from '../../hooks/useLocalConfig'
import { DayChartLine, Log } from '../../types'
import SunsetBar from '../SunsetBar/SunsetBarWrap'
import DayChartGraph from './DayChartGraph'
import DayChartGraphNew from './DayChartGraphNew'

type Props = {
	logs: Log[]
	day: string
	yTicks?: number[] | undefined
	lines: DayChartLine[]
}

const DayChart = (props: Props) => {
	const { enabledGraphV2 } = useGraphConf()

	return (
		<Style>
			<div style={{ position: 'relative' }}>
				<SunsetBar day={props.day} />
				<div style={{ paddingTop: '1em' }}>
					{enabledGraphV2 ? (
						<DayChartGraphNew {...props} />
					) : (
						<DayChartGraph {...props} />
					)}
				</div>
			</div>
		</Style>
	)
}

const Style = styled.div`
	.recharts-cartesian-axis-tick-value tspan {
		font-size: 0.5rem;
	}
`

DayChart.defaultProps = {
	yTicks: undefined,
}

export default DayChart
