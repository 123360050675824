import { Create } from '@mui/icons-material'
import AlarmIcon from '@mui/icons-material/Alarm'
import DownIcon from '@mui/icons-material/ArrowDownward'
import UpIcon from '@mui/icons-material/ArrowUpward'
import { IconButton, LinearProgress } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditControlForm from '../../components/ControlForm/EditControlForm'
import { CategoryIcon } from '../../components/MachineTable/MachineIcon'
import Modal from '../../components/Modal'
import {
	deleteControl,
	updateControl,
} from '../../store/HouseContainer/operations'
import { getRequestIsLoading } from '../../store/Network/selectors'
import { getEditControl } from '../../store/SettingContainer/selectors'
import { Control } from '../../types'
import { isBoolMachineCategory } from '../../utils'
import BoolMachineToogleButton from './BoolMachineToogleButton'
import SubControlList from './SubControlList'

type OProps = {
	control: Control
	keyi: string
}

type Props = {
	control: Control
	keyi: string
	disabled: boolean
	editMode: boolean
	deleteControl: () => void
	openConfig: () => void
}

const ControlRowShow = ({ control, openConfig }: Props) => {
	return (
		<div style={{ width: '100%' }}>
			<div style={{ display: 'flex' }}>
				<Typography variant="h6" style={{ paddingLeft: '0', flex: 1 }}>
					<CategoryIcon
						category={control.category}
						stepRate={6}
						style={{ width: '1.2rem', height: '1.2rem', margin: '8px 4px 0 0' }}
					/>
					{control.label}
				</Typography>
				<IconButton onClick={() => openConfig()}>
					<Create />
				</IconButton>
				<div style={{ padding: '5px' }}>
					{control.timer && (
						<Chip
							avatar={
								<Avatar>
									<AlarmIcon />
								</Avatar>
							}
							label={control.timer}
						/>
					)}
				</div>
				{isBoolMachineCategory(control.category) && (
					<BoolMachineToogleButton control={control} />
				)}
			</div>

			{!isBoolMachineCategory(control.category) && (
				<>
					<div style={{ display: 'flex', width: '50vw' }}>
						<Typography variant="subtitle1" style={{ paddingLeft: '0' }}>
							状態: {control.rate}%開
						</Typography>
						<LinearProgress
							variant="determinate"
							value={control.rate}
							style={{ margin: '0.4rem 0 0 0.4rem', flexGrow: 1 }}
						/>
					</div>
				</>
			)}
		</div>
	)
}

const ControlRowEdit = (props: Props) => {
	return (
		<>
			<div style={{ flex: 1, display: 'flex' }}>
				<Typography variant="h6" style={{ paddingLeft: '0', flex: 1 }}>
					{props.control.label}
				</Typography>
			</div>
			{props.control.activePinView && (
				<Chip
					avatar={
						<Avatar>
							<UpIcon />
						</Avatar>
					}
					label={props.control.activePinView}
				/>
			)}
			{props.control.negativePinView && (
				<Chip
					avatar={
						<Avatar>
							<DownIcon />
						</Avatar>
					}
					label={props.control.negativePinView}
				/>
			)}
			<Button
				disabled={props.disabled}
				variant="contained"
				color="secondary"
				onClick={props.deleteControl}
				data-test={`control-del-btn-${props.keyi}`}
			>
				削除する
			</Button>
		</>
	)
}

const ControlRowBase = (props: Omit<Props, 'openConfig'>) => {
	const [open, setOpen] = React.useState(false)
	const dispath = useDispatch()
	const { control } = props

	return (
		<>
			<ListItem
				data-test={`control-item-${control.id}`}
				// data-hit={props.control.isHit}
				style={{
					borderTop: 'solid 1px black',
					paddingLeft: '0px',
					paddingRight: '0px',
					background: control.isHit ? '#d2ffd2' : '#fff',
				}}
			>
				{props.editMode ? (
					<ControlRowEdit {...props} openConfig={() => setOpen(true)} />
				) : (
					<ControlRowShow {...props} openConfig={() => setOpen(true)} />
				)}
			</ListItem>
			{!isBoolMachineCategory(props.control.category) && (
				<SubControlList control={props.control} keyi={props.keyi} />
			)}
			<Modal open={open} closeModal={() => setOpen(false)}>
				<EditControlForm
					disabled={props.disabled}
					initialValues={{
						..._.omit(control, ['id']),
						activePin: control.activePin + 1 || null,
						negativePin: control.negativePin + 1 || null,
					}}
					handleSubmit={(v) => {
						dispath(
							updateControl(
								{
									...v,
									houseId: control.houseId,
								},
								control.id,
							),
						)
						setOpen(false)
					}}
				/>
			</Modal>
		</>
	)
}

function ControlRow(props: OProps) {
	const disabled = useSelector(getRequestIsLoading)
	const editMode = useSelector(getEditControl)
	const dispatch = useDispatch()

	return (
		<ControlRowBase
			{...props}
			disabled={disabled}
			editMode={editMode}
			deleteControl={() => dispatch(deleteControl(props.control))}
		/>
	)
}

export default ControlRow
