import _ from 'lodash'
import { Control, State } from '../../types'
import { compControl } from '../../utils'
import { getHouse } from '../HouseById/selectors'

export function getControl(state: State, id: number): Control {
	return state.ControlById[id]
}

export function getControls(state: State, houseId: number): Control[] {
	const house = getHouse(state, houseId)

	return _.sortBy(
		house.controlIds.map((id) => getControl(state, id)),
		compControl,
	)
}
