import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { t } from '../../locale'
import { getHouse } from '../../store/HouseById/selectors'
import { loadHouses } from '../../store/HouseContainer/operations'
import { getScenario } from '../../store/ScenarioById/selectors'
import Layout from '../App/Layout'
import ControlTableContainer from '../HouseContainer/ControlTableContainer'

function ScenarioPage() {
	const history = useNavigate()
	const params = useParams()
	const hid = Number(params.hid)
	const sid = Number(params.sid)

	console.log({ hid, sid, params })

	const house = useSelector((s) => getHouse(s, hid))
	const scenario = useSelector((s) => getScenario(s, sid))
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(loadHouses())
	}, [dispatch])

	if (!scenario) {
		return (
			<div>
				<Typography variant="h4" style={{ flex: 1 }}>
					{t.scenario.base}は削除されたか存在しません
				</Typography>
				<Button onClick={() => history(`/houses/${house.id}`)}>
					←{house.name}
				</Button>
			</div>
		)
	}
	return (
		<div>
			<Grid container justifyContent={'center'}>
				<Grid item xs={12} sm={12} md={10}>
					<div style={{ display: 'flex' }}>
						<Typography variant="h4" style={{ flex: 1 }}>
							{t.scenario.base}: {scenario.name}
						</Typography>
					</div>
					<Button
						onClick={() => history(`/houses/${house.id}`)}
						data-test={`back-house-link`}
					>
						←{house.name}
					</Button>

					<Typography variant="h5">機器一覧</Typography>
					<ControlTableContainer house={house} scenario={scenario} />
				</Grid>
			</Grid>
		</div>
	)
}

export const ScenarioPageRoot = () => (
	<Layout>
		<ScenarioPage />
	</Layout>
)

export default ScenarioPage
