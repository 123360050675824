import Button from '@mui/material/Button'
import SettingsIcon from '@mui/icons-material/Settings'
import * as React from 'react'

type Props = {
	handleClick: (p: unknown) => void
	editMode: boolean
	props?: unknown
}

const EditModeButton = (props: Props) => (
	<Button
		variant="contained"
		color={props.editMode ? 'primary' : 'inherit'}
		size="small"
		onClick={props.handleClick}
		{...props.props}
	>
		<SettingsIcon />
		{props.editMode ? '完了' : '編集'}
	</Button>
)

export default EditModeButton
