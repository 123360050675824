import {
	Air,
	BubbleChart,
	BubbleChartOutlined,
	Router,
	RouterOutlined,
} from '@mui/icons-material'
import React, { PropsWithChildren } from 'react'
import { defaultProps } from 'recompose'
import { MachineCategory, StepRate } from '../../types'
import {
	Curtain1,
	Curtain2,
	Curtain3,
	Curtain4,
	Curtain5,
	CurtainOff,
	CurtainOn,
	HeaterOff,
	HeaterOn,
	MistOff,
	MistOn,
	Window1,
	Window2,
	Window3,
	Window4,
	Window5,
	WindowOff,
	WindowOn,
} from './IconsSet'

const CURTAIN_ICONS_MAP = [
	CurtainOff,
	Curtain1,
	Curtain2,
	Curtain3,
	Curtain4,
	Curtain5,
	CurtainOn,
]
const WINDOW_ICONS_MAP = [
	WindowOff,
	Window1,
	Window2,
	Window3,
	Window4,
	Window5,
	WindowOn,
]

const withGrayFont = defaultProps({ className: 'off-icon' })
const FanOff = withGrayFont(Air)
const Co2Off = withGrayFont(BubbleChartOutlined)
const RouterOff = withGrayFont(RouterOutlined)

export function machineCategoryIcon(
	category: MachineCategory,
	stepRate: StepRate,
) {
	switch (category) {
		case 'window':
			return WINDOW_ICONS_MAP[stepRate]
		case 'curtain':
			return CURTAIN_ICONS_MAP[stepRate]
		case 'fan':
			return stepRate === 0 ? FanOff : Air
		case 'heating':
			return stepRate === 0 ? HeaterOff : HeaterOn
		case 'mist':
			return stepRate === 0 ? MistOff : MistOn
		case 'co2':
			return stepRate === 0 ? Co2Off : BubbleChart
		default:
			return stepRate === 0 ? RouterOff : Router
	}
}

type IconProps = PropsWithChildren<{
	category: MachineCategory
	stepRate: StepRate
	style?: React.CSSProperties
}>
export function CategoryIcon({ category, stepRate, ...props }: IconProps) {
	const Icon = machineCategoryIcon(category, stepRate)

	return <Icon {...props} />
}

CategoryIcon.defaultProps = {
	stepRate: 6,
}
