import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as actions from './actions'

export type State = {
	isLoading: boolean
}

export const initialState: State = {
	isLoading: false,
}

export default reducerWithInitialState(initialState)
	.case(actions.syncStart, (state) => {
		return {
			...state,
			isLoading: true,
		}
	})
	.case(actions.syncEnd, (state) => {
		return {
			...state,
			isLoading: false,
		}
	})
