import moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'

type Props = {
	timeStart: string
	timeEnd: string
	noLabel?: boolean
}

// grid-template-columns: 50% 1fr 1fr 1fr 25%; ここを計算する

const template = `
  "before bar   after"
  "label  label label"
`

const Grid = styled.div<{ startRate: number; endRate: number }>`
	width: 100%;
	display: grid;
	grid-template-areas: ${template};
	grid-template-columns: ${(p) => p.startRate}% 1fr ${(p) => p.endRate}%;
	grid-template-rows: 8px;
`

const TimeBar = (props: Props) => {
	const timeStart = moment(props.timeStart)
	const timeEnd = moment(props.timeEnd)
	const startRate = (Math.abs(0 - timeStart.hour()) / 24) * 100
	const endRate = (Math.abs(24 - timeEnd.hour()) / 24) * 100

	return (
		<Grid startRate={startRate} endRate={endRate}>
			<div style={{ gridArea: 'before', background: 'lightgray' }} />
			<div style={{ gridArea: 'bar', background: 'teal' }} />
			<div style={{ gridArea: 'after', background: 'lightgray' }} />
			<div
				style={{
					gridArea: 'label',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				{!props.noLabel && (
					<>
						<div>{timeStart.format('HH:mm')}</div>
						<div>{timeEnd.format('HH:mm')}</div>
					</>
				)}
			</div>
		</Grid>
	)
}

export default TimeBar
