import React from 'react'

import SideDrawer from '../SideDrawer'
import NavBarContainer from '../NavBarContainer'
import AboutPage from './components/AboutPage'

const AboutPageContainer = () => (
	<div>
		<SideDrawer />
		<NavBarContainer />
		<AboutPage />
	</div>
)

export default AboutPageContainer
