import {
	Button,
	FormControlLabel,
	ListItemSecondaryAction,
	Radio,
	RadioGroup,
	Switch,
	TextField,
	Typography,
} from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { FormikErrors, useFormik } from 'formik'
import React from 'react'
import { t } from '../../../locale'
import { EditEventExField } from '../../../types'

type Fields = EditEventExField
type Props = {
	initialValues: Fields
	disabled: boolean
	handleSubmit: (fields: Fields) => void
}

const EditFanEventForm = (props: Props) => {
	const { disabled, initialValues } = props
	// const [enableTrigger, setEnableTrigger] = useState<boolean>(false)
	// const [enableInterval, setEnableInterval] = useState<boolean>(false)

	const {
		values,
		errors,
		touched,
		handleChange,
		handleSubmit,
		setFieldTouched,
		setFieldValue,
	} = useFormik<Fields>({
		initialValues,
		validate: (values) => {
			const errors: FormikErrors<Fields> = {}

			if (values.activeInterval === 0 && values.negativeInterval === 0) {
				errors.activeInterval = 'オンオフ両方0には出来ません'
			}

			return errors
		},
		onSubmit: (fields: Fields) => {
			props.handleSubmit(fields)
		},
	})

	const change = (name: keyof Fields, e: React.ChangeEvent<unknown>) => {
		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
	}
	const setActive = () => {
		setFieldValue('activeInterval', 1)
		setFieldValue('negativeInterval', 0)
	}
	const setNegative = () => {
		setFieldValue('activeInterval', 0)
		setFieldValue('negativeInterval', 1)
	}
	const setCustom = () => {
		if (!values.activeInterval) setFieldValue('activeInterval', 1)
		if (!values.negativeInterval) setFieldValue('negativeInterval', 1)
	}
	const radioSelected =
		values.negativeInterval && values.activeInterval
			? 'custom'
			: values.activeInterval
			? 'on'
			: 'off'

	return (
		<div>
			<Typography variant="h6">{t.event.base}を編集</Typography>
			<FormControlLabel
				control={
					<Switch
						checked={values.enabled}
						onChange={change.bind(null, 'enabled')}
						name="enabled"
						color="primary"
					/>
				}
				label="有効にする"
			/>
			<RadioGroup style={{ flexDirection: 'row' }} value={radioSelected}>
				<FormControlLabel
					value="on"
					control={<Radio onClick={setActive} />}
					label="常にオン"
				/>
				<FormControlLabel
					value="off"
					control={<Radio onClick={setNegative} />}
					label="常にオフ"
				/>
				<FormControlLabel
					value="custom"
					control={<Radio onClick={setCustom} />}
					label="交互"
				/>
			</RadioGroup>
			<List
				style={{ background: radioSelected === 'custom' ? 'white' : '#ddd' }}
			>
				<ListItem>
					<ListItemText secondary={'オン時間(分)'} />
					<ListItemSecondaryAction>
						<TextField
							type="number"
							name="activeInterval"
							value={values.activeInterval}
							disabled={disabled}
							error={touched.activeInterval && Boolean(errors.activeInterval)}
							helperText={touched.activeInterval && errors.activeInterval}
							onChange={change.bind(null, 'activeInterval')}
						/>
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem divider>
					<ListItemText secondary={'オフ時間(分)'} />
					<ListItemSecondaryAction>
						<TextField
							type="number"
							name="negativeInterval"
							value={values.negativeInterval}
							disabled={disabled}
							error={
								touched.negativeInterval && Boolean(errors.negativeInterval)
							}
							helperText={touched.negativeInterval && errors.negativeInterval}
							onChange={change.bind(null, 'negativeInterval')}
						/>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
			<div style={{ textAlign: 'right' }}>
				<Button onClick={() => handleSubmit()} color="primary" size={'large'}>
					変更する
				</Button>
			</div>
		</div>
	)
}

export default EditFanEventForm
