import { connect } from 'react-redux'
import { createEvent } from '../../store/HouseContainer/operations'
import { getRequestIsLoading } from '../../store/Network/selectors'
import { getSubControls } from '../../store/SubControlById/selectors'
import { getTriggers } from '../../store/TriggerById/selectors'
import {
	EditEventCustomField,
	House,
	State,
	SubControl,
	Trigger,
} from '../../types'
import CreateEventForm from './components/CreateEventForm'

type OProps = {
	house: House
	scenarioId: number
	controlId: number
}
type SProps = {
	scenarioId: number
	subControls: SubControl[]
	triggers: Trigger[]
	disabled: boolean
}
type DProps = {
	handleSubmit: (event: EditEventCustomField) => void
}
export default connect<SProps, DProps, OProps, State>(
	(state, op) => ({
		scenarioId: op.scenarioId,
		subControls: getSubControls(state, op.controlId),
		triggers: getTriggers(state, op.house.id),
		disabled: getRequestIsLoading(state),
	}),
	{
		handleSubmit: createEvent,
	},
)(CreateEventForm)
