import _ from 'lodash'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Machine } from '../../types'
import * as actions from './actions'

export type State = { [id: number]: Machine }

export const initialState: State = {}

export default reducerWithInitialState(initialState).case(
	actions.registerMachines,
	(state, objects) => {
		return { ...state, ..._.keyBy(objects, 'id') }
	},
)
