import { applyMiddleware, compose, createStore, Middleware } from 'redux'
import {
	createMigrate,
	MigrationManifest,
	persistReducer,
	persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import reducer from './reducer'
import { isDev } from './utils'

const migrations: MigrationManifest = {}

const persistConfig = {
	key: 'root',
	storage,
	version: 1,
	migrate: createMigrate(migrations, { debug: false }),
	blacklist: ['LoginPage', 'Drawer', 'SettingContainer', 'LogsById'],
}

const makeStore = () => {
	const middleware = [thunk]
	const enhancers: Middleware[] = []

	if (isDev) {
		// @ts-ignore
		const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

		if (typeof devToolsExtension === 'function') {
			enhancers.push(devToolsExtension())
		}
	}
	const composer = compose(applyMiddleware(...middleware), ...enhancers)

	const persistedReducer = persistReducer(persistConfig, reducer)
	// @ts-ignore
	const store = createStore(persistedReducer, composer)
	const persistor = persistStore(store, {}, () => {
		// setToken(store.getState().Auth.accessToken)
	})

	return { store, persistor }
}

export default makeStore
