import { Paper, Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { t } from '../../locale'
import { TabId } from '../../types'

type Props = {
	isOpen: boolean
	selectedTab: TabId
	onSelectTab: (tabId: TabId) => void
	onOpenDrawer: () => void
	onCloseDrawer: () => void
}

const HouseTabs = (props: Props) => {
	return (
		<nav>
			<Paper>
				<Tabs
					value={props.selectedTab}
					onChange={(e, v) => props.onSelectTab(v)}
					indicatorColor="primary"
					textColor="primary"
					centered
				>
					<Tab value="home" label="ホーム" />
					<Tab value="graph" label="グラフ" />
					<Tab value="scenario" label={`${t.scenario.base}`} />
					<Tab value="control" label={`${t.screens.control}`} />
					<Tab value="setting" label={`設定`} />
				</Tabs>
			</Paper>
		</nav>
	)
}

export default HouseTabs
