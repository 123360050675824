import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Scenario } from '../../types'

type Props = {
	scenario: Scenario
	disabled: boolean
	keyi: number
	handleSwitch: (scenario: Scenario) => void
}

const ScenarioRow = (props: Props) => {
	const history = useNavigate()
	const { scenario, keyi } = props

	return (
		<ListItem key={scenario.id} data-test={`scenario-item-${keyi}`}>
			{scenario.isActive ? (
				<Button size="small" variant="contained" color="primary">
					アクティブ
				</Button>
			) : (
				<Button
					variant="outlined"
					size="small"
					disabled={props.disabled}
					onClick={(_e) => {
						props.handleSwitch(scenario)
					}}
				>
					切り替える
				</Button>
			)}
			<Typography
				variant="subtitle1"
				style={{
					marginLeft: '20px',
					textDecoration: 'underline',
					cursor: 'pointer',
				}}
				onClick={() =>
					history(`/houses/${scenario.houseId}/scenarios/${scenario.id}`)
				}
			>
				{`${scenario.name}`}
			</Typography>
		</ListItem>
	)
}

export default ScenarioRow
