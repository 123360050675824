import _ from 'lodash'
import { ControlRaw, SubControl, ThunkAction } from '../../types'
import { registerSubControls } from './actions'

export const saveSubControls = (control: ControlRaw): ThunkAction => {
	return async (dispatch) => {
		const subControls = batchSubControls(control)

		await dispatch(registerSubControls(subControls))
	}
}

function batchSubControls(control: ControlRaw): SubControl[] {
	return _.map(_.sortBy(control.subControls, [(e) => -e.rate]), (sc) =>
		batchSubControl(sc, control),
	)
}

function batchSubControl(
	subControl: SubControl,
	control: ControlRaw,
): SubControl {
	return {
		...subControl,
		label: control.label + (subControl.name || ''),
	}
}
