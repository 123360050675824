import { connect } from 'react-redux'
import ScenarioForm from '../../components/ScenarioForm'
import {
	deleteScenario,
	submitScenario,
} from '../../store/HouseContainer/operations'
import { ScenarioFormField, State } from '../../types'

type OProps = {
	scenario: ScenarioFormField
}
const ms = (state: State, op: OProps) => ({
	scenario: op.scenario,
	disabled: state.Network.isLoading,
})

const conn = connect(ms, {
	handleSubmit: submitScenario,
	handleDelete: deleteScenario,
})

export default conn(ScenarioForm)
