import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Auth } from '../../types'
import * as actions from './actions'

export type State = Auth

export const initialState: State = {
	authorized: false,
	email: 'NoLogin',
}

export default reducerWithInitialState(initialState)
	.case(actions.removeAuth, () => {
		return initialState
	})
	.case(actions.saveAuth, (state, auth) => {
		return {
			...state,
			authorized: true,
			...auth,
		}
	})
