import { Container, Tab, Tabs } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { WrapImg } from '../../components'
import { useJump } from '../../store/App/operations'
import { getControl } from '../../store/ControlById/selectors'
import { getEventFulls } from '../../store/EventById/selectors'
import { getHouse } from '../../store/HouseById/selectors'
import { loadHouses } from '../../store/HouseContainer/operations'
import { getScenario } from '../../store/ScenarioById/selectors'
import {
	Control,
	EventFull,
	House,
	Scenario,
	timePatterns,
	timePatternsBase,
} from '../../types'
import {
	categoryStr,
	isBoolMachineCategory,
	isStepMachineCategory,
} from '../../utils'
import Layout from '../App/Layout'
import Co2ManageTab from './Co2ManageTab'
import EventManageTab from './EventManageTab'
import FanManageTab from './FanManageTab'
import HeatingManageTab from './HeatingManageTab'
import timePatternImg from './img/TimePatterns.png'
import timePatternSimpleImg from './img/TimePatternsSimple.png'
import MistManageTab from './MistManageTab'
import SteperManageTab from './SteperManageTab'

function MachinePage() {
	const params = useParams()
	const hid = Number(params.hid)
	const sid = Number(params.sid)
	const cid = Number(params.cid)

	const house = useSelector((s) => getHouse(s, hid))
	const scenario = useSelector((s) => getScenario(s, sid))
	const eventss = useSelector((s) => getEventFulls(s, sid))
	const control = useSelector((s) => getControl(s, cid))
	const isLoading = useSelector((s) => s.Network.isLoading)

	const { jumpHousePage, jumpScenarioPage } = useJump()

	React.useEffect(() => {
		loadHouses()
	}, [])

	if (!scenario || !control) {
		return (
			<div>
				<Typography variant="h4" style={{ flex: 1 }}>
					ページは削除されたか存在しません
				</Typography>
				<Button onClick={() => jumpHousePage(house.id)}>←{house.name}</Button>
			</div>
		)
	}

	return (
		<Container fixed>
			<Button
				onClick={() => jumpHousePage(house.id)}
				data-test={`back-house-link`}
			>
				{'> ' + house.name}
			</Button>
			<Button
				onClick={() => jumpScenarioPage(house.id, scenario.id)}
				data-test={`back-scenario-link`}
			>
				{'> ' + scenario.name}
			</Button>
			<div style={{ display: 'flex', marginBottom: '12px' }}>
				<Typography variant="h5" style={{ flex: 1 }}>
					機器: {categoryStr[control.category]} {control.label}
				</Typography>
			</div>
			<MachinePaneRouter
				isLoading={isLoading}
				house={house}
				control={control}
				scenario={scenario}
				eventss={eventss}
			/>
		</Container>
	)
}

type PaneProps = {
	isLoading: boolean
	house: House
	control: Control
	scenario: Scenario
	eventss: EventFull[]
}
function MachinePaneRouter(props: PaneProps) {
	const { isLoading, house, control, scenario, eventss } = props

	if (isStepMachineCategory(control.category)) {
		return <StepMachinePane {...props} />
	} else if (control.category === 'heating') {
		return <HeatingMachinePane {...props} />
	} else if (control.category !== null) {
		return <BoolMachinePane {...props} />
	} else {
		// etc null category
		return (
			<EventManageTab
				house={house}
				scenario={scenario}
				control={control}
				eventss={eventss}
				isLoading={isLoading}
			/>
		)
	}
}

function StepMachinePane(props: PaneProps) {
	const { isLoading, house, control, scenario, eventss } = props

	const [tab, setValue] = React.useState(0)
	const handleChange = (_e: unknown, newValue: number) => setValue(newValue)

	return (
		<>
			<WrapImg src={timePatternImg} alt={'タイムパターン図'} />
			<Tabs
				value={tab}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				centered
			>
				<Tab label="1" />
				<Tab label="2" />
				<Tab label="3" />
				<Tab label="QD" />
				<Tab label="カスタム" />
			</Tabs>
			{timePatterns.map(
				(tp, i) =>
					tab === i && (
						<SteperManageTab
							key={i}
							house={house}
							scenario={scenario}
							control={control}
							timePattern={tp}
							hasQd
						/>
					),
			)}
			{tab === 4 && (
				<EventManageTab
					house={house}
					scenario={scenario}
					control={control}
					eventss={eventss}
					isLoading={isLoading}
				/>
			)}
		</>
	)
}

const boolMachineTabByCategory = {
	fan: FanManageTab,
	mist: MistManageTab,
	co2: Co2ManageTab,
	heating: null,
}

function BoolMachinePane(props: PaneProps) {
	const { isLoading, house, control, scenario, eventss } = props

	const [tab, setValue] = React.useState(0)
	const handleChange = (_e: unknown, newValue: number) => setValue(newValue)

	const { category } = control

	if (!isBoolMachineCategory(category)) return null
	const TabComponent = boolMachineTabByCategory[category]

	if (TabComponent === null) return null

	return (
		<>
			<WrapImg src={timePatternSimpleImg} alt={'タイムパターン図'} />
			<Tabs
				value={tab}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				centered
			>
				<Tab label="1" />
				<Tab label="2" />
				<Tab label="3" />
				<Tab label="カスタム" />
			</Tabs>
			{timePatternsBase.map(
				(tp, i) =>
					tab === i && (
						<TabComponent
							key={i}
							house={house}
							scenario={scenario}
							control={control}
							timePattern={tp}
						/>
					),
			)}
			{tab === 3 && (
				<EventManageTab
					house={house}
					scenario={scenario}
					control={control}
					eventss={eventss}
					isLoading={isLoading}
				/>
			)}
		</>
	)
}

function HeatingMachinePane(props: PaneProps) {
	const { isLoading, house, control, scenario, eventss } = props

	const [tab, setValue] = React.useState(0)
	const handleChange = (_e: unknown, newValue: number) => setValue(newValue)

	return (
		<>
			<Tabs
				value={tab}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				centered
			>
				<Tab label="時間" />
				<Tab label="カスタム" />
			</Tabs>
			{tab === 0 && (
				<HeatingManageTab house={house} scenario={scenario} control={control} />
			)}
			{tab === 1 && (
				<EventManageTab
					house={house}
					scenario={scenario}
					control={control}
					eventss={eventss}
					isLoading={isLoading}
				/>
			)}
		</>
	)
}

export const MachinePageRoot = () => (
	<Layout>
		<MachinePage />
	</Layout>
)

export default MachinePage
