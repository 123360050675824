import NavBar from '../../components/NavBar'
import HousePage from '../HouseContainer/HousePage'

const DemoHousePage = () => {
	console.log('sample')

	return (
		<>
			<NavBar
				doLogout={() => alert('サンプルページです')}
				onClickMenu={() => alert('サンプルページです')}
			/>
			<HousePage houseId={0} />
		</>
	)
}

export default DemoHousePage
