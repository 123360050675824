import _ from 'lodash'

import { Control, State, StepRate, SubControl } from '../../types'
import { getControl } from '../ControlById/selectors'
import { getHouse } from '../HouseById/selectors'

export function getControlCurrentRate(state: State, id: number | null): number {
	if (!id) return 0

	return getSubControl(state, id)?.rate || 0
}
export function getControlCurrentStepRate(
	state: State,
	id: number | null,
): StepRate {
	const rate = getControlCurrentRate(state, id)

	return Math.floor((rate / 100) * 6) as StepRate
}
export function getSubControl(state: State, id: number): SubControl {
	return state.SubControlById[id]
}

export function getSubControls(state: State, controlId: number): SubControl[] {
	const control = getControl(state, controlId)

	return _.sortBy(
		control.subControlIds.map((id) => getSubControl(state, id)),
		['rate'],
	)
}

export function getSubControlsAll(state: State, houseId: number): SubControl[] {
	const house = getHouse(state, houseId)

	return _.flatten(house.controlIds.map((cid) => getSubControls(state, cid)))
}

export function getActiveSubControl(
	state: State,
	control: Control,
): SubControl {
	const subc = control.subControlIds
		.map((id) => getSubControl(state, id))
		.find((sub) => sub.rate === 100)

	if (!subc) throw Error('not found rate 100 control')
	return subc
}
