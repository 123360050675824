import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useDayQuery } from '../../hooks/useDayQuery'
import { getHouse } from '../../store/HouseById/selectors'
import { loadHouses, loadLogs } from '../../store/HouseContainer/operations'
import { getLogs } from '../../store/LogsById/selectors'
import { getSelectedTab } from '../../store/SettingContainer/selectors'
import Layout from '../App/Layout'
import HouseTabs from '../HouseTabs/index'
import HomeTabs from './HouseTabs'

type Props = {
	houseId?: number
}

function HousePage({ houseId: houseIdP }: Props) {
	const params = useParams()
	const day = useDayQuery().dayId

	const houseId = Number(houseIdP ?? params.hid)

	// console.log('HousePage', houseId)
	const house = useSelector((s) => getHouse(s, houseId))
	const dayLogs = useSelector((s) => getLogs(s, `${houseId}:${day}`))
	const selectedTab = useSelector(getSelectedTab)

	const history = useNavigate()
	const dispatch = useDispatch()

	React.useEffect(() => {
		console.log(`HousePage`)
		dispatch(loadHouses())
	}, [dispatch])
	React.useEffect(() => {
		if (!house) return
		dispatch(loadLogs({ houseId: house.id, day }))
	}, [house, day, dispatch])

	if (!house) return <div>loading</div>

	return (
		<div>
			<Grid container justifyContent={'center'}>
				<Grid item xs={12} sm={12} md={8}>
					<Button onClick={() => history('/')}>←ハウス一覧へ戻る</Button>
					<Typography variant="h4">{house.name}</Typography>
					<HouseTabs />
					<HomeTabs
						selectedTab={selectedTab}
						day={day}
						house={house}
						dayLogs={dayLogs}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

export const HousePageRoot = () => (
	<Layout>
		<HousePage />
	</Layout>
)

export default HousePage
