import { connect } from 'react-redux'
import NavBar from '../../components/NavBar'
import { doLogout } from '../../store/Auth/operations'
import { toggleDrawer } from '../../store/Drawer/actions'

const ms = () => ({})

const conn = connect(ms, { doLogout, onClickMenu: () => toggleDrawer() })

export default conn(NavBar)
