import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import React, { useState } from 'react'
import { ScenarioFormField, SubmitScenarioField } from '../../types'

type Props = {
	scenario: ScenarioFormField
	handleSubmit: (p: SubmitScenarioField) => void
	handleDelete: (scenarioId: number) => void
	disabled: boolean
}

function ScenarioForm(props: Props) {
	const { scenario } = props
	const [field, setField] = useState<ScenarioFormField>(props.scenario)

	const onSubmit = () => {
		props.handleSubmit(field)
	}

	return (
		<div>
			<form>
				<TextField
					id="name"
					required
					label="名前"
					disabled={props.disabled}
					defaultValue={props.scenario.name}
					margin="normal"
					onChange={(e) => setField({ ...field, name: e.target.value })}
					inputProps={{ 'data-test': 'scenario-input-name' }}
				/>

				<Box sx={{ display: 'flex', gap: '0.4rem' }}>
					{scenario.isNew ? (
						<Button
							variant="contained"
							size="small"
							disabled={props.disabled}
							onClick={onSubmit}
							data-test="create-scenario-btn"
						>
							作成
						</Button>
					) : (
						<>
							<Button
								variant="contained"
								size="small"
								disabled={props.disabled}
								onClick={onSubmit}
								data-test={`scenario-update-btn`}
							>
								更新
							</Button>

							<Button
								variant="contained"
								size="small"
								color="secondary"
								disabled={props.disabled}
								onClick={(_e) => {
									props.handleDelete(scenario.id)
								}}
								data-test={`scenario-del-btn`}
							>
								削除する
							</Button>
						</>
					)}
				</Box>
			</form>
		</div>
	)
}

export default ScenarioForm
