import { State } from '../../types'

export function getLoginFields(state: State) {
	return state.LoginPage.fields
}
export function getLoginErrorMessage(state: State) {
	return state.LoginPage.errorMessage
}

export function getLoginLoading(state: State) {
	return state.LoginPage.loading
}
