import {
	Control,
	initSteper,
	Scenario,
	State,
	Steper,
	TimePattern,
} from '../../types'

export function getSteper(state: State, id: number): Steper {
	return state.SteperById[id]
}

export function getStepersByScenario(
	state: State,
	scenario: Scenario,
): Steper[] {
	return scenario.steperIds.map((id) => getSteper(state, id))
}

export function getStepers(state: State, steperIds: number[]): Steper[] {
	return steperIds.map((id) => getSteper(state, id))
}

export function getSteperOne(
	state: State,
	steperIds: number[],
	timePattern: TimePattern,
	control: Control,
): Steper {
	const stepers = getStepers(state, steperIds)

	return (
		stepers.find(
			(s) => s.controlId === control.id && s.timePattern === timePattern,
		) || initSteper
	)
}

export function getReport(state: State): string {
	return JSON.stringify(state)
}
