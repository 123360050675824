import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { makeDayLabel } from '../utils'

export const useDayQuery = () => {
	const { search } = useLocation()
	// const params = new URLSearchParams(op.history.location.search)

	const [dayId, todayId] = useMemo(() => {
		const params = new URLSearchParams(search)

		return [makeDayLabel(params.get('day') || undefined), makeDayLabel()]
	}, [search])

	return { dayId, isToday: todayId === dayId }
}
