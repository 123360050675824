import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getHouses } from '../../store/HouseById/selectors'
import { loadHouses } from '../../store/HouseContainer/operations'
import Layout from '../App/Layout'

function HouseContainer() {
	const history = useNavigate()
	const houses = useSelector(getHouses)
	const dispatch = useDispatch()

	React.useEffect(() => {
		dispatch(loadHouses())
	}, [dispatch])
	if (houses.length === 0) {
		return <div>ハウスが登録されていません</div>
	}

	return (
		<div>
			<List data-test="house-list">
				{houses.map((house) => (
					<ListItem key={house.id}>
						<Typography variant="body1">{house.name}</Typography>
						<Button
							data-test={`house-${house.id}-link`}
							onClick={() => history(`/houses/${house.id}`)}
						>
							開く
						</Button>
					</ListItem>
				))}
			</List>
		</div>
	)
}

const AuthHouseContainer = () => (
	<Layout>
		<HouseContainer />
	</Layout>
)

export default AuthHouseContainer
