import { Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useDayQuery } from '../../hooks/useDayQuery'
import { getHouse } from '../../store/HouseById/selectors'
import { makeDayLabel } from '../../utils'
import Timeline from './TimeLine'
import { useActionLogs } from './useActionLogs'

type Props = {
	houseId: number
}
function TimeLineContainer(props: Props) {
	const house = useSelector((s) => getHouse(s, props.houseId))

	const { dayId } = useDayQuery()
	const { logs, reload } = useActionLogs(house.id, makeDayLabel(dayId))

	return (
		<div>
			<Typography variant="h6" style={{ flex: 1 }}>
				制御ログ
			</Typography>

			<Timeline actionLogs={logs} onClickSync={reload} />
		</div>
	)
}

export default TimeLineContainer
