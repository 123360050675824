import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import 'moment/locale/ja'
import config from '.'
import { loadDemoMocks } from '../store/Demo/operations'
import { getToken } from '../utils/tokenUtil'

const isMockPage = () => !!window.location.href.match('/houses/0')

export const cli = () => {
	const c = axios.create()
	c.defaults.baseURL = config.api.url
	c.interceptors.response.use(
		(res) => {
			if (!res.data) return res

			return { ...res, data: camelcaseKeys(res.data, { deep: true }) }
		},
		(error) => Promise.reject(error),
	)

	const token = getToken()
	if (token) c.defaults.headers.common['Authorization'] = token
	console.log(token)

	if (isMockPage()) {
		loadDemoMocks(c)
	}
	return c
}
export { axios }
