import {
	Event,
	EventCustom,
	EventEx,
	EventFull,
	EventHeating,
	initEventHeating,
	isEventCustom,
	isEventEx,
	isEventHeating,
	State,
	SubControl,
	TimePattern,
} from '../../types'
import { getScenario } from '../ScenarioById/selectors'
import { getSubControl } from '../SubControlById/selectors'
import { getTrigger } from '../TriggerById/selectors'

export function getEvent(state: State, id: number): Event {
	return state.EventById[id]
}

export function getEventsByScenario(
	state: State,
	scenarioId: number,
): EventCustom[] {
	const scenario = getScenario(state, scenarioId)

	return getEventCustoms(state, scenario.eventIds)
}

export function getEvents(state: State, eventIds: number[]): Event[] {
	return eventIds.map((id) => getEvent(state, id))
}

export function getEventCustoms(state: State, eventIds: number[]) {
	return getEvents(state, eventIds).filter(isEventCustom)
}

export function getEventExs(state: State, eventIds: number[]) {
	const evs = getEvents(state, eventIds)

	return evs.filter(isEventEx)
}

export function getEventFulls(state: State, scenarioId: number): EventFull[] {
	const events = getEventsByScenario(state, scenarioId)
	const scenario = getScenario(state, scenarioId)

	if (!scenario) {
		return []
	}

	const eventss = events.map((fe) => ({
		...fe,
		...(fe.triggerId ? { trigger: getTrigger(state, fe.triggerId) } : {}),
		subControl: getSubControl(state, fe.subControlId),
	}))

	return eventss
}

export function getReport(state: State): string {
	return JSON.stringify(state)
}

export function getEventEx(
	state: State,
	eventIds: number[],
	timePattern: TimePattern,
	subc: SubControl,
	init: EventEx,
): EventEx {
	const events = getEventExs(state, eventIds)

	return (
		events.find(
			(e) => subc.id === e.subControlId && e.timePattern === timePattern,
		) || init
	)
}

export function getEventHeating24(
	state: State,
	eventIds: number[],
	subc: SubControl,
) {
	const eventByHour: Record<number, EventHeating> = {}
	const events = getEvents(state, eventIds)
		.filter(isEventHeating)
		.filter((e) => e.subControlId === subc.id)

	events.forEach((ev) => {
		const [sh, sm] = ev.startTime.split(':').map(Number)
		const [eh, em] = ev.endTime.split(':').map(Number)

		const matchHourConfig = sm === 0 && em === 59 && sh === eh

		if (matchHourConfig) {
			eventByHour[sh] = ev
		}
	})

	for (let i = 0; i <= 23; i++) {
		if (!!eventByHour[i]) continue

		const hh = `${i}`.padStart(2, '0')

		eventByHour[i] = {
			...initEventHeating,
			startTime: `${hh}:00`,
			endTime: `${hh}:59`,
		}
	}
	return eventByHour
}
