import {
	Button,
	Checkbox,
	FormControl,
	InputAdornment,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from '@mui/material'
import { FormikErrors, useFormik } from 'formik'
import React, { useState } from 'react'
import { CreateTriggerField } from '../../../types'
import { sensorLib } from '../../../utils'

type Fields = Omit<CreateTriggerField, 'high' | 'low' | 'houseId'> & {
	high: number
	low: number
}
type Props = {
	houseId: number
	disabled: boolean
	initSensorId: number

	handleSubmit: (field: CreateTriggerField) => void
}
const sensors = Object.values(sensorLib)

const CreateTriggerForm = (props: Props) => {
	const { houseId, disabled } = props
	const [highEnable, setHighEnable] = useState<boolean>(true)
	const [lowEnable, setLowEnable] = useState<boolean>(true)

	const {
		values,
		errors,
		touched,
		handleChange,
		handleSubmit,
		setFieldTouched,
	} = useFormik<Fields>({
		initialValues: {
			high: 20,
			low: 10,
			sensor: props.initSensorId,
		},
		validate: (values) => {
			const errors: FormikErrors<Fields> = {}

			if (highEnable && lowEnable && values.high <= values.low) {
				errors.low = '上限より大きいです。'
			}

			return errors
		},
		onSubmit: (fields: Fields) => {
			props.handleSubmit({
				houseId,
				sensor: fields.sensor,
				low: lowEnable ? fields.low : null,
				high: highEnable ? fields.high : null,
			})
		},
	})

	const change = (name: keyof Fields, e: React.ChangeEvent<unknown>) => {
		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
	}

	const select = (name: keyof Fields, e: SelectChangeEvent<unknown>) => {
		handleChange(e)
		setFieldTouched(name, true, false)
	}

	const sensor = sensorLib[values.sensor]
	const endAdornment = (
		<InputAdornment position="end">{sensor.unit}</InputAdornment>
	)

	return (
		<div>
			<Typography variant="h6">トリガーを作成</Typography>
			<List>
				<ListItem divider>
					<ListItemText secondary={'センサー'} />
					<ListItemSecondaryAction>
						<FormControl>
							<Select
								value={values.sensor}
								name="sensor"
								disabled={disabled}
								onChange={select.bind(null, 'sensor')}
								error={touched.sensor && Boolean(errors.sensor)}
								style={{ width: '6rem' }}
							>
								{sensors.map((sensor, key) => (
									<MenuItem key={key} value={sensor.id}>
										{sensor.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Checkbox style={{ visibility: 'hidden' }} edge="end" />
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem disabled={disabled || !highEnable}>
					<ListItemText secondary={'上限値'}>
						<ListItemSecondaryAction>
							<TextField
								type="number"
								name="high"
								value={values.high}
								InputProps={{ endAdornment }}
								inputProps={{ min: 0 }}
								onChange={change.bind(null, 'high')}
								style={{ width: '6rem' }}
								disabled={disabled || !highEnable}
							/>
							<Checkbox
								edge="end"
								onChange={(e) => setHighEnable(e.target.checked)}
								checked={highEnable}
							/>
						</ListItemSecondaryAction>
					</ListItemText>
				</ListItem>
				<ListItem disabled={disabled || !lowEnable} divider>
					<ListItemText secondary={'下限値'}>
						<ListItemSecondaryAction>
							<TextField
								type="number"
								name="low"
								value={values.low}
								InputProps={{ endAdornment }}
								inputProps={{ min: 0 }}
								onChange={change.bind(null, 'low')}
								style={{ width: '6rem' }}
								disabled={disabled || !lowEnable}
								error={touched.low && Boolean(errors.low)}
								helperText={touched.low && errors.low}
							/>
							<Checkbox
								edge="end"
								onChange={(e) => setLowEnable(e.target.checked)}
								checked={lowEnable}
							/>
						</ListItemSecondaryAction>
					</ListItemText>
				</ListItem>
			</List>
			<div style={{ textAlign: 'right' }}>
				<Button onClick={() => handleSubmit()} color="primary" size={'large'}>
					追加する
				</Button>
			</div>
		</div>
	)
}

export default CreateTriggerForm
