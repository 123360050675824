import { State } from '../../types'

export function getAppConfig(state: State) {
	return state.SettingContainer
}

export const getSelectedTab = (state: State) => {
	return state.SettingContainer.selectedTabId
}

export const getEditControl = (state: State) => {
	return state.SettingContainer.editControl
}
