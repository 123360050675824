import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import SideDrawerComp from '../../components/SideDrawer'
import { doLogout } from '../../store/Auth/operations'
import {
	changeTabAndCloseDrawer,
	closeDrawer,
	openDrawer,
} from '../../store/Drawer/operations'
import { getDrawerIsOpen } from '../../store/Drawer/selectors'

const SideDrawer = () => {
	const location = useLocation()
	const dispatch = useDispatch()
	const isOpen = useSelector(getDrawerIsOpen)

	return (
		<SideDrawerComp
			isOpen={isOpen}
			isTop={location.pathname === '/'}
			onSelectTab={(v) => dispatch(changeTabAndCloseDrawer(v))}
			onCloseDrawer={() => dispatch(closeDrawer())}
			onOpenDrawer={() => dispatch(openDrawer())}
			logout={() => dispatch(doLogout())}
		/>
	)
}

export default SideDrawer
