import { Close } from '@mui/icons-material'
import {
	Button,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Tab,
	Tabs,
} from '@mui/material'
import _ from 'lodash'
import React, { useState } from 'react'
import { Sensor, Trigger } from '../../../types'
import { sensorLib } from '../../../utils'

type Props = {
	triggers: Trigger[]
	classes?: unknown

	handleDeleteTrigger: (triggerId: number) => void
	handleCreateTrigger: (sensorId: number) => void
}

const TriggerList = (props: Props) => {
	const [selectTab, setSelectTab] = useState<number>(1)

	const sensorsById = _.groupBy(props.triggers, 'sensor')

	return (
		<div>
			<Tabs value={selectTab - 1}>
				{_.map(sensorLib, (sensor) => (
					<Tab
						disableRipple
						key={sensor.id}
						onClick={() => setSelectTab(sensor.id)}
						label={sensor.label}
					/>
				))}
			</Tabs>
			<TriggerListTab
				{...props}
				triggers={sensorsById[selectTab] || []}
				sensor={sensorLib[selectTab]}
			/>
		</div>
	)
}

function TriggerListTab(props: { sensor: Sensor } & Props) {
	const { triggers, sensor } = props

	return (
		<div>
			<List>
				{triggers.map((trigger, k) => (
					<ListItem key={k} button divider>
						<ListItemText>{trigger.ruleText}</ListItemText>
						<ListItemSecondaryAction>
							<IconButton onClick={() => props.handleDeleteTrigger(trigger.id)}>
								<Close />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
			<div style={{ textAlign: 'center' }}>
				<Button
					onClick={() => props.handleCreateTrigger(sensor.id)}
					style={{
						color: 'white',
						backgroundColor: '#f46b8e',
					}}
				>
					追加
				</Button>
			</div>
		</div>
	)
}

export default TriggerList
