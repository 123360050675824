import _ from 'lodash'
import * as React from 'react'

import { Typography } from '@mui/material'
import { EventCustom, EventFull, TimeGroup } from '../../../types'
import EventItem from '../../../components/EventItem'
import TimeBar from '../../../components/TimeBar'
import { t } from '../../../locale'

type Props = {
	eventss: EventFull[]
	handleDeleteEvent: (_e: EventCustom) => void
	handleToggleEnabled: (_e: EventCustom) => void
	disabled: boolean
}

const hmmFormat = (timeStr: string) => {
	const [h, m] = timeStr.split(':')

	return `${Number(h)}時${m}分`
}
const EventList = (props: Props) => {
	const { eventss, disabled } = props

	if (!eventss.length) {
		return (
			<Typography>
				この機器に登録されている{t.event.base}はありません。
			</Typography>
		)
	}
	const timeGroups: TimeGroup[] = _.map(
		_.groupBy(eventss, (fe) => {
			return fe.startTime + '-' + fe.endTime
		}),
		(eventss) => {
			return {
				startTime: eventss[0].startTime,
				endTime: eventss[0].endTime,
				startTimeStr: hmmFormat(eventss[0].startTime),
				endTimeStr: hmmFormat(eventss[0].endTime),
				isFullTime: eventss[0].isFullTime,
				eventss,
			}
		},
	)

	return (
		<div>
			{timeGroups.map((timeGroup, k) => (
				<div key={k}>
					<div style={{ padding: '10px' }}>
						{timeGroup.isFullTime ? (
							<Typography variant="h5">常時</Typography>
						) : (
							<>
								<Typography variant="h5">
									{timeGroup.startTimeStr}〜{timeGroup.endTimeStr}
								</Typography>
								<TimeBar
									timeStart={timeGroup.startTime}
									timeEnd={timeGroup.endTime}
									noLabel
								/>
							</>
						)}
					</div>
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'center',
						}}
					>
						{timeGroup.eventss.map((fe, i) => (
							<EventItem
								key={i}
								disabled={disabled}
								handleDelete={props.handleDeleteEvent}
								handleToggleEnabled={props.handleToggleEnabled}
								events={fe}
							/>
						))}
					</div>
				</div>
			))}
		</div>
	)
}

export default EventList
