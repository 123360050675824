import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LogGraph from '../../components/LogGraph'
import { useDayQuery } from '../../hooks/useDayQuery'
import { loadLogs } from '../../store/HouseContainer/operations'
import { getLogs } from '../../store/LogsById/selectors'
import TimeLineContainer from '../ActionLogCardContainer/TimeLineContainer'

type Props = {
	houseId: number
}

function LogContainer(props: Props) {
	const { houseId } = props
	const { dayId, isToday } = useDayQuery()
	const dayLogs = useSelector((state) => getLogs(state, `${houseId}:${dayId}`))
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(loadLogs({ houseId, day: dayId }))
	}, [houseId, dayId, dispatch])
	const history = useNavigate()

	return (
		<>
			<LogGraph
				dayLogs={dayLogs}
				day={dayId}
				isToday={isToday}
				houseId={props.houseId}
				handleSubmit={(day) => {
					history({ search: `?day=${day}` })
				}}
				handleSubmitToday={() => {
					history({ search: `` })
				}}
			/>
			<TimeLineContainer houseId={props.houseId} />
		</>
	)
}

export default LogContainer
