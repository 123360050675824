import { Slider } from '@mui/material'
import { withStyles } from '@mui/styles'
import { range } from 'lodash'
import * as React from 'react'
import config from '../../../config'
import theme from '../../../theme'
import { Steper } from '../../../types'
import { sensorLibByKey } from '../../../utils'

type Props = {
	steper: Steper
	value: number
}

const PrettoSlider = withStyles({
	root: {
		marginTop: '8px',
		color: '#d8d8d8',
		height: 8,
	},
	thumb: {
		height: 0,
		width: 0,
	},
	valueLabel: {
		left: 'calc(-50% + 4px)',
	},
	track: {
		height: 16,
		borderRadius: 4,
		color: theme.palette.primary.main,
		opacity: 1,
	},
	rail: {
		height: 16,
		borderRadius: 4,
	},
	mark: {
		height: 16,
	},
	markLabel: {
		marginLeft: '20px',
	},
})(Slider)

function StepBar({ steper, value }: Props) {
	const sensor = sensorLibByKey[steper.sensor]
	const valueKeys = range(config.stepCount - 1)

	const start = steper.bottom - steper.step
	const marks = valueKeys.map((key) => ({
		value: key,
		label: `${start + steper.step * key}${sensor.unit}`,
	}))

	return (
		<PrettoSlider
			step={1}
			min={0}
			max={config.stepCount - 1}
			marks={marks}
			value={value}
		/>
	)
}

export default StepBar
