import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { LoginFields } from '../../types'
import * as actions from './actions'

export type State = {
	loading: boolean
	fields: LoginFields
	errorMessage: string | null
}

const initialState: State = {
	loading: false,
	fields: {
		email: '',
		password: '',
	},
	errorMessage: null,
}

export default reducerWithInitialState<State>(initialState)
	.case(actions.updateFields, (state, fields) => {
		return {
			...state,
			fields: {
				...state.fields,
				...fields,
			},
		}
	})
	.case(actions.updateErrorMessage, (state, errorMessage) => {
		return { ...state, errorMessage }
	})
	.case(actions.updateLoading, (state, loading) => {
		return { ...state, loading }
	})
