import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { AppConfig } from '../../types'
import * as actions from './actions'

export type State = AppConfig

export const initialState = {
	editScenario: false,
	editControl: false,
	editTrigger: false,
	editEvent: false,
	selectedTabId: 'home',
} as State

export default reducerWithInitialState(initialState).case(
	actions.updateConfig,
	(state, config) => {
		return { ...state, ...config }
	},
)
