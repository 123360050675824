import * as React from 'react'
import { connect } from 'react-redux'

import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'

import { createSubControl } from '../../store/HouseContainer/operations'
import { Control, State, SubControl } from '../../types'

import CreateSubControlForm from '../../components/CreateSubControlForm'
import { getSubControls } from '../../store/SubControlById/selectors'
import SubControlRow from './SubControlRow'

type OProps = {
	control: Control
	keyi: string
}

type Props = {
	control: Control
	subControls: SubControl[]
	keyi: string
	disabled: boolean
	editMode: boolean

	createSubControl: (subControl: SubControl) => void
}

const SubControlList = (props: Props) => (
	<Collapse in unmountOnExit style={{ paddingLeft: '1em' }}>
		<List disablePadding>
			{props.subControls.map((sc, i) => (
				<SubControlRow
					control={props.control}
					subControl={sc}
					keyi={`${props.keyi}-${i}`}
					key={i}
				/>
			))}
		</List>
		{props.editMode && (
			<CreateSubControlForm
				disabled={props.disabled}
				controlId={props.control.id}
				// @ts-ignore
				handleSubmit={props.createSubControl}
			/>
		)}
	</Collapse>
)

const ms = (state: State, op: OProps) => ({
	control: op.control,
	keyi: op.keyi,
	disabled: state.Network.isLoading,
	editMode: state.SettingContainer.editControl,
	subControls: getSubControls(state, op.control.id),
})

const conn = connect(ms, {
	createSubControl,
})

export default conn(SubControlList)
