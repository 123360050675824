import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import styled from 'styled-components'
import DayChart from '../../components/DayChart'
import Device from '../../components/Device'
import { lines } from '../../components/LogGraph'
import { DayLogById, House, TabId } from '../../types'
import ControlList from '../ControlContainer/ControlList'
import LogContainer from '../LogContainer'
import ScenarioList from '../ScenarioContainer/ScenarioList'
import SettingTab from '../SettingTab'
import ControlTableContainer from './ControlTableContainer'

const Tab = styled(Paper)`
	padding: 20px;
	margin-top: 10px;
`
const StyledPaper = styled(Paper)`
	padding: 20px;
	margin-top: 10px;

	@media screen and (max-width: 600px) {
		padding: 0;
	}
`

type Props = {
	house: House
	day: string
	selectedTab: TabId
	dayLogs: DayLogById
}
const HomeTabs = ({ house, selectedTab, dayLogs, day }: Props) => {
	switch (selectedTab) {
		case 'home':
			return (
				<StyledPaper>
					<div style={{ padding: '4px 20px' }}>
						<Typography variant="h6">デバイス状態</Typography>
						<Device
							house={house}
							isActive={house.isActive}
							log={house.lastLog}
							dayLogs={dayLogs}
						/>
					</div>
					<hr />
					<div style={{ margin: '0' }}>
						<DayChart
							day={day}
							logs={dayLogs.logs}
							yTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
							lines={[lines.tmp, lines.hmd, lines.vpd, lines.dew]}
						/>
					</div>
					<ControlTableContainer house={house} />
				</StyledPaper>
			)
		case 'graph':
			return (
				<Tab>
					<Typography variant="h6">ログ</Typography>
					<LogContainer houseId={house.id} />
				</Tab>
			)
		case 'scenario':
			return (
				<Tab>
					<ScenarioList houseId={house.id} />
				</Tab>
			)
		case 'control':
			return (
				<Tab>
					<ControlList houseId={house.id} />
				</Tab>
			)
		case 'setting':
			return (
				<Tab>
					<SettingTab houseId={house.id} />
				</Tab>
			)

		default:
			return null
	}
}

export default HomeTabs
