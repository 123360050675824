import { combineReducers } from 'redux'
import { AnyAction } from 'typescript-fsa'
import { resetRootApp } from './store/App/action'
import Auth from './store/Auth/reducer'
import ControlById from './store/ControlById/reducer'
import Drawer from './store/Drawer/reducer'
import EventById from './store/EventById/reducer'
import SteperById from './store/SteperById/reducer'
import HouseById from './store/HouseById/reducer'
import LoginPage from './store/LoginPage/reducer'
import LogsById from './store/LogsById/reducer'
import Network from './store/Network/reducer'
import ScenarioById from './store/ScenarioById/reducer'
import MachineById from './store/MachineById/reducer'
import SettingContainer from './store/SettingContainer/reducer'
import SubControlById from './store/SubControlById/reducer'
import TriggerById from './store/TriggerById/reducer'
import Snackbar from './store/Snackbar/reducer'
import { State } from './types'

const appReducer = combineReducers<State>({
	Auth,
	LogsById,
	Network,
	LoginPage,
	Drawer,
	SettingContainer,
	HouseById,
	MachineById,
	ScenarioById,
	Snackbar,
	EventById,
	SteperById,
	ControlById,
	SubControlById,
	TriggerById,
})

const rootReducer = (state: State | undefined, action: AnyAction) => {
	if (action.type === resetRootApp.type) {
		return appReducer(undefined, action)
	}
	return appReducer(state, action)
}

export default rootReducer
