import React from 'react'
import {
	BrowserRouter as Router,
	Navigate,
	Route,
	Routes,
} from 'react-router-dom'
import { RELEASE_NOTE_URL } from '../../config'
import AboutPage from '../AboutPage'
import DemoHousePage from '../Demo/DemoHousePage'
import DemoMachinePage from '../Demo/DemoMachinePage'
import DemoScenarioPage from '../Demo/DemoScenarioPage'
import HouseContainer from '../HouseContainer'
import { HousePageRoot } from '../HouseContainer/HousePage'
import LoginPage from '../LoginPage'
import { MachinePageRoot } from '../MachinePage'
import { ScenarioPageRoot } from '../ScenarioPage'

const RouteApp = () => (
	<Router>
		<Routes>
			<Route path="/login" element={<LoginPage />} />
			<Route path="/demo" element={<Navigate to="/houses/0" />} />

			<Route path="/about" element={<AboutPage />} />
			<Route path="/release" element={<Navigate to={RELEASE_NOTE_URL} />} />
			<Route path="/" element={<HouseContainer />} />
			<Route path="/houses/0/" element={<DemoHousePage />} />
			<Route path="/houses/0/scenarios/:sid" element={<DemoScenarioPage />} />
			<Route
				path="/houses/0/scenarios/:sid/machines/:cid"
				element={<DemoMachinePage />}
			/>
			<Route path="/houses/:hid" element={<HousePageRoot />} />
			<Route
				path="/houses/:hid/scenarios/:sid"
				element={<ScenarioPageRoot />}
			/>
			<Route
				path="/houses/:hid/scenarios/:sid/machines/:cid"
				element={<MachinePageRoot />}
			/>
		</Routes>
	</Router>
)

export default RouteApp
