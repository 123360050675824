import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { FormikErrors, useFormik } from 'formik'
import * as React from 'react'

type Props = {
	handleSubmit: (p: unknown) => void
	controlId: number
	disabled: boolean
}
type Fields = {
	name: string
	rate: number
}

function CreateSubControlForm(props: Props) {
	const {
		values,
		errors,
		touched,
		handleChange,
		handleSubmit,
		setFieldTouched,
		setFieldValue,
		setFieldError,
	} = useFormik<Fields>({
		initialValues: {
			name: '',
			rate: 0,
		},
		validate: (values) => {
			const errors: FormikErrors<Fields> = {}

			if (!values.name) {
				errors.name = '必須です。'
			}

			return errors
		},
		onSubmit: (fields: Fields) => {
			setFieldError('name', undefined)
			props.handleSubmit({
				...fields,
				controlId: props.controlId,
			})
			setFieldValue('name', '')
			setFieldValue('rate', 0)
		},
	})
	const change = (name: keyof Fields, e: React.ChangeEvent<unknown>) => {
		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
	}

	return (
		<div>
			<form>
				<TextField
					name="name"
					autoComplete="pf-name"
					error={touched.name && Boolean(errors.name)}
					required
					disabled={props.disabled}
					label="名前"
					margin="normal"
					helperText={touched.name && errors.name}
					value={values.name}
					inputProps={{ 'data-test': 'subControl-input-name' }}
					onChange={change.bind(null, 'name')}
				/>
				<TextField
					name="rate"
					autoComplete="pf-rate"
					type="number"
					error={touched.rate && Boolean(errors.rate)}
					required
					disabled={props.disabled}
					label="割合(0%〜100%)"
					margin="normal"
					style={{ width: '150px' }}
					helperText={touched.rate && errors.rate}
					value={values.rate}
					inputProps={{
						'data-test': 'subControl-input-rate',
						min: 0,
						max: 100,
					}}
					onChange={change.bind(null, 'rate')}
				/>
				<Button
					variant="contained"
					color="primary"
					onClick={() => handleSubmit()}
					disabled={props.disabled}
					data-test="subControl-submit-btn"
					size={'large'}
					style={{ margin: '30px 0 0 15px' }}
				>
					追加する
				</Button>
			</form>
		</div>
	)
}

export default CreateSubControlForm
