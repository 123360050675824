import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { refInit } from '../../../store/App/operations'
import { getIsLogin } from '../../../store/Auth/selectors'

export const useAuth = () => {
	const isLogin = useSelector(getIsLogin)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(refInit())
	}, [dispatch])
	return { isLogin }
}
