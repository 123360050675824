import { HouseRaw } from '../types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const DemoData = require('../containers/Demo/resource/demo-house.json')

export const houseDate = [DemoData as HouseRaw] as HouseRaw[]

export const isDemoMode = () => {
	if (String(window.location).includes('houses/0')) {
		return true
	}
	return false
}
