import {
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RightBox } from '../../components'
import Modal from '../../components/Modal'
import { updateOrCreateSteper } from '../../store/HouseContainer/operations'
import { getSteperOne } from '../../store/SteperById/selectors'
import { Control, House, Scenario, TimePattern } from '../../types'
import { toStepRate } from '../../utils'
import EditSteperForm from './components/EditSteperForm'
import StepBar from './components/StepBar'

type Props = {
	house: House
	scenario: Scenario
	control: Control
	timePattern: TimePattern
	hasQd: boolean
}

function SteperManageTab({ scenario, control, timePattern, hasQd }: Props) {
	const [open, setOpen] = React.useState(false)
	const dispatch = useDispatch()
	const steper = useSelector((s) =>
		getSteperOne(s, scenario.steperIds, timePattern, control),
	)
	const enableStyle = steper.enabled ? {} : { background: '#ddd' }

	return (
		<Container>
			<div style={{ marginTop: '8px' }}>
				<RightBox>
					<Button variant="outlined" onClick={() => setOpen(true)}>
						<SettingsIcon />
						編集{!steper.enabled && '・有効にする'}
					</Button>
				</RightBox>
				{!steper.enabled && <Typography>無効です</Typography>}
				<div style={{ ...enableStyle }}>
					<StepBar steper={steper} value={toStepRate(control.rate)} />
					<TableContainer component={Paper}>
						<Table style={{ ...enableStyle }}>
							<TableBody>
								<TableRow>
									<TableCell>開始値</TableCell>
									<TableCell>{steper.bottom}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>感度</TableCell>
									<TableCell>{steper.step}</TableCell>
								</TableRow>
								{control.category === 'window' && (
									<TableRow>
										<TableCell>雨で全閉</TableCell>
										<TableCell>{steper.rainOff ? '有効' : '無効'}</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					{hasQd && (
						<Typography variant="caption">
							※クイックドロップが有効の場合、重なっているスケジュールではクリックドロップの設定が優先されます。
						</Typography>
					)}
				</div>
			</div>
			<Modal open={open} closeModal={() => setOpen(false)}>
				<EditSteperForm
					initialValues={_.omit(
						{
							...steper,
							timePattern,
							scenarioId: scenario.id,
							controlId: control.id,
						},
						['id'],
					)}
					category={control.category}
					disabled={false}
					handleSubmit={(field) => {
						dispatch(updateOrCreateSteper(field, steper.id))
						setOpen(false)
					}}
				/>
			</Modal>
		</Container>
	)
}
SteperManageTab.defaultProps = {
	hasQd: false,
}

export default SteperManageTab
