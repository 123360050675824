import styled from 'styled-components'
import React, { PropsWithChildren } from 'react'
import { Box } from '@mui/material'

export const RightBox = styled.div`
	justify-content: flex-end;
	display: flex;
`

export const WrapImg = styled.img`
	width: 100%;
	border-radius: 4px;
	border: solid 2px #00a39a;
`

export const FlexBox = (props: PropsWithChildren<{}>) => (
	<Box sx={{ display: 'flex', gap: '4px' }} {...props} />
)

export const FlexBoxRight = (props: PropsWithChildren<{}>) => (
	<Box
		sx={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}
		{...props}
	/>
)
