import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { SnackbarText } from '../../types'
import * as actions from './actions'

export type State = { newest: SnackbarText | null }

export const initialState: State = { newest: null }

export default reducerWithInitialState(initialState)
	.case(actions.createSnackbar, (state, newest) => ({ newest }))
	.case(actions.clearSnackbar, () => ({ newest: null }))
