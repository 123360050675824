import { SwipeableDrawer } from '@mui/material'
import React from 'react'
import { TabId } from '../../types'
import DrawerContents from './DrawerContents'

type Props = {
	isTop: boolean
	isOpen: boolean
	onSelectTab: (tabId: TabId) => void
	onOpenDrawer: () => void
	onCloseDrawer: () => void
	logout: () => void
}

const SideDrawer = (props: Props) => {
	return (
		<nav>
			<SwipeableDrawer
				open={props.isOpen}
				anchor="left"
				onOpen={() => props.onOpenDrawer()}
				onClose={() => props.onCloseDrawer()}
			>
				<DrawerContents
					isTop={props.isTop}
					onSelectTab={props.onSelectTab}
					logout={() => {
						props.onCloseDrawer()
						props.logout()
					}}
				/>
			</SwipeableDrawer>
		</nav>
	)
}

export default SideDrawer
