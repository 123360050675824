import _ from 'lodash'
import { State, Trigger } from '../../types'
import { getHouse } from '../HouseById/selectors'
import { compTrigger } from '../../utils'

export function getTrigger(state: State, id: number): Trigger {
	return state.TriggerById[id]
}

export function getTriggers(state: State, houseId: number): Trigger[] {
	const house = getHouse(state, houseId)

	return _.sortBy(
		house.triggerIds.map((id) => getTrigger(state, id)),
		compTrigger,
	)
}
