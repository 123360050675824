import _ from 'lodash'
import { HouseRaw, Scenario, ThunkAction } from '../../types'
import { saveEvents } from '../EventById/operations'
import { saveStepers } from '../SteperById/operations'
import { registerScenarios } from './actions'

export const saveScenarios = (house: HouseRaw): ThunkAction => {
	return async (dispatch) => {
		await Promise.all(
			house.scenarios.map(async (scenario) => {
				await dispatch(saveEvents(scenario))
				await dispatch(saveStepers(scenario))
			}),
		)
		const scenarios = batchScenarios(house)

		await dispatch(registerScenarios(scenarios))
	}
}

function batchScenarios(house: HouseRaw): Scenario[] {
	return house.scenarios.map(
		(scenario) =>
			({
				..._.omit(scenario, ['events']),
				isActive: `${house.activeScenarioId}` === `${scenario.id}`,
				eventIds: scenario.events.map((v) => v.id),
				steperIds: scenario.stepers.map((v) => v.id),
				isNew: false,
			} as Scenario),
	)
}
