import { Scenario, State } from '../../types'
import { getHouse } from '../HouseById/selectors'

export function getScenario(state: State, id: number): Scenario {
	return state.ScenarioById[id]
}

export function getScenarios(state: State, houseId: number): Scenario[] {
	const house = getHouse(state, houseId)

	return house.scenarioIds.map((id) => getScenario(state, id))
}
