import _ from 'lodash'

import { Typography } from '@mui/material'
import moment from 'moment'
import {
	Area,
	AreaChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts'
import { DayChartLine, Log } from '../../types'
import { graphSensorLabelByKey } from '../../utils'

type Props = {
	logs: Log[]
	yTicks: number[]
	lines: DayChartLine[]
}

const makeJustTimeTicks = (logs: Log[]): number[] => {
	if (logs.length === 0) {
		return []
	}
	const m = moment(logs[0].timestamp).startOf('date')
	const ticks = [] as number[]

	_.range(25).forEach(() => {
		ticks.push(m.unix() * 1000)
		m.add(1, 'hour')
	})
	return ticks
}

function DayChartGraph(props: Props) {
	const { logs } = props

	if (logs.length === 0) {
		return (
			<Typography style={{ paddingTop: '1rem' }}>ログはありません</Typography>
		)
	}
	const ticks = makeJustTimeTicks(logs)

	return (
		<ResponsiveContainer aspect={2} width="100%">
			<AreaChart data={logs} margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
				<XAxis
					ticks={ticks}
					type={'number'}
					domain={['dataMin', 'dataMax']}
					dataKey="timestamp"
					tickFormatter={(v) => moment(v).format('H') + 'H'}
				/>
				{props.lines.map((line) => (
					<YAxis
						key={line.dataKey}
						width={1}
						yAxisId={line.dataKey}
						tick={props.lines.length === 1 && { dx: 40, dy: 8 }}
						ticks={props.yTicks}
						domain={['auto', 'auto']}
						type={'number'}
					/>
				))}
				<Tooltip labelFormatter={(v) => moment(v).format('HH:mm')} />
				<CartesianGrid stroke="#e5e5e5" />
				{props.lines.map((line) => (
					<Area
						name={graphSensorLabelByKey[line.dataKey]}
						key={line.dataKey}
						yAxisId={line.dataKey}
						type="monotone"
						// dot={false}
						// strokeWidth={2}
						stroke={line.stroke}
						dataKey={line.dataKey}
						fill={line.stroke}
						fillOpacity={0.0}
						isAnimationActive={false}
					/>
				))}
			</AreaChart>
		</ResponsiveContainer>
	)
}

DayChartGraph.defaultProps = {
	yTicks: undefined,
}

export default DayChartGraph
