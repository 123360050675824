import { connect } from 'react-redux'
import ScenarioRow from '../../components/ScenarioRow'
import { Scenario, State } from '../../types'

import { switchScenario } from '../../store/HouseContainer/operations'

type OProps = {
	scenario: Scenario
	keyi: number
}

const ms = (state: State, op: OProps) => ({
	scenario: op.scenario,
	keyi: op.keyi,
	disabled: state.Network.isLoading,
})

const conn = connect(ms, {
	handleSwitch: switchScenario,
})

export default conn(ScenarioRow)
