import { cli } from '../config/init'
import {
	ActionLogRaw,
	AuthResponse,
	CreateControlField,
	CreateSteperField,
	EditEventExField,
	EditEventHeatingField,
	HouseRaw,
} from '../types'
import { houseDate, isDemoMode } from './acitonLogsAPI'

export async function postLogin(params: {
	email: string
	password: string
}): Promise<AuthResponse> {
	const response = await cli()
		.post<AuthResponse>('/login', params)
		.catch((res) => {
			return {
				data: { error: res?.response?.data?.error || 'エラーが発生しました。' },
			}
		})

	return response.data
}

// @HACKME
export async function getHouses() {
	if (isDemoMode()) {
		return houseDate
	}

	const res = await cli().get<HouseRaw[]>('/houses')
	const body = res.data

	if (res.status !== 200) {
		throw new Error('invalid token')
	}
	return body
}

type Req = {
	path: string
	params?: Record<string, unknown>
	token?: string
}

export async function get({ path = '/', params = {} }: Req): Promise<unknown> {
	const res = await cli().get(path, { params })

	return res.data
}

export async function post({ path = '/', params = {} }: Req): Promise<unknown> {
	const res = await cli().post(path, params)

	return res.data
}

export async function put({ path = '/', params = {} }: Req): Promise<unknown> {
	const res = await cli().put(path, params)

	return res.data
}

export async function del({ path = '/', params = {} }: Req): Promise<unknown> {
	const res = await cli().delete(path, params)

	return res.data
}

export async function deleteControl(controlId: number): Promise<unknown> {
	const res = await cli().delete(`/controls/${controlId}`)

	return res.data
}

export async function runControl(controlId: number): Promise<unknown> {
	const res = await cli().put(`/controls/${controlId}/run`)

	return res.data
}

export async function resetControlVirtualState(
	controlId: number,
	subControlId: number,
): Promise<unknown> {
	const res = await cli().put(`/controls/${controlId}/reset`, {
		sub_id: subControlId,
	})

	return res.data
}

function normalizePinIndex(n: number | null | string): number | null {
	if (n === null || n === '' || isNaN(Number(n))) return -1

	return Number(n) - 1
}
export async function updateControl(
	params: CreateControlField,
	controlId: number,
): Promise<unknown> {
	const control = {
		label: params.label,
		active_pin: normalizePinIndex(params.activePin),
		negative_pin: normalizePinIndex(params.negativePin),
		timer: params.timer || null,
		cooltime: params.cooltime,
	}

	const res = await cli().put(`/controls/${controlId}`, { control })

	return res.data
}

export async function createControl(
	params: CreateControlField,
): Promise<unknown> {
	const control = {
		label: params.label,
		active_pin: normalizePinIndex(params.activePin),
		negative_pin: normalizePinIndex(params.negativePin),
		category: params.category,
		house_id: params.houseId,
		timer: params.timer || null,
		cooltime: params.cooltime,
	}

	const res = await cli().post(`/controls`, { control })

	return res.data
}

export async function createSteper(
	params: CreateSteperField,
): Promise<unknown> {
	const res = await cli().post(`/stepers`, {
		steper: {
			control_id: params.controlId,
			scenario_id: params.scenarioId,
			enabled: params.enabled,
			bottom: params.bottom,
			sensor: params.sensor,
			step: params.step,
			time_pattern: params.timePattern,
			step_time_down: 1, // dummy
			step_time_up: 1,
		},
	})

	return res.data
}

export async function updateSteper(
	params: CreateSteperField,
	steperId: number,
): Promise<unknown> {
	const res = await cli().put(`/stepers/${steperId}`, {
		steper: {
			control_id: params.controlId,
			scenario_id: params.scenarioId,
			enabled: params.enabled,
			bottom: params.bottom,
			sensor: params.sensor,
			step: params.step,
			rain_off: params.rainOff,
			time_pattern: params.timePattern,
		},
	})

	return res.data
}

export async function createScenario(params: {
	houseId: number
	name: string
}): Promise<unknown> {
	const res = await cli().post(`/scenarios`, {
		scenario: {
			name: params.name,
			house_id: params.houseId,
		},
	})

	return res.data
}

export async function deleteTrigger(triggerId: number): Promise<unknown> {
	const res = await cli().delete(`/triggers/${triggerId}`)

	return res.data
}

const makeBodyEventEx = (params: EditEventExField) => ({
	event: {
		scenario_id: params.scenarioId,
		active_interval: params.activeInterval,
		negative_interval: params.negativeInterval,
		enabled: params.enabled,
		tmp_above: params.tmpAbove,
		hmd_above: params.hmdAbove,
		co2_above: params.co2Above,
		window_check: params.windowCheck,
		sub_control_id: params.subControlId,
		time_pattern: params.timePattern,
	},
})

export async function updateEventEx(
	params: EditEventExField,
	eventId: number,
): Promise<unknown> {
	const res = await cli().put(`/events/${eventId}`, makeBodyEventEx(params))

	return res.data
}
export async function createEventEx(
	params: EditEventExField,
): Promise<unknown> {
	const res = await cli().post(`/events`, makeBodyEventEx(params))

	return res.data
}

export async function deleteEvent(eventId: number): Promise<unknown> {
	const res = await cli().delete(`/events/${eventId}`)

	return res.data
}

const makeBodyEventHeating = (params: EditEventHeatingField) => ({
	event: {
		scenario_id: params.scenarioId,
		sub_control_id: params.subControlId,
		enabled: params.enabled,
		tmp_above: params.tmpAbove,
		start_time: params.startTime,
		end_time: params.endTime,
	},
})

export async function updateEventHeating(
	params: EditEventHeatingField,
	eventId: number,
): Promise<unknown> {
	const res = await cli().put(
		`/events/${eventId}`,
		makeBodyEventHeating(params),
	)

	return res.data
}
export async function createEventHeating(
	params: EditEventHeatingField,
): Promise<unknown> {
	const res = await cli().post(`/events`, makeBodyEventHeating(params))

	return res.data
}

export async function getActionLogs(
	houseId: number,
	day: string,
): Promise<ActionLogRaw[]> {
	const res = await cli().get<ActionLogRaw[]>(
		`/houses/${houseId}/action_logs`,
		{
			params: { day },
		},
	)

	return res.data
}
