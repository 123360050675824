import MuiModal from '@mui/material/Modal'
import React, { CSSProperties } from 'react'
import { WithChild } from '../types'

const paperStyle: CSSProperties = {
	position: 'absolute',
	width: '100vw',
	backgroundColor: '#fff',
	// backgroundColor: theme.palette.background.paper,
	padding: '1rem 40px',
	top: `50%`,
	left: `50%`,
	minHeight: '50vh',
	transform: `translate(-50%, -50%)`,
}

type Props = {
	open: boolean
	closeModal: () => void
}
function Modal({ children, open, closeModal }: WithChild<Props>) {
	return (
		<div>
			<MuiModal open={open} onClose={() => closeModal()}>
				<div style={paperStyle}>{children}</div>
			</MuiModal>
		</div>
	)
}

export default Modal
