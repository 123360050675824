import MockAdapter from 'axios-mock-adapter'
import demoHouseLogs from '../../containers/Demo/resource/demo-house-log.data.json'
import demoHouse from '../../containers/Demo/resource/demo-house.json'
import { AxiosInstance } from 'axios'

demoHouse.lastLog = {
	...demoHouse.lastLog,
	createdAt: new Date().toLocaleString(),
}

// Mock any GET request to /users
// arguments for reply are (status, data, headers)

export const loadDemoMocks = (cli: AxiosInstance) => {
	new MockAdapter(cli)
		.onGet('/houses')
		.reply(200, [demoHouse])
		.onGet(/\/houses\/0\/logs.*/)
		// .onGet('/houses/0/logs/')
		.reply(200, demoHouseLogs)
		.onPut(/.*/)
		.reply((_config) => {
			alert('[サンプル動作です]変更を実行します。')
			return [200, {}]
		})
		.onPost(/.*/)
		.reply((_config) => {
			alert('[サンプル動作です]作成を実行します。')
			return [200, {}]
		})
		.onDelete(/.*/)
		.reply((_config) => {
			alert('[サンプル動作です]削除を実行します。')
			return [200, {}]
		})
}
