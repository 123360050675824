import { noop } from 'lodash'
import React, { createContext, useContext } from 'react'
import { WithChild } from '../types'
import { useLocalStorage } from './useLocalStorage'

type Config = {
	enabledGraphV2: boolean
}
const defaultConfig = {
	enabledGraphV2: false,
}

const ProfileContext = createContext<
	[Config, (config: Config) => void] | undefined
>(undefined)

export const ConfigProvider = ({ children }: WithChild) => {
	const ctxValue = useLocalStorage<Config>('pocketfarm-config', defaultConfig)

	return (
		<ProfileContext.Provider value={ctxValue}>
			{children}
		</ProfileContext.Provider>
	)
}

const useConfigContext = () => useContext(ProfileContext)

export const useConfig = () => {
	const [config, setConfig] =
		useConfigContext() || ([defaultConfig, noop] as const)

	return { config, setConfig }
}
export const useGraphConf = () => {
	const { config, setConfig } = useConfig()
	const { enabledGraphV2 } = config

	return {
		enabledGraphV2,
		setEnabledGraphV2: (enabledGraphV2: boolean) =>
			setConfig({ ...config, enabledGraphV2 }),
	}
}
