import * as client from '../../api/client'
import { LoginFields, ThunkAction } from '../../types'
import { setToken } from '../../utils/tokenUtil'
import { resetRootApp } from '../App/action'
import { updateErrorMessage } from '../LoginPage/actions'
import { authEnd, authStart, removeAuth, saveAuth } from './actions'

export function doLogin(fields: LoginFields): ThunkAction {
	return async (dispatch) => {
		dispatch(authStart())
		const res = await client.postLogin(fields)

		if (res.error !== undefined) {
			// dispatch error message
			dispatch(updateErrorMessage(res.error))
			dispatch(authEnd())
		} else {
			setToken(res.accessToken)
			await dispatch(saveAuth({ email: res.email }))
			await dispatch(authEnd())
		}
	}
}

export function doLogout(): ThunkAction {
	return async (dispatch) => {
		await dispatch(removeAuth())
		await dispatch(resetRootApp())
		// TODO history('/login')
	}
}
