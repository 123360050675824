import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getHouse } from '../../store/HouseById/selectors'
import { updateHouseSetting } from '../../store/HouseContainer/operations'
import EditSettingFromComponent from './components/EditSettingFrom'

type Props = {
	houseId: number
}

function EditSettingFrom(props: Props) {
	const dispatch = useDispatch()
	const house = useSelector((s) => getHouse(s, props.houseId))

	return (
		<EditSettingFromComponent
			disabled={false}
			initialValues={{
				name: house.name,
				lat: house.lat,
				lng: house.lng,
				tmpDetect: house.tmpDetect,
				liveDetect: house.liveDetect,
				rainDetect: house.rainDetect,
				tmpAbove: house.tmpAbove,
				email: house.email,
			}}
			handleSubmit={(fields) =>
				dispatch(updateHouseSetting(props.houseId, fields))
			}
		/>
	)
}

export default EditSettingFrom
