import { Box } from '@mui/material'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { ConfigProvider } from '../../hooks/useLocalConfig'
import { WithChild } from '../../types'
import { useAuth } from '../Auth/components/useAuth'
import NavBar from '../NavBarContainer'
import SideDrawer from '../SideDrawer'

function Layout({ children }: WithChild<{}>) {
	const { isLogin } = useAuth()

	if (!isLogin) return <Navigate to={'/login'} />

	return (
		<div>
			<SideDrawer />
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<NavBar />
				<ConfigProvider>{children}</ConfigProvider>
			</Box>
		</div>
	)
}
export default Layout
