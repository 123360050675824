import { Box, Typography } from '@mui/material'
import React from 'react'
import { BUILD_VERSION } from '../../config'
import { TabId } from '../../types'
import MenuList from './MenuList'

type Props = {
	isTop: boolean
	onSelectTab: (tabId: TabId) => void
	logout: () => void
}

const DrawerContents = (props: Props) => {
	return (
		<Box
			sx={{
				height: '100%',
				display: 'grid',
				gridTemplateRows: '1fr max-content',
			}}
		>
			<MenuList {...props} />
			<Typography
				variant="caption"
				style={{ paddingLeft: '8px', textAlign: 'right' }}
			>
				{BUILD_VERSION}
			</Typography>
		</Box>
	)
}

export default DrawerContents
