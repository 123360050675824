import NavBar from '../../components/NavBar/index'
import ScenarioPage from '../ScenarioPage'

function DemoScenarioPage() {
	return (
		<>
			<NavBar
				doLogout={() => {
					alert('サンプルページです')
				}}
				onClickMenu={() => {
					alert('サンプルページです')
				}}
			/>
			<ScenarioPage />
		</>
	)
}

export default DemoScenarioPage
