import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { FormikErrors, useFormik } from 'formik'
import React from 'react'
import { t } from '../../locale'
import { UpdateControlField } from '../../types'
import { categories, categoryStr, isBoolMachineCategory } from '../../utils'
import { CategoryIcon } from '../MachineTable/MachineIcon'

type Fields = Omit<UpdateControlField, 'houseId'>
type Props = {
	disabled: boolean
	initialValues: Fields
	handleSubmit: (fields: Fields) => void
}

const pinValidate = (v: number | null | string) =>
	v !== '' && v !== null && (v >= 17 || v <= 0)

function CreateControlForm(props: Props) {
	const { initialValues } = props

	const {
		values,
		errors,
		touched,
		handleChange,
		handleSubmit,
		setFieldTouched,
	} = useFormik<Fields>({
		initialValues,
		validate: (values) => {
			const errors: FormikErrors<Fields> = {}

			if (!values.label) {
				errors.label = '必須です。'
			}

			if (pinValidate(values.activePin)) {
				errors.activePin = '1から16の値にしてください。'
			} else if (pinValidate(values.negativePin)) {
				errors.negativePin = '1から16の値にしてください。'
			} else if (!values.activePin && !values.negativePin) {
				errors.activePin =
					'開けるピンか閉めるピンを少なくとも1つ有効にしてください。'
			}
			return errors
		},
		onSubmit: (fields: Fields) => {
			if (isBoolMachineCategory(fields.category)) {
				fields.timer = null
			}
			props.handleSubmit(fields)
		},
	})
	const change = (name: keyof Fields, e: React.ChangeEvent<unknown>) => {
		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
	}
	const isBool = isBoolMachineCategory(values.category)

	return (
		<div>
			<Typography>{t.control.base}を作成</Typography>
			<form style={{ display: 'grid', gap: '8px' }}>
				<div>
					<Typography>機器タイプ</Typography>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: 'repeat(6, 1fr)',
						}}
					>
						{categories.map((category) => {
							return (
								<Button
									key={category}
									disabled={category !== values.category}
									style={{
										height: '4rem',
										padding: '0',
										paddingBottom: '8px',
										textAlign: 'center',
										borderBottomLeftRadius: '0',
										borderBottomRightRadius: '0',
										...(category === values.category
											? {
													border: 'solid 1px black',
													borderBottom: 'none',
											  }
											: {
													borderBottom: 'solid 1px black',
													background: '#ddd',
											  }),
									}}
								>
									<div style={{ display: 'grid' }}>
										<CategoryIcon
											category={category}
											stepRate={6}
											style={{
												height: '1.8rem',
												fontSize: '1.8rem',
											}}
										/>
										<Typography
											style={{
												fontSize: '0.6rem',
											}}
										>
											{categoryStr[category]}
										</Typography>
									</div>
								</Button>
							)
						})}
					</div>
				</div>
				<TextField
					name="label"
					type="text"
					autoComplete="pf-label"
					error={touched.label && Boolean(errors.label)}
					required
					disabled={props.disabled}
					label="名前"
					helperText={touched.label && errors.label}
					value={values.label}
					inputProps={{ 'data-test': 'control-input-label' }}
					onChange={change.bind(null, 'label')}
					fullWidth
				/>
				<TextField
					name="activePin"
					type="number"
					autoComplete="pf-activePin"
					error={touched.activePin && Boolean(errors.activePin)}
					required
					disabled={props.disabled}
					label={isBool ? '制御ピン(1~16)' : '開けるピン(1~16)'}
					helperText={touched.activePin && errors.activePin}
					value={values.activePin}
					inputProps={{ 'data-test': 'control-input-activePin' }}
					onChange={change.bind(null, 'activePin')}
					fullWidth
				/>
				<TextField
					name="negativePin"
					type="number"
					autoComplete="pf-negativePin"
					error={touched.negativePin && Boolean(errors.negativePin)}
					style={{
						visibility: isBool ? 'hidden' : 'visible',
					}}
					required
					disabled={props.disabled}
					label="閉めるピン"
					helperText={touched.negativePin && errors.negativePin}
					value={values.negativePin}
					inputProps={{ 'data-test': 'control-input-negativePin' }}
					onChange={change.bind(null, 'negativePin')}
					fullWidth
				/>
				<TextField
					name="timer"
					type="number"
					autoComplete="pf-timer"
					error={touched.timer && Boolean(errors.timer)}
					required
					disabled={props.disabled}
					style={{
						visibility: isBool ? 'hidden' : 'visible',
					}}
					label="実行時間(秒)"
					helperText={touched.timer && errors.timer}
					value={values.timer}
					inputProps={{ 'data-test': 'control-input-timer', min: 1 }}
					onChange={change.bind(null, 'timer')}
					fullWidth
				/>
				<TextField
					name="cooltime"
					type="number"
					autoComplete="pf-cooltime"
					error={touched.cooltime && Boolean(errors.cooltime)}
					required
					disabled={props.disabled}
					style={{
						visibility: isBool ? 'hidden' : 'visible',
					}}
					label="クールタイム(秒,最低でも空ける実行間隔)"
					helperText={touched.cooltime && errors.cooltime}
					value={values.cooltime}
					inputProps={{ 'data-test': 'control-input-cooltime' }}
					onChange={change.bind(null, 'cooltime')}
					fullWidth
				/>
				<Button
					variant="contained"
					color="primary"
					onClick={() => handleSubmit()}
					disabled={props.disabled}
					size={'large'}
				>
					保存する
				</Button>
			</form>
		</div>
	)
}

export default CreateControlForm
