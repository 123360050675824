import React from 'react'
import { useSelector } from 'react-redux'
import ControlTable from '../../components/MachineTable/ControlTable'
import { getControls } from '../../store/ControlById/selectors'
import { House, Scenario } from '../../types'

type Props = {
	house: House
	scenario?: Scenario
}

function ControlTableContainer(props: Props) {
	const controls = useSelector((s) => getControls(s, props.house.id))

	return <ControlTable {...props} controls={controls} />
}

export default ControlTableContainer
