/* eslint-disable no-restricted-globals */

import _ from 'lodash'
import moment from 'moment'
import * as client from '../../api/client'
import config from '../../config/index'
import { t } from '../../locale'
import {
	Control,
	CreateControlField,
	EditEventCustomField,
	CreateScenarioField,
	CreateSteperField,
	CreateTriggerField,
	Event,
	House,
	HouseRaw,
	Log,
	Scenario,
	SubControl,
	SubmitScenarioField,
	ThunkAction,
	UpdateScenarioField,
	EditEventExField,
	EditEventHeatingField,
	HouseSettingField,
	UpdateControlField,
} from '../../types'
import { sleep } from '../../utils/index'
import { saveControls } from '../ControlById/operations'
import { registerHouses } from '../HouseById/actions'
import { calcLog, saveLogs } from '../LogsById/operations'
import { syncEnd, syncStart } from '../Network/actions'
import { saveScenarios } from '../ScenarioById/operations'
import { snack } from '../Snackbar/operations'
import { saveTriggers } from '../TriggerById/operations'
import { cli } from '../../config/init'

export function isHouseActive(house: HouseRaw): boolean {
	return (
		!(house.lastLog instanceof Array) &&
		moment(house.lastLog.createdAt).isAfter(
			moment().subtract(config.activeJudgeSpan),
		)
	)
}

function houseBatch(house: HouseRaw): House {
	const lastLog = house.lastLog instanceof Array ? null : calcLog(house.lastLog)

	return {
		..._.omit(house, ['controls', 'triggers', 'scenarios']),
		controlIds: _.map(house.controls, 'id'),
		triggerIds: _.map(house.triggers, 'id'),
		scenarioIds: _.map(house.scenarios, 'id'),
		isActive: isHouseActive(house),
		lastLog,
	}
}

export function loadHouses(): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const houseRaws = await client.getHouses()

		await Promise.all(
			houseRaws.map(async (houseRaw) => {
				await dispatch(saveControls(houseRaw))
				await dispatch(saveTriggers(houseRaw))
				await dispatch(saveScenarios(houseRaw))
				const house = houseBatch(houseRaw)

				await dispatch(registerHouses([house]))
			}),
		)
		await dispatch(syncEnd())
	}
}

export function loadLogs({
	houseId,
	day,
}: {
	houseId: number
	day: string
}): ThunkAction {
	return async (dispatch, _getState) => {
		await sleep(500)
		const res = await cli().get<Log[]>(`/houses/${houseId}/logs`, {
			params: { day },
		})

		if (res.status !== 200) {
			throw new Error('invalid token')
		}
		dispatch(saveLogs({ houseId, day, data: res.data }))
	}
}

export function deleteControl(control: Control): ThunkAction {
	return async (dispatch, _getState) => {
		if (!confirm('削除しますか？')) return
		await dispatch(syncStart())
		const res = await client.deleteControl(control.id)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.control.base}を削除しました。`))
	}
}

export function runControl(control: Control): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		if (!confirm('実行しますか？(シナリオがオフになるので注意してください)'))
			return
		const res = await client.runControl(control.id).catch((_e) => false)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.control.base}を実行しました。`))
	}
}

export function resetControlVirtualState(subControl: SubControl): ThunkAction {
	return async (dispatch, _getState) => {
		if (!confirm('状態を選択した値に補正しますか？')) return
		await dispatch(syncStart())
		const res = await client
			.resetControlVirtualState(subControl.controlId, subControl.id)
			.catch((_e) => false)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack('アプリ上での機器の状態を補正しました。'))
	}
}

export function createControl(params: CreateControlField): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.createControl(params)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.control.base}を作成しました。`))
	}
}

export function updateControl(
	params: UpdateControlField,
	controlId: number,
): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.updateControl(params, controlId)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.control.base}を更新しました。`))
	}
}

export function submitScenario(scenario: SubmitScenarioField): ThunkAction {
	if (scenario.isNew) {
		return createScenario(scenario)
	} else {
		return updateScenario(scenario)
	}
}

function createScenario(scenario: CreateScenarioField): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.post({
			path: '/scenarios',
			params: {
				scenario: {
					name: scenario.name,
					house_id: scenario.houseId,
				},
			},
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.scenario.base}を作成しました。`))
	}
}

function updateScenario(scenario: UpdateScenarioField): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.put({
			path: `/scenarios/${scenario.id}`,
			params: {
				scenario: {
					name: scenario.name,
				},
			},
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.scenario.base}を更新しました。`))
	}
}

export function deleteScenario(scenarioId: number): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.del({
			path: `/scenarios/${scenarioId}`,
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.scenario.base}を削除しました。`))
	}
}

export function createTrigger(trigger: CreateTriggerField): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())

		const res = await client.post({
			path: '/triggers',
			params: {
				trigger: {
					house_id: trigger.houseId,
					sensor: trigger.sensor,
					high: trigger.high,
					low: trigger.low,
				},
			},
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack('トリガーを作成しました。'))
	}
}

export function deleteTrigger(triggerId: number): ThunkAction {
	return async (dispatch, _getState) => {
		if (!confirm('削除しますか？')) return
		await dispatch(syncStart())
		const res = await client.deleteTrigger(triggerId)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack('トリガーを削除しました。'))
	}
}

function nonZero(v: unknown) {
	if (!v || v === '0') {
		return null
	}
	return v
}

export function createEvent(event: EditEventCustomField): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.post({
			path: '/events',
			params: {
				event: {
					scenario_id: event.scenarioId,
					trigger_id: nonZero(event.triggerId),
					sub_control_id: event.subControlId,
					start_time: event.startTime,
					end_time: event.endTime,
					active_interval: event.activeInterval,
					negative_interval: event.negativeInterval,
				},
			},
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.event.base}を作成しました。`))
	}
}

// TODO: to SubControlField
export function createSubControl(sc: Partial<SubControl>): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.post({
			path: '/sub_controls',
			params: {
				sub_control: {
					control_id: sc.controlId,
					name: sc.name,
					rate: sc.rate,
				},
			},
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`サブ${t.control.base}を作成しました。`))
	}
}

export function runSubControl(sc: SubControl): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.put({
			path: `/sub_controls/${sc.id}/run`,
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.control.base}を実行しました。`))
	}
}

export function deleteSubControl(sc: SubControl): ThunkAction {
	return async (dispatch, _getState) => {
		if (!confirm('削除しますか？')) return
		await dispatch(syncStart())
		const res = await client.del({
			path: `/sub_controls/${sc.id}`,
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
	}
}

export function updateEvent(event: Event): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.put({
			path: `/events/${event.id}`,
			params: {
				event: {
					scenario_id: event.scenarioId,
					trigger_id: event.triggerId,
					start_time: event.startTime,
					end_time: event.endTime,
					active_interval: event.activeInterval,
					negative_interval: event.negativeInterval,
					enabled: event.enabled,
				},
			},
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.event.base}を更新しました。`))
	}
}

export function toggleEventEnabled(event: Event): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(updateEvent({ ...event, enabled: !event.enabled }))
		if (event.enabled) {
			dispatch(snack(`${t.event.base}を無効にしました。`))
		} else {
			dispatch(snack(`${t.event.base}を有効にしました。`))
		}
	}
}

export function deleteEvent(eventId: number): ThunkAction {
	return async (dispatch, _getState) => {
		if (!confirm('削除しますか？')) return
		await dispatch(syncStart())
		const res = await client.del({
			path: `/events/${eventId}`,
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.event.base}を削除しました。`))
	}
}

export function switchScenario(scenario: Scenario): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.put({
			path: `/houses/${scenario.houseId}`,
			params: {
				house: {
					active_scenario_id: scenario.id,
				},
			},
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.scenario.base}を切り替えました。`))
	}
}

export function clearScenario(houseId: number): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.put({
			path: `/houses/${houseId}`,
			params: {
				house: {
					active_scenario_id: null,
				},
			},
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`${t.scenario.base}をオフにしました。`))
	}
}

export function updateOrCreateSteper(
	params: CreateSteperField,
	steperId: number,
) {
	if (steperId === 0) return createSteper(params)
	else return updateSteper(params, steperId)
}

export function updateSteper(
	params: CreateSteperField,
	steperId: number,
): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.updateSteper(params, steperId).catch((_e) => false)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack('変更しました。'))
	}
}

export function createSteper(params: CreateSteperField): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.createSteper(params).catch((_e) => false)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack('変更しました。'))
	}
}

export function updateOrCreateEventEx(
	params: EditEventExField,
	steperId: number,
) {
	if (steperId === 0) return createEventEx(params)
	else return updateEventEx(params, steperId)
}

export function updateEventEx(
	params: EditEventExField,
	steperId: number,
): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client
			.updateEventEx(params, steperId)
			.catch((_e) => false)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack('変更しました。'))
	}
}

export function createEventEx(params: EditEventExField): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.createEventEx(params).catch((_e) => false)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack('変更しました。'))
	}
}

export function updateOrCreateEventHeating(
	params: EditEventHeatingField,
	eventId: number,
) {
	if (eventId === 0) return createEventHeating(params)
	else return updateEventHeating(params, eventId)
}

export function updateEventHeating(
	params: EditEventHeatingField,
	steperId: number,
): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client
			.updateEventHeating(params, steperId)
			.catch((_e) => false)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack('変更しました。'))
	}
}

export function createEventHeating(params: EditEventHeatingField): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.createEventHeating(params).catch((_e) => false)

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack('変更しました。'))
	}
}

export function updateHouseSetting(
	houseId: number,
	fields: HouseSettingField,
): ThunkAction {
	return async (dispatch, _getState) => {
		await dispatch(syncStart())
		const res = await client.put({
			path: `/houses/${houseId}`,
			params: {
				house: {
					email: fields.email,
					rain_detect: fields.rainDetect,
					tmp_detect: fields.tmpDetect,
					tmp_above: fields.tmpAbove,
					live_detect: fields.liveDetect,
				},
			},
		})

		console.log(res)
		await dispatch(loadHouses())
		await dispatch(syncEnd())
		dispatch(snack(`設定を変更しまし`))
	}
}
