export const t = {
	screens: {
		control: '手動制御',
	},
	control: {
		base: '制御',
	},
	scenario: {
		base: '自動制御',
	},
	event: {
		base: 'コントロール',
	},
}
