import {
	Button,
	FormControlLabel,
	ListItemSecondaryAction,
	Switch,
	TextField,
	Typography,
} from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { FormikErrors, useFormik } from 'formik'
import React from 'react'
import { t } from '../../../locale'
import { EditEventExField } from '../../../types'

type Fields = EditEventExField
type Props = {
	initialValues: Fields
	disabled: boolean
	handleSubmit: (fields: Fields) => void
}

const EditMistEventForm = (props: Props) => {
	const { disabled, initialValues } = props

	const {
		values,
		errors,
		touched,
		handleChange,
		handleSubmit,
		setFieldTouched,
	} = useFormik<Fields>({
		initialValues,
		validate: (_values) => {
			const errors: FormikErrors<Fields> = {}

			return errors
		},
		onSubmit: (fields: Fields) => {
			props.handleSubmit(fields)
		},
	})

	const change = (name: keyof Fields, e: React.ChangeEvent<unknown>) => {
		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
	}

	return (
		<div>
			<Typography variant="h6">{t.event.base}を編集</Typography>
			<FormControlLabel
				control={
					<Switch
						checked={values.enabled}
						onChange={change.bind(null, 'enabled')}
						name="enabled"
						color="primary"
					/>
				}
				label="有効にする"
			/>
			<List style={{ background: values.enabled ? 'white' : '#ddd' }}>
				<ListItem>
					<ListItemText primary={'温度(以上)'} />
					<ListItemSecondaryAction style={{ width: '80px' }}>
						<TextField
							type="number"
							name="tmpAbove"
							value={values.tmpAbove}
							disabled={disabled}
							error={touched.tmpAbove && Boolean(errors.tmpAbove)}
							helperText={touched.tmpAbove && errors.tmpAbove}
							onChange={change.bind(null, 'tmpAbove')}
						/>
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem>
					<ListItemText secondary={'または'} />
				</ListItem>
				<ListItem divider>
					<ListItemText primary={'湿度(以上)'} />
					<ListItemSecondaryAction style={{ width: '80px' }}>
						<TextField
							type="number"
							name="hmdAbove"
							value={values.hmdAbove}
							disabled={disabled}
							error={touched.hmdAbove && Boolean(errors.hmdAbove)}
							helperText={touched.hmdAbove && errors.hmdAbove}
							onChange={change.bind(null, 'hmdAbove')}
						/>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
			<div style={{ textAlign: 'right' }}>
				<Button onClick={() => handleSubmit()} color="primary" size={'large'}>
					変更する
				</Button>
			</div>
		</div>
	)
}

export default EditMistEventForm
