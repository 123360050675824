import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { connect } from 'react-redux'
import {
	deleteSubControl,
	resetControlVirtualState,
	runSubControl,
} from '../../store/HouseContainer/operations'
import { Control, State, SubControl } from '../../types'
import { isStepMachineCategory } from '../../utils'

type OProps = {
	control: Control
	subControl: SubControl
	keyi: string
}

type Props = {
	control: Control
	subControl: SubControl
	keyi: string
	disabled: boolean
	editMode: boolean

	deleteSubControl: (subControl: SubControl) => void
	runSubControl: (subControl: SubControl) => void
	resetControl: (subControl: SubControl) => void
}

function Container(props: Props) {
	return (
		<ListItem
			data-test={`subControl-item-${props.keyi}`}
			style={{
				display: 'flex',
				justifyContent: 'flex-end',
			}}
		>
			<div style={{ display: 'flex' }}>
				<Typography variant="subtitle1">{props.subControl.name}</Typography>-
				<Typography variant="subtitle1">
					{(props.control.timer * props.subControl.rate) / 100}秒
				</Typography>
				-
				<Typography variant="subtitle1">
					({props.subControl.rate}%
					{isStepMachineCategory(props.control.category) && '開'})
				</Typography>
			</div>
			<div style={{ marginLeft: '1em', display: 'flex' }}>
				{props.editMode && (
					<>
						<Button
							disabled={props.disabled}
							variant="contained"
							onClick={() => {
								props.resetControl(props.subControl)
							}}
							data-test={`control-reset-btn-${props.keyi}`}
						>
							補正する
						</Button>
						<Button
							disabled={props.disabled}
							variant="contained"
							color="secondary"
							onClick={() => {
								props.deleteSubControl(props.subControl)
							}}
							data-test={`control-del-btn-${props.keyi}`}
						>
							削除する
						</Button>
					</>
				)}
				{!props.editMode && (
					<Button
						variant="contained"
						disabled={props.disabled}
						onClick={() => {
							if (!window.confirm('自動制御がオフになりますが実行しますか？'))
								return
							props.runSubControl(props.subControl)
						}}
					>
						実行
					</Button>
				)}
			</div>
		</ListItem>
	)
}

const ms = (state: State, op: OProps) => ({
	...op,
	disabled: state.Network.isLoading,
	editMode: state.SettingContainer.editControl,
})

const conn = connect(ms, {
	deleteSubControl,
	runSubControl,
	resetControl: resetControlVirtualState,
})

export default conn(Container)
