import { PropsWithChildren } from 'react'
import { ThunkAction as _ThunkAction } from 'redux-thunk'
import { Action, AnyAction } from 'typescript-fsa'
import { State as _State } from './state'

export type State = _State

export type GetState = () => State

export type ThunkAction = _ThunkAction<
	void | Promise<void>,
	State,
	undefined,
	AnyAction | Action<unknown>
>

export type Auth = {
	authorized: boolean
	email: string
}

export type Sensor = {
	id: number
	label: string
	unit: string
	key: SensorType
}

export type AuthResponse =
	| {
			id: number
			email: string
			accessToken: string
			error: undefined
	  }
	| {
			error: string
	  }

export type Log = {
	id: number
	houseId: number
	tmp: number
	hmd: number
	co2: number
	psr: number
	wat: number
	lux: number
	bad: boolean
	// client side calc
	vpd: number
	dew: number
	hm: string
	timestamp: number
	createdAt: string
}

export type DayLogById = {
	logs: Log[]
	tempMax: number
	tempMin: number

	movingAvgTmp24: number
	movingAvgTmp48: number
	movingAvgTmp72: number
	integrationTmp: number
}

export type SubControlField = {
	name: string
	rate: number
	controlId: number
}

export type SubControl = {
	id: number
	label: string
	name: string
	rate: number
	controlId: number
}

export type ControlField = {
	houseId: number
	label: string
	category: MachineCategory
	activePin: number
	negativePin: number
	activePinView: string | null
	negativePinView: string | null
	rate: number
	timer: number
	cooltime: number
}

export type UpdateControlField = {
	houseId: number
	category: MachineCategory
	label: string
	activePin: number | null
	negativePin: number | null
	timer: number | null
	cooltime: number
}

export type CreateControlField = {
	houseId: number
	category: MachineCategory
	label: string
	activePin: number | null
	negativePin: number | null
	timer: number | null
	cooltime: number
}

export type ControlRaw = ControlField & {
	id: number
	category?: MachineCategory
	subControls: SubControl[]
}

export type Control = ControlField & {
	id: number
	isHit: boolean
	subControlIds: number[]
	isNew: false
}

export type ControlNew = ControlField & {
	id: null
	isNew: true
}

export type Trigger = {
	id: number
	houseId: number
	sensor: number
	high: number | null // TODO: fix string from api
	low: number | null
	label: string
	ruleText: string
}
export type CreateTriggerField = Omit<Trigger, 'id' | 'label' | 'ruleText'>
export type DeleteTriggerField = { triggerId: number }

export const sensors = ['tmp', 'hmd', 'co2', 'psr', 'wat', 'lux'] as const
export type SensorType = typeof sensors[number]

export const timePatternsBase = ['base_t1', 'base_t2', 'base_t3'] as const
export const timePatterns = [...timePatternsBase, 'qd'] as const
export type TimePattern = typeof timePatterns[number]

export const initSteperField: SteperField = {
	scenarioId: 0,
	controlId: 0,
	enabled: false,
	timePattern: 'base_t1',
	sensor: 'tmp',
	bottom: 20,
	rainOff: false,
	step: 2,
}
export const initSteper: Steper = {
	id: 0,
	scenarioId: 0,
	controlId: 0,
	enabled: false,
	timePattern: 'base_t1',
	sensor: 'tmp',
	rainOff: false,
	bottom: 20,
	step: 2,
}

export type SteperField = {
	controlId: number
	scenarioId: number
	enabled: boolean
	timePattern: TimePattern
	sensor: SensorType
	bottom: number
	step: number
	rainOff: boolean
}
export type Steper = SteperField & {
	id: number
}
export type SteperRaw = SteperField & {
	id: number
}
export type CreateSteperField = Omit<SteperField, 'id'>
export type UpdateSteperField = Omit<SteperField, 'id'>

export type EventBase = {
	id: number
	enabled: boolean
	scenarioId: number
	subControlId: number
}

export type EventCustomField = {
	triggerId: number | null
	activeInterval: number | null
	negativeInterval: number | null
	startTime: string
	endTime: string
	isFullTime: boolean
	exclusive: boolean
}

export type EventHeatingField = {
	triggerId: null
	activeInterval: null
	negativeInterval: null
	startTime: string
	endTime: string
	isFullTime: boolean
	exclusive: boolean
	tmpAbove: number
}

export type EventCustom = EventBase &
	EventCustomField & {
		timePattern: null
		tmpAbove: null
		hmdAbove: null
		co2Above: null
		windowCheck: false
	}

export const initEventCustomField: EventCustomField = {
	triggerId: null,
	activeInterval: null,
	negativeInterval: null,
	startTime: '00:00',
	endTime: '23:59',
	isFullTime: true,
	exclusive: false,
}
export const initEventHeatingField: EventHeatingField = {
	triggerId: null,
	activeInterval: null,
	negativeInterval: null,
	startTime: '00:00',
	endTime: '00:59',
	isFullTime: false,
	tmpAbove: 30,
	exclusive: false,
}

export const initEventHeating: EventHeating = {
	id: 0,
	enabled: false,
	hmdAbove: null,
	co2Above: null,
	windowCheck: false,
	scenarioId: 0,
	subControlId: 0,
	timePattern: null,
	...initEventHeatingField,
}

export const initEventExField: EditEventExField = {
	enabled: false,
	scenarioId: 0,
	subControlId: 0,
	timePattern: 'base_t1',
	activeInterval: null,
	negativeInterval: null,
	tmpAbove: null,
	hmdAbove: null,
	co2Above: null,
	windowCheck: false,
	exclusive: false,
}
const eventExOpt = { triggerId: null, startTime: null, endTime: null, id: 0 }

export const initEventFanField = {
	...initEventExField,
	activeInterval: 1,
	negativeInterval: 0,
}
export const initEventFan: EventEx = { ...initEventFanField, ...eventExOpt }

export const initEventMistField = {
	...initEventExField,
	tmpAbove: 50,
	hmdAbove: 50,
}
export const initEventMist: EventEx = { ...initEventMistField, ...eventExOpt }

export const initEventCo2Field = {
	...initEventExField,
	co2Above: 400,
	windowCheck: false,
}
export const initEventCo2: EventEx = { ...initEventCo2Field, ...eventExOpt }

export const isEventEx = (e: Event): e is EventEx => e.timePattern !== null
export const isEventCustom = (e: Event): e is EventCustom =>
	e.timePattern === null && typeof e.tmpAbove !== 'number'
export const isEventHeating = (e: Event): e is EventHeating =>
	e.timePattern === null && typeof e.tmpAbove === 'number'

export type EventFanField = {
	timePattern: TimePattern
	activeInterval: number | null
	negativeInterval: number | null
	tmpAbove: null
	hmdAbove: null
	co2Above: null
	windowCheck: false
}

export type EventCo2Field = {
	timePattern: TimePattern
	activeInterval: null
	negativeInterval: null
	tmpAbove: null
	hmdAbove: null
	co2Above: number
	windowCheck: boolean
}

export type EventMistField = {
	timePattern: TimePattern
	activeInterval: null
	negativeInterval: null
	tmpAbove: number
	hmdAbove: number
	co2Above: null
	windowCheck: false
}

export type EventExField = {
	timePattern: TimePattern
	activeInterval: number | null
	negativeInterval: number | null
	tmpAbove: number | null
	hmdAbove: number | null
	co2Above: number | null
	windowCheck: boolean
	exclusive: false
}

export type EventEx = EventBase &
	EventExField & {
		triggerId: null
		startTime: null
		endTime: null
	}

export type EventHeating = EventBase &
	EventHeatingField & {
		triggerId: null
		timePattern: null
		// tmpAbove: null
		hmdAbove: null
		co2Above: null
		windowCheck: false
	}
export type Event = EventCustom | EventEx | EventHeating
export type EditEventCustomField = Omit<
	EventBase & EventCustomField,
	'id' | 'enabled' | 'isFullTime'
>
export type EditEventExField = Omit<EventBase & EventExField, 'id'>
export type EditEventHeatingField = Omit<EventBase & EventHeatingField, 'id'>

export type TimeGroup = {
	isFullTime: boolean
	startTime: string
	endTime: string
	startTimeStr: string
	endTimeStr: string
	eventss: EventFull[]
	// eventsIds: number[]
}

export type EventFull = EventCustom & {
	trigger?: Trigger
	subControl: SubControl
}

export type ScenarioField = {
	id: number
	name: string
	houseId: number
	isActive: boolean
}

export type Scenario = ScenarioField & {
	eventIds: number[]
	steperIds: number[]
	isNew: false
}

export type CreateScenarioField = {
	name: string
	houseId: number
	isNew: true
}
export type UpdateScenarioField = {
	id: number
	name: string
	houseId: number
	isNew: false
}
export type SubmitScenarioField = CreateScenarioField | UpdateScenarioField

export type ScenarioRaw = ScenarioField & {
	events: Event[]
	stepers: SteperRaw[]
}

export type ScenarioNew = {
	houseId: number
	name: string
	isNew: true
}

export type ScenarioFormField = Scenario | ScenarioNew

export type TabId = 'home' | 'graph' | 'scenario' | 'control' | 'setting'
// | 'actionlogs'
export type AppConfig = {
	editScenario: boolean
	editControl: boolean
	editTrigger: boolean
	editEvent: boolean
	selectedTabId: TabId
}

export type Form = {}

export type LoginFields = {
	email: string
	password: string
}

export type HouseSettingField = {
	name: string
	lat: number
	lng: number
	rainDetect: boolean
	tmpDetect: boolean
	tmpAbove: number
	liveDetect: boolean
	email: string
}

export type HouseField = HouseSettingField & {
	id: number
	name: string
	tmp2: number
	tmp3: number
	activeScenarioId: number
	pin: number
}

export type HouseRaw = HouseField & {
	controls: ControlRaw[]
	triggers: Trigger[]
	scenarios: ScenarioRaw[]
	lastLog: Log | number[]
}

export type House = HouseField & {
	controlIds: number[]
	triggerIds: number[]
	scenarioIds: number[]
	lastLog: Log | null
	isActive: boolean
}

export type StepMachineCategory =
	| 'curtain' // カーテン E 6段階
	| 'window' // 窓 E 6段階

export type StepMachine = {
	category: StepMachineCategory
	label: string
	controlStartTemperature: number
	sensitivity: number
	upControlTimeSec: number
	downControlTimeSec: number
	conditionPer: number // 割合
	conditionDiv: number // div で n段階目
	unit: string
	max: number
	min: number
	divs: number // 5 なら 0,1,2,3,4,5
}

export type BoolMachineCategory =
	| 'fan' // ファン
	| 'mist' // ミスト
	| 'heating' // 暖房機
	| 'co2' // 二酸化炭素

export type BoolMachine = {
	category: BoolMachineCategory | null
	label: string
	condition: boolean
}

export type MachineCategory = StepMachineCategory | BoolMachineCategory | 'none'

export type Machine = StepMachine | BoolMachine

export type SnackbarText = {
	severity: SeverityKey
	message: string
}

export type SeverityKey = 'error' | 'warning' | 'info' | 'success'
export type StepRate = 0 | 1 | 2 | 3 | 4 | 5 | 6

export type ActionLogRaw = {
	id: number
	tag: '' | 'btn' | 'rainclose'
	createdAt: string
	updatedAt: string
	progress: number
	rate: number
	steperId: number | null
	startAt?: string
	endAt?: string
	realStartAt?: string
	realEndAt?: string
	startPin?: number
	endPin?: number
	startRate?: number
	endRate?: number
	cancelled: boolean
	corrected: boolean
	controlId: number
	runningTime?: number
}

export type ActionLog = {
	id: number
	tag: '' | 'btn' | 'rainclose'
	createdAt: string
	updatedAt: string
	progress: number
	steperId: number | null
	startAt?: string
	endAt?: string
	realStartAt?: string
	realEndAt?: string
	cancelled: boolean
	corrected: boolean
	controlId: number
	runningTime?: number
	startPin?: number
	endPin?: number
	startRate?: number
	endRate?: number
}

export type WithChild<P = {}> = PropsWithChildren<P>

export type DayChartLine = { dataKey: keyof Log; stroke: string }

export type PartLog = {
	id: string
	alog: ActionLog
	timing: 'start' | 'end'
	time?: number
	scheduleTime?: number
	realLess: boolean
	pin?: number
	rate?: number
}
