import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createTrigger } from '../../store/HouseContainer/operations'
import { getRequestIsLoading } from '../../store/Network/selectors'
import { House } from '../../types'
import CreateTriggerForm from './components/CreateTriggerForm'

type Props = {
	house: House
	initSensorId: number
}

const CreateTriggerFormContainer = (props: Props) => {
	const disabled = useSelector(getRequestIsLoading)
	const dispatch = useDispatch()

	return (
		<CreateTriggerForm
			houseId={props.house.id}
			disabled={disabled}
			initSensorId={props.initSensorId}
			handleSubmit={(field) => dispatch(createTrigger(field))}
		/>
	)
}

export default CreateTriggerFormContainer
