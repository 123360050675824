import MenuIcon from '@mui/icons-material/Menu'
import { Box } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React from 'react'
import whiteLogo from './img/white-logo.png'

type Props = {
	doLogout: () => void
	onClickMenu: () => void
}

function NavBar(props: Props) {
	return (
		<div>
			<AppBar position="static" color="primary">
				<Toolbar color="inherit">
					<IconButton
						color="inherit"
						aria-label="Open drawer"
						onClick={() => props.onClickMenu()}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" color="inherit" style={{ flex: 1 }}>
						<img src={whiteLogo} style={{ height: '29px' }} alt="pocketfarm" />
					</Typography>
					<Box sx={{ display: { sm: 'none', xs: 'none' } }}>
						<Button color="inherit" onClick={props.doLogout}>
							ログアウト
						</Button>
					</Box>
				</Toolbar>
			</AppBar>
		</div>
	)
}

export default NavBar
