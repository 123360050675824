import { Box, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getControl } from '../../store/ControlById/selectors'
import { PartLog } from '../../types'
import { hexPinCode, toBitPins, toHms } from '../../utils/businessUtil'

export type Props = {
	plog: PartLog
}

export default function PinLogCard({ plog }: Props) {
	const control = useSelector((s) => getControl(s, plog.alog.controlId))
	const actionLog = plog.alog

	const runRate = Math.floor(
		plog.alog.endRate || 0 - (plog.alog.startRate || 0),
	)
	console.log({ control, runRate })

	return (
		<LogBox
			data-tag={actionLog.tag}
			data-cancelled={actionLog.cancelled}
			data-progress={actionLog.progress}
			data-timing={plog.timing}
		>
			<Box className="time">
				<Typography>{toHms(plog.time)}</Typography>
			</Box>
			<img
				src={`https://avatars.dicebear.com/api/jdenticon/${plog.alog.id}.svg`}
				alt={plog.id}
			/>
			<Box className="pins">
				<BigPinView pin={plog.pin} />
			</Box>
			<Typography className={'rt'}>{plog.alog.runningTime}s</Typography>
			<Box className="times">
				<Typography>S {toHms(plog.scheduleTime)}</Typography>
				<Typography>C {toHms(plog.alog.createdAt)}</Typography>
				<Typography>U {toHms(plog.alog.updatedAt)}</Typography>
			</Box>
		</LogBox>
	)
}

const BigPinView = ({ pin }: { pin?: number }) => {
	if (typeof pin !== 'number') return <span>-</span>
	return (
		<Box style={{ display: 'grid', gridTemplateColumns: '60px 1fr' }}>
			<span>[{hexPinCode(pin)}]</span>
			<div>
				{toBitPins(pin).map((b, i) => (
					<span key={i} className="pin" data-on={b}>
						{b ? '1' : '0'}
					</span>
				))}
			</div>
		</Box>
	)
}

const LogBox = styled.div`
	border: solid 0.5px black;
	width: 100%;
	padding: 4px;
	margin: 10px 0;
	display: grid;
	overflow: scroll;
	grid-template-columns: 100px 30px 1fr 50px 200px;
	.times p {
		font-size: 0.5rem;
	}

	.pin {
		font-size: 1.2rem;
		padding: 4px;
		&[data-on='true'] {
			background: orange;
		}
	}
`
