import {
	Button,
	FormControlLabel,
	Switch,
	TextField,
	Typography,
} from '@mui/material'
import { FormikErrors, useFormik } from 'formik'
import React from 'react'
import { HouseSettingField } from '../../../types'

type Fields = HouseSettingField
type Props = {
	initialValues: Fields
	disabled: boolean
	handleSubmit: (fields: Fields) => void
}

const EditSettingForm = (props: Props) => {
	const { disabled, initialValues } = props

	const {
		values,
		errors,
		touched,
		handleChange,
		handleSubmit,
		setFieldTouched,
	} = useFormik<Fields>({
		initialValues,
		validate: (_values) => {
			const errors: FormikErrors<Fields> = {}

			return errors
		},
		onSubmit: (fields: Fields) => {
			props.handleSubmit(fields)
		},
	})

	const change = (name: keyof Fields, e: React.ChangeEvent<unknown>) => {
		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
	}

	return (
		<div>
			<form>
				<Typography variant="h6">緊急通知設定</Typography>
				<FormControlLabel
					control={
						<Switch
							checked={values.rainDetect}
							onChange={change.bind(null, 'rainDetect')}
							name="rainDetect"
							color="primary"
						/>
					}
					label="雨通知"
				/>
				<FormControlLabel
					control={
						<Switch
							checked={values.tmpDetect}
							onChange={change.bind(null, 'tmpDetect')}
							name="tmpDetect"
							color="primary"
						/>
					}
					label="温度通知"
				/>
				<FormControlLabel
					control={
						<Switch
							checked={values.liveDetect}
							onChange={change.bind(null, 'liveDetect')}
							name="liveDetect"
							color="primary"
						/>
					}
					label="接続エラー通知"
				/>
				<div>
					<TextField
						type="number"
						name="tmpAbove"
						value={values.tmpAbove}
						disabled={disabled || !values.tmpDetect}
						fullWidth
						error={touched.tmpAbove && Boolean(errors.tmpAbove)}
						helperText={touched.tmpAbove && errors.tmpAbove}
						label="温度閾値"
						onChange={change.bind(null, 'tmpAbove')}
					/>
					<TextField
						type="text"
						name="email"
						label="配信先メールアドレス"
						fullWidth
						disabled={
							(!values.rainDetect && !values.liveDetect && !values.tmpDetect) ||
							disabled
						}
						value={values.email}
						error={touched.email && Boolean(errors.email)}
						helperText={touched.email && errors.email}
						onChange={change.bind(null, 'email')}
					/>
				</div>
				<div style={{ textAlign: 'right' }}>
					<Button onClick={() => handleSubmit()} color="primary" size={'large'}>
						変更する
					</Button>
				</div>
			</form>
		</div>
	)
}

export default EditSettingForm
