import {
	FormControl,
	FormControlLabel,
	InputAdornment,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { FormikErrors, useFormik } from 'formik'
import React from 'react'
import { RightBox } from '../../../components'
import { CreateSteperField, MachineCategory } from '../../../types'
import { sensorLib, sensorLibByKey, toj } from '../../../utils'
import StepBar from './StepBar'

const sensors = Object.values(sensorLib)

type Fields = CreateSteperField
type Props = {
	disabled: boolean
	initialValues: Fields
	category: MachineCategory
	handleSubmit: (fields: Fields) => void
}

function EditSteperForm(props: Props) {
	const { disabled, initialValues, category } = props
	const {
		values,
		errors,
		touched,
		handleChange,
		handleSubmit,
		setFieldTouched,
	} = useFormik<Fields>({
		initialValues,
		validate: (values) => {
			const errors: FormikErrors<Fields> = {}

			if (values.bottom < 0) {
				errors.bottom = '必須です。'
			}

			return errors
		},
		onSubmit: (fields: Fields) => {
			props.handleSubmit(fields)
		},
	})
	const change = (name: keyof Fields, e: React.ChangeEvent<unknown>) => {
		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
	}
	const select = (name: keyof Fields, e: SelectChangeEvent<unknown>) => {
		handleChange(e)
		setFieldTouched(name, true, false)
	}

	const endAdornmentSensor = (
		<InputAdornment position="end">
			{sensorLibByKey[values.sensor].unit}
		</InputAdornment>
	)

	const changed = toj(values) === toj(initialValues)

	return (
		<div>
			<Typography>簡易設定を編集</Typography>
			<form>
				<FormControlLabel
					control={
						<Switch
							checked={values.enabled}
							onChange={change.bind(null, 'enabled')}
							name="enabled"
							color="primary"
						/>
					}
					label="有効にする"
				/>
				<List style={{ ...(values.enabled ? {} : { background: '#ddd' }) }}>
					<ListItem divider>
						<StepBar steper={{ ...values, id: 0 }} value={5} />
					</ListItem>
					<ListItem divider>
						<ListItemText secondary={'センサー'} />
						<ListItemSecondaryAction>
							<FormControl>
								<Select
									value={values.sensor}
									name="sensor"
									disabled={disabled}
									onChange={select.bind(null, 'sensor')}
									error={touched.sensor && Boolean(errors.sensor)}
									style={{ width: '6rem' }}
								>
									{sensors.map((sensor) => (
										<MenuItem key={sensor.key} value={sensor.key}>
											{sensor.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem>
						<ListItemText secondary={'開始値'} />
						<ListItemSecondaryAction>
							<TextField
								type="number"
								name="bottom"
								value={values.bottom}
								InputProps={{ endAdornment: endAdornmentSensor }}
								onChange={change.bind(null, 'bottom')}
								style={{ width: '6rem' }}
								disabled={disabled}
								error={touched.bottom && Boolean(errors.bottom)}
								helperText={touched.bottom && errors.bottom}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem>
						<ListItemText secondary={'感度'} />
						<ListItemSecondaryAction>
							<TextField
								type="number"
								name="step"
								value={values.step}
								InputProps={{ endAdornment: endAdornmentSensor }}
								inputProps={{ min: 0 }}
								onChange={change.bind(null, 'step')}
								style={{ width: '6rem' }}
								disabled={disabled}
								error={touched.step && Boolean(errors.step)}
								helperText={touched.step && errors.step}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					{category === 'window' && (
						<ListItem>
							<ListItemText secondary={'雨で全閉'} />
							<ListItemSecondaryAction>
								<FormControlLabel
									control={
										<Switch
											checked={values.rainOff}
											onChange={change.bind(null, 'rainOff')}
											name="rainOff"
											color="primary"
										/>
									}
									label={values.rainOff ? '有効' : '無効'}
								/>
							</ListItemSecondaryAction>
						</ListItem>
					)}
				</List>
				<RightBox>
					<Button
						variant="contained"
						color="primary"
						onClick={() => handleSubmit()}
						disabled={props.disabled || changed}
						size={'large'}
					>
						保存する
					</Button>
				</RightBox>
			</form>
		</div>
	)
}

export default EditSteperForm
