import { Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { ActionLog, Control } from '../../types'
import { categoryStr } from '../../utils'
import { toHms } from '../../utils/businessUtil'
import { CategoryIcon } from '../MachineTable/MachineIcon'

export type Props = {
	control: Control
	actionLog: ActionLog
	detail?: boolean
}

export default function LogCard({ actionLog, control, detail = false }: Props) {
	const cateLable = categoryStr[control.category]
	const label = `${
		control.label.includes(cateLable)
			? control.label
			: `${cateLable} ${control.label}`
	}→${String(actionLog.endRate)}%`

	return (
		<LogBox
			className="action-log-card"
			data-tag={actionLog.tag}
			data-cancelled={actionLog.cancelled}
			data-corrected={actionLog.corrected}
			data-progress={actionLog.progress}
		>
			<CategoryIcon
				category={control.category}
				stepRate={6}
				style={{
					width: '32px',
					height: '32px',
					gridArea: 'icon',
				}}
			/>

			<Typography style={{ gridArea: 'time' }} className="time">
				{toHms(actionLog.startAt)}
				{actionLog.endAt && (
					<span className="end-at">(〜{toHms(actionLog.endAt)})</span>
				)}
			</Typography>

			<Typography className="trig-time" style={{ gridArea: 'born' }}>
				{detail && (
					<span className="real-time">
						({toHms(actionLog.realStartAt)}〜{toHms(actionLog.realEndAt)})
					</span>
				)}
				検知 {toHms(actionLog.createdAt)}
			</Typography>

			{/* <Typography style={{ gridArea: 'runt' }}>
				{actionLog.runningTime}秒
			</Typography> */}

			<Typography style={{ gridArea: 'labe' }}>{label}</Typography>

			<div style={{ gridArea: 'bybt' }}>
				{actionLog.tag === 'btn' && (
					<Badge>
						<Typography variant="caption">ボタン操作</Typography>
					</Badge>
				)}
				{actionLog.tag === 'rainclose' && (
					<Badge>
						<Typography variant="caption">雨で閉じる</Typography>
					</Badge>
				)}
				{actionLog.cancelled && (
					<Badge>
						<Typography variant="caption">上書きキャンセル</Typography>
					</Badge>
				)}
			</div>
		</LogBox>
	)
}

const LogBox = styled.div`
	border: solid 0.5px black;
	width: 100%;
	height: 56px;
	padding: 4px;
	margin: 10px 0;
	display: grid;
	column-gap: 4px;
	overflow: scroll;
	font-size: 0.8rem;
	grid-template-areas:
		'icon time time'
		'icon labe born';
	grid-template-columns: auto 1fr auto auto;
	grid-template-rows: auto 1fr;
	.end-at {
		font-size: 0.6rem;
	}
	.real-time {
		font-size: 0.6rem;
	}
	.time {
		/* overflow: scroll; */
	}
`

const Badge = styled.div`
	margin-left: auto;
`
