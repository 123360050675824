import { createTheme } from '@mui/material'
import { orange } from '@mui/material/colors'
import green from '@mui/material/colors/green'
// import lime from '@mui/material/colors/lime'
const defaultTheme = createTheme({})
const { pxToRem } = defaultTheme.typography

const theme = createTheme({
	typography: {
		h1: { fontSize: pxToRem(60) },
		h2: { fontSize: pxToRem(48) },
		h3: { fontSize: pxToRem(42) },
		h4: { fontSize: pxToRem(36) },
		h5: { fontSize: pxToRem(20) },
		h6: { fontSize: pxToRem(18) },
		subtitle1: { fontSize: pxToRem(18) },
		body1: { fontSize: pxToRem(16) },
	},
	palette: {
		primary: { ...green, main: '#00A39A' },
		secondary: { ...orange, main: '#E68920' },
		// secondary: lime,
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				h4: {
					margin: '16px 8px',
					fontSize: '1.8rem',
				},
				h5: {
					marginLeft: '8px',
				},
			},
		},
		MuiLink: {
			defaultProps: {
				color: 'inherit',
			},
		},
		MuiGrid: {
			styleOverrides: {
				container: {
					// marginBottom: '30px',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					marginTop: '4px',
					// padding: '5px',
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					padding: 0,
					margin: 0,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					marginLeft: '10px',
					height: '28px',
				},
				avatar: {
					height: '28px',
					width: '28px',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					minWidth: '60px',
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					width: '0.8em',
					height: '0.8em',
				},
			},
		},
		MuiSelect: {
			defaultProps: {
				size: 'small',
			},
		},
		MuiTextField: {
			defaultProps: {
				size: 'small',
			},
		},
	},
})

export default theme
