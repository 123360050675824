import { TabId, ThunkAction } from '../../types'
import { changeTab } from '../SettingContainer/operations'
import { changeDrawer } from './actions'

export function changeTabAndCloseDrawer(selectedTabId: TabId): ThunkAction {
	return async (dispatch) => {
		dispatch(changeTab(selectedTabId))
		dispatch(closeDrawer())
	}
}

export const openDrawer = () => changeDrawerOpen(true)
export const closeDrawer = () => changeDrawerOpen(false)
export function changeDrawerOpen(isOpen: boolean): ThunkAction {
	return async (dispatch) => {
		dispatch(changeDrawer({ isOpen }))
	}
}
