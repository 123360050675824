import { CardContent } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import styled from 'styled-components'
import { t } from '../../locale'
import { EventCustom, EventFull } from '../../types'

type Props = {
	disabled: boolean
	handleDelete: (event: EventCustom) => void
	handleToggleEnabled: (event: EventCustom) => void
	events: EventFull
}

const EventItem = (props: Props) => {
	const { events } = props
	const { trigger, subControl } = events

	return (
		<EventCard data-disable={!events.enabled}>
			<EventCardContent>
				<div>
					{trigger && (
						<div style={{ display: 'flex' }}>
							<Typography variant="h6" style={{ textAlign: 'center' }}>
								{trigger.label}
							</Typography>
							<Typography variant="h6" style={{ textAlign: 'center' }}>
								{trigger.ruleText}
							</Typography>
						</div>
					)}
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						{trigger ? (
							<Typography color="secondary" variant="body2">
								トリガー
							</Typography>
						) : (
							<Typography color="textSecondary" variant="body2">
								トリガーなし
							</Typography>
						)}
						<Typography color="primary" variant="body2">
							{t.control.base}
						</Typography>
					</div>
					<Typography variant="h6" style={{ textAlign: 'right' }}>
						→{subControl.label}
					</Typography>
				</div>
				{events.activeInterval && events.negativeInterval && (
					<div>
						<Typography color="primary" style={{ textAlign: 'right' }}>
							[{events.activeInterval}分ON⇔{events.negativeInterval}
							分OFF]
						</Typography>
					</div>
				)}

				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					{/* <Settings /> */}
					<Switch
						color="primary"
						checked={events.enabled}
						// tabIndex={-1}
						disableRipple
						disabled={props.disabled}
						onClick={() => {
							props.handleToggleEnabled(props.events)
						}}
						inputProps={{
							// @ts-ignore
							'data-checked': events.enabled,
						}}
					/>
					<Button
						color="secondary"
						disabled={props.disabled}
						onClick={() => {
							props.handleDelete(events)
						}}
					>
						削除する
					</Button>
				</div>
			</EventCardContent>
		</EventCard>
	)
}

const EventCard = styled(Card)`
	width: 100%;
	max-width: 400px;
	height: 180px;
	margin: 4px;
	&[data-disable='true'] {
		background: #c9c9c9;
		opacity: 0.6;
	}
`
const EventCardContent = styled(CardContent)`
	padding: 12px 8px !important;
`

export default EventItem
