import {
	Box,
	Button,
	FormControlLabel,
	Switch,
	Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import { useGraphConf } from '../../hooks/useLocalConfig'
import { getHouse } from '../../store/HouseById/selectors'
import EditSettingFrom from './EditSettingFrom'

type Props = {
	houseId: number
}
function SettingTab(props: Props) {
	const house = useSelector((s) => getHouse(s, props.houseId))
	const [modalOpen, setModalOpen] = useState<boolean>(false)
	const { enabledGraphV2, setEnabledGraphV2 } = useGraphConf()

	return (
		<div>
			<Typography variant="h6" style={{ flex: 1 }}>
				ハウス名: {house.name}
			</Typography>
			<label>
				<Box>
					<FormControlLabel
						control={
							<Switch
								onClick={() => setEnabledGraphV2(!enabledGraphV2)}
							></Switch>
						}
						label="グラフv2"
						labelPlacement="end"
						checked={enabledGraphV2 || false}
					/>
				</Box>
			</label>
			<Button
				onClick={() => {
					setModalOpen(true)
				}}
			>
				編集する
			</Button>
			<Modal open={modalOpen} closeModal={() => setModalOpen(false)}>
				<EditSettingFrom houseId={house.id} />
			</Modal>
		</div>
	)
}

export default SettingTab
