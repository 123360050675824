import {
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RightBox } from '../../components'
import Modal from '../../components/Modal'
import { getEventEx } from '../../store/EventById/selectors'
import { updateOrCreateEventEx } from '../../store/HouseContainer/operations'
import { getActiveSubControl } from '../../store/SubControlById/selectors'
import {
	Control,
	House,
	initEventMist,
	Scenario,
	TimePattern,
} from '../../types'
import { SENSOR, sensorLib } from '../../utils'
import EditMistEventForm from './components/EditMistEventForm'

type Props = {
	house: House
	scenario: Scenario
	control: Control
	timePattern: TimePattern
}

function MistManageTab({ scenario, control, timePattern }: Props) {
	const [open, setOpen] = React.useState(false)
	const dispatch = useDispatch()
	const activeSubc = useSelector((s) => getActiveSubControl(s, control))
	const eventEx = useSelector((s) =>
		getEventEx(s, scenario.eventIds, timePattern, activeSubc, initEventMist),
	)
	const enableStyle = eventEx.enabled ? {} : { background: '#ddd' }

	return (
		<Container>
			<div style={{ marginTop: '8px' }}>
				<RightBox>
					<Button variant="outlined" onClick={() => setOpen(true)}>
						<SettingsIcon />
						編集{!eventEx.enabled && '・有効にする'}
					</Button>
				</RightBox>
				{!eventEx.enabled && <Typography>無効です</Typography>}
				<div style={{ ...enableStyle }}>
					<TableContainer component={Paper}>
						<Table style={{ ...enableStyle }}>
							<TableBody>
								<TableRow>
									<TableCell>温度</TableCell>
									<TableCell>
										{eventEx.tmpAbove}
										{sensorLib[SENSOR.TMP].unit}
									</TableCell>
									<TableCell>以上</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>湿度</TableCell>
									<TableCell>
										{eventEx.hmdAbove}
										{sensorLib[SENSOR.HMD].unit}
									</TableCell>
									<TableCell>以上</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</div>
			<Modal open={open} closeModal={() => setOpen(false)}>
				<EditMistEventForm
					initialValues={_.omit(
						{
							...eventEx,
							scenarioId: scenario.id,
							timePattern,
							subControlId: activeSubc.id,
						},
						['id'],
					)}
					disabled={false}
					handleSubmit={(field) => {
						dispatch(updateOrCreateEventEx(field, eventEx.id))
						setOpen(false)
					}}
				/>
			</Modal>
		</Container>
	)
}

export default MistManageTab
