import GetApp from '@mui/icons-material/GetApp'
import { Button, Tab, Tabs, Typography } from '@mui/material'
import { range } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { FlexBoxRight } from '..'
import { DayChartLine, DayLogById } from '../../types'
import { downloadCSV } from '../../utils'
import DayChart from '../DayChart'
import LogDateSelector from '../LogDateSelector'

export type Props = {
	dayLogs: DayLogById
	day: string
	isToday: boolean
	houseId: number
	handleSubmit: (day: string) => void
	handleSubmitToday: () => void
}

/*

・温度：赤
・湿度：青
・飽差：紫
・露点：水色
・CO2：黒
・外部温度1：赤白のゼブラ(無理な場合は赤系)
・外部温度2：赤黒のゼブラ(無理な場合は赤系)
・日射：オレンジ
*/

export const lines: { [key: string]: DayChartLine } = {
	tmp: {
		dataKey: 'tmp',
		stroke: 'red',
	},
	hmd: {
		dataKey: 'hmd',
		stroke: 'blue',
	},
	vpd: {
		dataKey: 'vpd',
		stroke: 'purple',
	},
	co2: {
		dataKey: 'co2',
		stroke: 'black',
	},
	lux: {
		dataKey: 'lux',
		stroke: 'orange',
	},
	dew: {
		dataKey: 'dew',
		stroke: '#35f2ff',
	},
}

const Style = styled.div`
	.MuiTabs-flexContainer > button {
		min-width: 40px;
	}
`
const StyledTab = styled(Tab)<{ color: string }>`
	span.MuiTab-wrapper {
		border-bottom: solid ${(p) => p.color};
	}
`

function LogGraph(props: Props) {
	const [value, setValue] = React.useState<
		'all' | 'tmp' | 'hmd' | 'vpd' | 'dew' | 'co2' | 'lux'
	>('all')
	const { day } = props

	return (
		<Style>
			<Typography variant="h6">{props.day}</Typography>

			<FlexBoxRight>
				<LogDateSelector
					handleSubmit={props.handleSubmit}
					handleSubmitToday={props.handleSubmitToday}
					isToday={props.isToday}
					day={props.day.replace(/\//g, '-')}
				/>
				<Button
					variant="contained"
					color="primary"
					startIcon={<GetApp />}
					onClick={() =>
						downloadCSV(String(props.houseId), props.day, props.dayLogs.logs)
					}
				>
					CSV
				</Button>
			</FlexBoxRight>

			<Tabs
				value={value}
				onChange={(e, v) => setValue(v)}
				aria-label="wrapped label tabs example"
			>
				<Tab value="all" label="複合" wrapped />
				<StyledTab
					color={lines['tmp'].stroke}
					value="tmp"
					label="温度"
					wrapped
				/>
				<StyledTab
					color={lines['hmd'].stroke}
					value="hmd"
					label="湿度"
					wrapped
				/>
				<StyledTab
					color={lines['vpd'].stroke}
					value="vpd"
					label="飽差"
					wrapped
				/>
				<StyledTab
					color={lines['dew'].stroke}
					value="dew"
					label="露点"
					wrapped
				/>
				<StyledTab
					color={lines['lux'].stroke}
					value="lux"
					label="日射"
					wrapped
				/>
				<StyledTab
					color={lines['co2'].stroke}
					value="co2"
					label="CO2"
					wrapped
				/>
			</Tabs>

			<div style={{ margin: '0' }}>
				{value === 'all' && (
					<div>
						<DayChart
							day={day}
							logs={props.dayLogs.logs}
							yTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
							lines={[
								lines.tmp,
								lines.hmd,
								lines.vpd,
								lines.dew,
								lines.lux,
								lines.co2,
							]}
						/>
					</div>
				)}

				{value === 'tmp' && (
					<div>
						<DayChart
							day={day}
							logs={props.dayLogs.logs}
							yTicks={[0, 10, 20, 30, 40]}
							lines={[lines.tmp]}
						/>
					</div>
				)}

				{value === 'hmd' && (
					<div>
						<DayChart
							day={day}
							logs={props.dayLogs.logs}
							yTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
							lines={[lines.hmd]}
						/>
					</div>
				)}

				{value === 'vpd' && (
					<div>
						<DayChart
							day={day}
							logs={props.dayLogs.logs}
							yTicks={[0, 10, 20, 30, 40]}
							lines={[lines.vpd]}
						/>
					</div>
				)}

				{value === 'dew' && (
					<div>
						<DayChart
							day={day}
							logs={props.dayLogs.logs}
							yTicks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
							lines={[lines.dew]}
						/>
					</div>
				)}

				{value === 'lux' && (
					<div>
						<DayChart
							day={day}
							logs={props.dayLogs.logs}
							yTicks={range(0, 1500, 150)}
							lines={[lines.lux]}
						/>
					</div>
				)}

				{value === 'co2' && (
					<div>
						<DayChart
							day={day}
							logs={props.dayLogs.logs}
							yTicks={range(0, 1500, 150)}
							lines={[lines.co2]}
						/>
					</div>
				)}
			</div>
		</Style>
	)
}

export default LogGraph
