import _ from 'lodash'
import { HouseRaw, ThunkAction, Trigger } from '../../types'
import { triggerToLabel, triggerToRuleText } from '../../utils/index'
import { registerTriggers } from './actions'

export const saveTriggers = (house: HouseRaw): ThunkAction => {
	return async (dispatch) => {
		const triggers = batchTriggers(house)

		await dispatch(registerTriggers(triggers))
	}
}

export function batchTriggers(house: HouseRaw): Trigger[] {
	return _.sortBy(house.triggers, ['sensor', 'rule']).map((v) => ({
		...v,
		label: triggerToLabel(v),
		ruleText: triggerToRuleText(v),
	}))
}
