import _ from 'lodash'
import { Control, ControlRaw, HouseRaw, ThunkAction } from '../../types'
import { saveSubControls } from '../SubControlById/operations'
import { registerControls } from './actions'

const normPin = (v: number | null) => {
	if (v === null) return null
	return v >= 0 ? `${v + 1}` : null
}

function isHit(pin: number, { activePin, negativePin }: ControlRaw): boolean {
	const apinSt =
		activePin === null || activePin === -1 || ((pin >> activePin) & 1) === 1
	const npinSt =
		negativePin === null ||
		negativePin === -1 ||
		((pin >> negativePin) & 1) === 0

	return apinSt && npinSt
}

export const saveControls = (house: HouseRaw): ThunkAction => {
	return async (dispatch) => {
		await Promise.all(
			house.controls.map(async (control) => {
				await dispatch(saveSubControls(control))
			}),
		)
		const controls = batchControls(house)

		await dispatch(registerControls(controls))
	}
}

function batchControls(house: HouseRaw): Control[] {
	return _.sortBy(house.controls, ['label']).map((control) => {
		return {
			..._.omit(control, ['subControls']),
			category: control.category || 'none',
			subControlIds: control.subControls.map((v) => v.id),
			activePinView: normPin(control.activePin),
			negativePinView: normPin(control.negativePin),
			isHit: isHit(house.pin, control),
			isNew: false,
		}
	})
}
