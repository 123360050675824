import _ from 'lodash'
import moment from 'moment'
// @ts-ignore
import Feels from 'feels'
import config from '../../config/index'
import { Log, ThunkAction } from '../../types'
import { receiveLogs } from './actions'
import { DateTime } from 'luxon'

const calcVpd = (input: Log | undefined) => {
	const { tmp, hmd } = input || { tmp: 0, hmd: 0 }

	if (typeof tmp !== 'number' || typeof hmd !== 'number') {
		throw new TypeError(
			`Expected a { tmp: number, hmd: number }, got ${typeof tmp} ${typeof hmd}`,
		)
	}
	// vaporPressur
	const vp = 6.1078 * Math.pow(10, (7.5 * tmp) / (tmp + 237.3))
	const swv = (217 * vp) / (tmp + 273.15)
	// amount of saturated water vapor
	const vpd = ((100 - hmd) * swv) / 100

	return { vp, swv, vpd }
}

export function calcDewPoint(temp: number, humidity: number) {
	try {
		return _.round(new Feels({ temp, humidity }).getDP(), 2)
	} catch (_e) {
		return 0
	}
}

export function calcLog(log: Log): Log {
	const { vpd } = calcVpd(log)
	const date = DateTime.fromISO(new Date(log.createdAt).toISOString())
	const dew = calcDewPoint(log.tmp, log.hmd)

	return {
		...log,
		vpd,
		dew,
		timestamp: date.toMillis(),
		hm: date.toFormat('HH:mm'),
	}
}

const startOf5min = (ts: number): number => {
	return ts - (ts % (config.dayGraphDecimatingMin * 60000))
}

// 5分毎の平均にする
const cutLogs = (logs: Log[]): Log[] => {
	const min5Groups = _.groupBy(
		_.filter(logs, (v) => !v.bad),
		(log) => {
			const m = moment(log.timestamp)
			const ms = `${Math.floor(
				m.minute() / config.dayGraphDecimatingMin,
			)}`.padStart(2, '0')

			return `${m.format('HH')}${ms}`
		},
	)

	return _.sortBy(
		_.map(min5Groups, (logs) => {
			return {
				...logs[0],
				...['tmp', 'hmd', 'vpd', 'co2', 'lux'].reduce(
					(p, k) => ({
						...p,
						[k]: Number(_.meanBy(logs, k).toFixed(2)),
					}),
					{},
				),
				timestamp: startOf5min(logs[0].timestamp),
			}
		}),
		'timestamp',
	)
}

const logsAvg = (logs: Log[]) => _.sumBy(logs, 'tmp') / logs.length

export function saveLogs({
	houseId,
	day,
	data,
}: {
	houseId: number
	day: string
	data: Log[]
}): ThunkAction {
	return (dispatch) => {
		const logs = _.map(
			data.filter((v) => v.createdAt),
			calcLog,
		)
		const movingAvgTmp24 = logsAvg(logs)
		const movingAvgTmp48 = movingAvgTmp24 + 0.3
		const movingAvgTmp72 = movingAvgTmp24 - 0.2

		const tempMax = _.get(_.maxBy(logs, 'tmp'), ['tmp']) || 0
		const tempMin = _.get(_.minBy(logs, 'tmp'), ['tmp']) || 0

		// HHmm → HHm|m で 10 分毎に分ける
		const logsBy10min = _.groupBy(logs, (log) =>
			DateTime.fromMillis(log.timestamp).toFormat('HHmm').substring(0, 3),
		)
		console.log(logsBy10min)
		const integrationTmp = _.sum(_.map(logsBy10min, logsAvg))

		dispatch(
			receiveLogs({
				day: {
					logs: cutLogs(logs),
					tempMax,
					tempMin,
					movingAvgTmp24,
					movingAvgTmp48,
					movingAvgTmp72,
					integrationTmp,
				},
				label: `${houseId}:${day}`,
			}),
		)
	}
}
