import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { isDemoMode } from '../../api/acitonLogsAPI'
import { DayLogById, House, Log } from '../../types'

type Props = {
	isActive: boolean
	house: House
	log: Log | null
	dayLogs: DayLogById
}

const Device = (props: Props) => {
	const { log, dayLogs, house } = props

	const time = log
		? moment(log.createdAt).format('YYYY/MM/DD (dd) HH:mm')
		: '---'
	const round = (num: number) => _.round(num, 1)

	return (
		<Grid container>
			<Grid item xs={12} md={4}>
				{props.isActive || isDemoMode() ? (
					<Typography variant="h5" color="primary">
						稼働中
					</Typography>
				) : (
					<div>
						<Typography variant="h5">停止中</Typography>
						<Typography variant="subtitle1" color="error">
							5分以上ログが来ていません
						</Typography>
					</div>
				)}
				<Typography variant="h6">{time}</Typography>
				<Box sx={{ display: { md: 'none', lg: 'none' } }}>
					<hr />
				</Box>

				{log && (
					<Grid item md={5}>
						{Boolean(log.wat) ? (
							<Typography variant="h5" style={{ color: '#3344ff' }}>
								{'雨あり'}
							</Typography>
						) : (
							<Typography variant="h5" style={{ color: '#aaa' }}>
								{'雨なし'}
							</Typography>
						)}
					</Grid>
				)}
			</Grid>
			{log ? (
				<Grid item xs={12} md={8}>
					<Grid container>
						<Grid item xs={4}>
							<Typography variant="subtitle1">温度</Typography>
							<Typography variant="h5">{round(log.tmp)}℃</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle1">湿度</Typography>
							<Typography variant="h5">{round(log.hmd)}％</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle1">露点</Typography>
							<Typography variant="h5">{round(log.dew)}℃</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle1">飽差</Typography>
							<Typography variant="h5">{round(log.vpd)}</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle1">CO2</Typography>
							<Typography variant="h5">{round(log.co2)}ppm</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle1">日射量</Typography>
							<Typography variant="h5">{round(log.lux)}W</Typography>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="subtitle1">移動平均気温</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle1">24H</Typography>
							<Typography variant="h5">
								{round(dayLogs.movingAvgTmp24) || '-'}℃
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle2">48H</Typography>
							<Typography variant="h5">
								{round(dayLogs.movingAvgTmp48) || '-'}℃
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle2">72H</Typography>
							<Typography variant="h5">
								{round(dayLogs.movingAvgTmp72) || '-'}℃
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle2">積算気温</Typography>
							<Typography variant="h5">
								{round(dayLogs.integrationTmp) || '-'}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle2">外部温度1</Typography>
							<Typography variant="h5">{round(house.tmp2) || '-'}</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle2">外部温度2</Typography>
							<Typography variant="h5">{round(house.tmp3) || '-'}</Typography>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<div>まだログがありません</div>
			)}
		</Grid>
	)
}

export default Device
