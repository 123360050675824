import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import './config/init'
import App from './containers/App/index'
import { GlobalStyle } from './global'
import configureStore from './store'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import theme from './theme'
import reportWebVitals from './reportWebVitals'

const { store, persistor } = configureStore()

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={<h3>Loading</h3>} persistor={persistor}>
				<CssBaseline />
				<GlobalStyle />

				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
)

serviceWorkerRegistration.register()

reportWebVitals()
