import _ from 'lodash'
import {
	ScenarioRaw,
	Steper,
	SteperRaw,
	ThunkAction,
	timePatterns,
} from '../../types'
import { registerStepers } from './actions'

export const saveStepers = (scenario: ScenarioRaw): ThunkAction => {
	return async (dispatch) => {
		const stepers = batchStepers(scenario.stepers)

		await dispatch(registerStepers(stepers))
	}
}

function batchStepers(stepers: SteperRaw[]): Steper[] {
	return _.sortBy(stepers, [(s) => timePatterns.indexOf(s.timePattern)])
}
