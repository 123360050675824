import _ from 'lodash'
import { DateTime } from 'luxon'
import { ActionLog, PartLog } from '../types'

export function alog2plog(alogs: ActionLog[]) {
	const plogs: PartLog[] = []

	alogs.forEach((alog) => {
		plogs.push({
			id: `timeline-plog-${alog.id}-s`,
			alog,
			timing: 'start',
			pin: alog.startPin,
			rate: alog.startRate,
			scheduleTime: alog.startAt ? toUnix(alog.startAt) : undefined,
			time: alog.realStartAt ? toUnix(alog.realStartAt) : undefined,
			realLess: !!alog.realStartAt,
		})
		if (alog.endAt) {
			plogs.push({
				id: `timeline-plog-${alog.id}-e`,
				alog,
				timing: 'end',
				pin: alog.endPin,
				rate: alog.endRate,
				scheduleTime: toUnix(alog.endAt),
				time: alog.realEndAt ? toUnix(alog.realEndAt) : undefined,
				realLess: !!alog.realEndAt,
			})
		}
	})
	plogs.sort(
		(a, b) => (a.time || a.scheduleTime || 0) - (b.time || b.scheduleTime || 0),
	)
	return plogs
}
const toUnix = (time: string) => +new Date(time)

export const hexPinCode = (v: number) =>
	'0x' + _.padStart(v.toString(16), 4, '0')
export const isActive = (pin: number, i: number) => {
	return Boolean((pin >> i) & 1)
}

export const toBitPins = (pin: number) =>
	_.range(0, 16).map((i) => isActive(pin, i))

export const toHms = (ms: number | string | undefined): string => {
	if (typeof ms !== 'number' && typeof ms !== 'string') {
		return ''
	}
	return DateTime.fromMillis(
		typeof ms === 'number' ? ms : +new Date(ms),
	).toFormat('HH:mm:ss.SSS')
}
