import _ from 'lodash'
import moment from 'moment'
import { Event, ScenarioRaw, ThunkAction } from '../../types'
import { registerEvents } from './actions'

export const saveEvents = (scenario: ScenarioRaw): ThunkAction => {
	return async (dispatch) => {
		const events = batchEvents(scenario.events)

		await dispatch(registerEvents(events))
	}
}

const batchEvent = (e: Event): Event => {
	if (e.startTime === null || e.endTime === null) {
		return e
	}
	const startTime = moment(e.startTime).format('HH:mm')
	const endTime = moment(e.endTime).format('HH:mm')

	return {
		...e,
		startTime,
		endTime,
		isFullTime: startTime === '00:00' && endTime === '23:59',
	}
}

function batchEvents(events: Event[]): Event[] {
	return _.sortBy(events.map(batchEvent), [
		(e) => {
			return `${e.startTime}-${e.endTime}`
		},
	])
}
