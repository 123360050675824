import { Card, Grid, Typography } from '@mui/material'
import _ from 'lodash'
import * as React from 'react'
import { useJump } from '../../store/App/operations'
import { Control, House, Scenario } from '../../types'
import { categoryStr, isBoolMachineCategory, toStepRate } from '../../utils'
import { CategoryIcon } from './MachineIcon'

function ControlTableCell(props: {
	house: House
	control: Control
	scenario?: Scenario
}) {
	const { house, control, scenario } = props
	const stepRate = toStepRate(control.rate || 0)
	const { jumpMachinePage } = useJump()

	return (
		<Card
			style={{ cursor: scenario ? 'pointer' : 'auto' }}
			onClick={() => {
				if (!scenario) return
				jumpMachinePage(house.id, scenario.id, control.id)
			}}
		>
			<div style={{ textAlign: 'center', margin: '1rem' }}>
				<Typography
					variant="subtitle1"
					style={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
				>
					{control.label}
				</Typography>
				<CategoryIcon
					category={control.category}
					stepRate={stepRate}
					style={{ width: '72px', height: '72px' }}
				/>

				{isBoolMachineCategory(control.category) ? (
					<>
						<Typography variant="subtitle1">
							{control.rate ? 'オン' : 'オフ'}
						</Typography>
					</>
				) : (
					<>
						<Typography variant="subtitle1">{control.rate}%開</Typography>
					</>
				)}
			</div>
		</Card>
	)
}

const categoryList = Object.entries(categoryStr).map(([key, title]) => ({
	key,
	title,
}))

type Props = {
	house: House
	scenario?: Scenario
	controls: Control[]
}
function ControlTable({ house, scenario, controls }: Props) {
	const controlsByCategory = _.groupBy(controls, (c) => c.category || 'none')

	return (
		<div>
			{categoryList
				.filter((c) => controlsByCategory[c.key])
				.map((category) => (
					<div key={category.key}>
						<Typography variant="h6" style={{ marginLeft: '1rem' }}>
							{category.title}
						</Typography>
						<Grid container>
							{controlsByCategory[category.key]?.map(
								(control: Control, key: number) => {
									return (
										<Grid item xs={3} key={key}>
											<ControlTableCell
												house={house}
												scenario={scenario}
												control={control}
											/>
										</Grid>
									)
								},
							)}
						</Grid>
					</div>
				))}
		</div>
	)
}

export default ControlTable
