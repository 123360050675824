import { OtherHouses } from '@mui/icons-material'
import { Avatar, Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import * as Yup from 'yup'
import { doLogin } from '../../../store/Auth/operations'
import Init from '../../App/Init'
import { useAuth } from '../../Auth/components/useAuth'

type Props = {
	disabled: boolean
	errorMessage: string | null
}

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email('メールの形式になっていません')
		.required('必須です'),
	password: Yup.string().required('必須です'),
})

type Field = { email: string; password: string }
const initialValues = { email: '', password: '' }

function LoginForm({ errorMessage, disabled }: Props) {
	const dispatch = useDispatch()
	const auth = useAuth()

	const { values, errors, handleChange, handleSubmit, setSubmitting } =
		useFormik<Field>({
			initialValues,
			validationSchema: loginSchema,
			onSubmit: (values) => {
				dispatch(doLogin(values))
				setSubmitting(false)
			},
		})

	if (auth.isLogin) return <Navigate to="/" />

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Init />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Avatar
					sx={{ p: 1, m: 1, bgcolor: 'primary.main' }}
					component={OtherHouses}
				/>
				<Typography component="h1" variant="h5">
					ログイン
				</Typography>
				<form onSubmit={handleSubmit}>
					<Box sx={{ mt: 1 }}>
						<TextField
							disabled={disabled}
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email"
							name="email"
							autoComplete="email"
							onChange={handleChange}
							error={!!errors.email}
							helperText={errors.email || errorMessage || ''}
							value={values.email}
							autoFocus
						/>
						<TextField
							disabled={disabled}
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							onChange={handleChange}
							error={!!errors.password}
							helperText={errors.password || ''}
							value={values.password}
							autoComplete="current-password"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							ログイン
						</Button>
					</Box>
				</form>
			</Box>
		</Container>
	)
}

export default LoginForm
