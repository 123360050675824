import { useCallback, useEffect, useState } from 'react'
import { getActionLogs } from '../../api/client'
import { ActionLog } from '../../types'

export function useActionLogs(houseId: number, day: string) {
	const [logs, setActionLogs] = useState<ActionLog[]>([])
	const loadActionLogs = useCallback(() => {
		console.log('loadActionLogs: ', houseId, day)

		getActionLogs(houseId, day).then((logRaws) => {
			const logs = logRaws.map((logRaw) => {
				return {
					...logRaw,
				}
			})

			setActionLogs(logs)
		})
	}, [houseId, day])

	useEffect(() => {
		loadActionLogs()
	}, [loadActionLogs])

	return { logs, reload: loadActionLogs }
}
