import moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import SunCalc from 'suncalc'

type Props = {
	day: string
}

const template = `
  "...... beg end ....."
  "before bar bar after"
`

const SunsetBar = (props: Props) => {
	const res = SunCalc.getTimes(new Date(props.day), 35.23, 136.43)
	const sunrise = moment(res.sunrise)
	const sunset = moment(res.sunset)
	const dayStart = moment(props.day).unix()
	const startRate = ((sunrise.unix() - dayStart) / 86400) * 100
	const endRate = 100 - ((sunset.unix() - dayStart) / 86400) * 100

	return (
		<Grid startRate={startRate} endRate={endRate}>
			<div className="left" />
			<div className="center" />
			<div className="right" />
			<div style={{ gridArea: 'beg' }}>日の出{sunrise.format('H:mm')}</div>
			<div style={{ gridArea: 'end', textAlign: 'right' }}>
				{sunset.format('H:mm')}日の入
			</div>
		</Grid>
	)
}

const Grid = styled.div<{ startRate: number; endRate: number }>`
	width: 100%;
	height: 100%;
	display: grid;
	position: absolute;
	grid-template-areas: ${template};
	gap: 2px;
	grid-template-columns: ${(p) => p.startRate}% 1fr 1fr ${(p) => p.endRate}%;
	grid-template-rows: max-content auto;
	.left {
		grid-area: before;
		border-top: #b9b9ff solid 2px;
		border-right: #b9b9ff solid 2px;
	}
	.center {
		grid-area: bar;
		border: #ffe189 solid 2px;
		border-bottom: none;
	}
	.right {
		grid-area: after;
		border-top: #b9b9ff solid 2px;
		border-left: #b9b9ff solid 2px;
	}
	font-size: 0.6rem;
`

export default SunsetBar
