import _ from 'lodash'

import { House, State } from '../../types'

export function getHouses(state: State): House[] {
	return _.values(state.HouseById)
}

export function getHouse(state: State, houseId: number): House {
	return state.HouseById[houseId]
}
