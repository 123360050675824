import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Switch,
	Typography,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import ActionLogCardContainer from '.'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { ActionLog } from '../../types'
import { alog2plog } from '../../utils/businessUtil'
import PartLogCard from './PartLogCard'
import PinLogCard from './PinLogCard'

export type Props = {
	actionLogs: ActionLog[]
	onClickSync: () => void
}

type Mode = 'normal' | 'debug' | 'pin'
export default function Timeline({ actionLogs, onClickSync }: Props) {
	const [mode, setMode] = useLocalStorage<Mode>(
		'pf_action-history-tabmode',
		'normal',
	)
	const [viewCancel, setViewCancel] = useState<boolean>(false)
	const [sortBy, setSortBy] = useState<'trigger' | 'start'>('start')

	const alogs = useMemo(() => {
		;[...actionLogs].sort((a, b) => {
			if (typeof a.startAt !== 'string') return 1
			if (typeof b.startAt !== 'string') return -1
			return sortBy === 'start'
				? a.startAt.localeCompare(b.startAt)
				: a.createdAt.localeCompare(b.createdAt)
		})
		return actionLogs
	}, [actionLogs, sortBy])

	const plogs = useMemo(() => alog2plog(actionLogs), [actionLogs])

	return (
		<Style data-view-cancel={viewCancel}>
			<Box style={{ display: 'flex' }}>
				<Box mt={2.8}>
					<label>
						<FormControlLabel
							control={
								<Switch
									onClick={() =>
										setSortBy(sortBy === 'start' ? 'trigger' : 'start')
									}
								></Switch>
							}
							label="検知順"
							labelPlacement="end"
							checked={sortBy === 'start'}
						/>
					</label>
					<label>
						<FormControlLabel
							control={
								<Switch onClick={() => setViewCancel(!viewCancel)}></Switch>
							}
							label="キャンセルも表示"
							labelPlacement="end"
							checked={viewCancel}
						/>
					</label>
				</Box>
				<FormControl>
					<FormLabel id="pf-alog-mode">モード</FormLabel>
					<RadioGroup
						row
						value={mode}
						aria-labelledby="pf-alog-mode"
						name="alog-mode-group"
						onChange={(e) => {
							setMode(e.target.value as Mode)
						}}
					>
						<FormControlLabel value="normal" control={<Radio />} label="通常" />
						<FormControlLabel value="pin" control={<Radio />} label="ピン" />
						<FormControlLabel
							value="debug"
							control={<Radio />}
							label="デバッグ"
						/>
					</RadioGroup>
				</FormControl>
			</Box>
			{!alogs.length && <Typography>制御ログはありません</Typography>}
			{mode === 'debug' &&
				plogs.map((plog) => <PartLogCard key={plog.id} plog={plog} />)}
			{mode === 'pin' && (
				<>
					{plogs.map((plog) => (
						<PinLogCard key={plog.id} plog={plog} />
					))}
					<Typography>β機能</Typography>
				</>
			)}
			{mode === 'normal' &&
				alogs.map((log) => (
					<ActionLogCardContainer
						key={`timeline-actionLog${log.id}`}
						actionLog={log}
					/>
				))}
			<div>
				<Button onClick={onClickSync}>更新</Button>
				<Typography variant="caption">
					色の説明
					※リアルタイム同期は実装中のためロード時の状態が表示されています
				</Typography>
				<div className="help">
					<div className="action-log-card" data-tag={'btn'}>
						ボタン
					</div>
					<div className="action-log-card" data-tag={'rainclose'}>
						雨で閉じる
					</div>
					<div className="action-log-card" data-tag={''}>
						自動実行
					</div>
					<div className="action-log-card" data-cancelled={true}>
						キャンセル
					</div>
					<div className="action-log-card" data-corrected={true}>
						補正終了
					</div>
					<div className="action-log-card" data-progress={'1'}>
						実行中
					</div>
					<div className="action-log-card" data-progress={'2'}>
						待機中
					</div>
					<div className="action-log-card" data-progress={'3'}>
						クールタイム待機中
					</div>
				</div>
			</div>
		</Style>
	)
}

const Style = styled(Box)`
	&[data-view-cancel='false'] {
		[data-cancelled='true'] {
			display: none;
		}
	}

	.help {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
	}
	.help .action-log-card {
		border: solid 1px gray;
		width: 140px;
		font-size: 12px;
		padding: 4px;
	}
	.action-log-card {
		.trig-time {
			font-size: 0.8rem;
			color: gray;
		}

		&[data-tag='btn'] {
			border-left: solid 5px #e0d868;
		}
		&[data-tag='rainclose'] {
			border-left: solid 5px #73e3ff;
		}
		&[data-tag=''] {
			border-left: solid 5px #885588;
		}
		&[data-cancelled='true'] {
			opacity: 0.5;
		}
		&[data-corrected='true'] {
			background: #e0e0f3;
		}
		&[data-progress='1'] {
			background: #ffffdd;
		}
		&[data-progress='2'] {
			opacity: 0.7;
			background: #ffddff;
		}
		&[data-progress='3'] {
			opacity: 0.7;
			background: #aadddd;
		}
	}
`
