import { connect } from 'react-redux'
import {
	getLoginErrorMessage,
	getLoginLoading,
} from '../../store/LoginPage/selectors'
import { State } from '../../types'
import LoginPage from './components/LoginPage'

const mapStateToProps = (state: State) => {
	return {
		errorMessage: getLoginErrorMessage(state),
		disabled: getLoginLoading(state),
	}
}

export default connect(mapStateToProps, {})(LoginPage)
