import { Clear } from '@mui/icons-material'
import * as React from 'react'

import {
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
} from '@mui/material'
import { t } from '../../../locale'

type Props = {
	isOpen: boolean

	closeModal: () => void
	handleCreateEvent: () => void
	handleTriggerList: () => void
	handleCreateTrigger: () => void
}

const ManageScenarioModal = (props: Props) => {
	return (
		<div>
			<List>
				<ListItem divider>
					<IconButton onClick={props.closeModal}>
						<Clear />
					</IconButton>
					<ListItemText secondary={`${t.event.base}やトリガーを追加`} />
				</ListItem>
				<ListSubheader>{t.event.base}</ListSubheader>
				<ListItem divider button onClick={props.handleCreateEvent}>
					<ListItemText>新規{t.event.base}を作成</ListItemText>
				</ListItem>
				<ListSubheader>トリガー</ListSubheader>
				<ListItem button onClick={props.handleTriggerList}>
					<ListItemText>トリガー一覧</ListItemText>
				</ListItem>
				<ListItem button onClick={props.handleCreateTrigger}>
					<ListItemText>トリガーを追加</ListItemText>
				</ListItem>
			</List>
		</div>
	)
}

export default ManageScenarioModal
