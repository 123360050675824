import {
	Campaign,
	ContactSupport,
	ExitToApp,
	House,
	Info,
	SettingsInputAntenna,
	SettingsRemote,
	ShowChart,
	TravelExplore,
} from '@mui/icons-material'
import { Divider, ListItemButton, ListItemIcon } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'
import { RELEASE_NOTE_URL } from '../../config'
import { usageLink } from '../../constants'
import { t } from '../../locale'
import { TabId } from '../../types'
import { QA_URL } from '../../config'

type Props = {
	isTop: boolean
	onSelectTab: (tabId: TabId) => void
	logout: () => void
}

const MenuList = (props: Props) => {
	return (
		<List>
			<ListItem
				disabled={props.isTop}
				button
				onClick={() => props.onSelectTab('home')}
			>
				<ListItemIcon>
					<House />
				</ListItemIcon>
				<ListItemText primary={'ホーム'} />
			</ListItem>
			<ListItem
				disabled={props.isTop}
				button
				onClick={() => props.onSelectTab('graph')}
			>
				<ListItemIcon>
					<ShowChart />
				</ListItemIcon>
				<ListItemText primary={'グラフ'} />
			</ListItem>
			<ListItem
				disabled={props.isTop}
				button
				onClick={() => props.onSelectTab('scenario')}
			>
				<ListItemIcon>
					<SettingsInputAntenna />
				</ListItemIcon>
				<ListItemText primary={`${t.scenario.base}`} />
			</ListItem>
			<ListItem
				disabled={props.isTop}
				button
				onClick={() => props.onSelectTab('control')}
			>
				<ListItemIcon>
					<SettingsRemote />
				</ListItemIcon>
				<ListItemText primary={`${t.screens.control}`} />
			</ListItem>

			<Divider />
			<ListItem button onClick={props.logout}>
				<ListItemIcon>
					<ExitToApp />
				</ListItemIcon>
				<ListItemText primary="ログアウト" />
			</ListItem>
			<ListItemButton component="a" href="/about">
				<ListItemIcon>
					<TravelExplore />
				</ListItemIcon>
				<ListItemText primary="サービス仕様" />
			</ListItemButton>
			<ListItemButton component="a" href={usageLink}>
				<ListItemIcon>
					<Info />
				</ListItemIcon>
				<ListItemText primary="利用規約" />
			</ListItemButton>
			<ListItemButton component="a" href={RELEASE_NOTE_URL}>
				<ListItemIcon>
					<Campaign />
				</ListItemIcon>
				<ListItemText primary="リリースノート" />
			</ListItemButton>
			<ListItemButton component="a" href={QA_URL}>
				<ListItemIcon>
					<ContactSupport />
				</ListItemIcon>
				<ListItemText primary="Q&A" />
			</ListItemButton>
		</List>
	)
}

export default MenuList
