import { Button } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { connect, useDispatch } from 'react-redux'
import EditModeButton from '../../components/EditModeButton'
import { t } from '../../locale'
import { clearScenario } from '../../store/HouseContainer/operations'
import { getScenarios } from '../../store/ScenarioById/selectors'
import { toggleEditScenario } from '../../store/SettingContainer/operations'
import { Scenario, ScenarioFormField, State } from '../../types'
import ScenarioForm from './ScenarioForm'
import ScenarioRow from './ScenarioRow'

type OProps = {
	houseId: number
}
type Props = {
	houseId: number
	scenarios: Scenario[]
	editMode: boolean
	toggleEditScenario: () => void
}

function ScenarioList(props: Props) {
	const dispatch = useDispatch()
	const newScenario: ScenarioFormField = {
		houseId: props.houseId,
		name: '',
		isNew: true,
	}
	const isOff = props.scenarios.every((p) => !p.isActive)

	return (
		<React.Fragment>
			<div style={{ display: 'flex' }}>
				<Typography variant="h6" style={{ flex: 1 }}>
					{t.scenario.base}一覧
				</Typography>
				<EditModeButton
					handleClick={props.toggleEditScenario}
					editMode={props.editMode}
				/>
			</div>
			{props.scenarios.length === 0 ? (
				<Typography variant="subtitle1">
					{t.scenario.base}がありません
				</Typography>
			) : (
				<List>
					<ListItem>
						<Button
							variant="outlined"
							disabled={isOff}
							size="small"
							onClick={() => {
								dispatch(clearScenario(props.houseId))
							}}
						>
							{t.scenario.base}をオフにする
						</Button>
					</ListItem>
					{props.scenarios.map((scenario, i) =>
						props.editMode ? (
							<ListItem key={i}>
								<ScenarioForm scenario={scenario} />
							</ListItem>
						) : (
							<ScenarioRow scenario={scenario} key={i} keyi={i} />
						),
					)}
				</List>
			)}
			{props.editMode && <ScenarioForm scenario={newScenario} />}
		</React.Fragment>
	)
}

const ms = (state: State, op: OProps) => ({
	houseId: op.houseId,
	scenarios: getScenarios(state, op.houseId),
	editMode: state.SettingContainer.editScenario,
})

const conn = connect(ms, {
	toggleEditScenario,
})

export default conn(ScenarioList)
