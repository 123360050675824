import { Typography } from '@mui/material'
import moment from 'moment'
import * as React from 'react'
import Chart from 'react-google-charts'
import { DayChartLine, Log } from '../../types'

type Option = Chart['props']['options']

type Props = {
	logs: Log[]
	yTicks: number[]
	lines: DayChartLine[]
}

function DayChartGraphNew(props: Props) {
	const { logs, lines } = props
	const hideTicks = lines.length > 2

	if (logs.length === 0) {
		return (
			<Typography style={{ paddingTop: '1rem' }}>ログはありません</Typography>
		)
	}

	// const ticks = makeJustTimeTicks(logs)

	// const get = <T, >() => (o: T) =>
	const header = ['時間', ...lines.map((l) => l.dataKey)]
	const data = logs.map((log) => [
		log.timestamp,
		...lines.map((v) => log[v.dataKey]),
	])

	console.log(data)
	const start = moment(logs[0].timestamp).startOf('date')
	const end = start.endOf('date')
	const colors = lines.map((l) => l.stroke)

	const options: Option = {
		legend: { position: 'none' },
		chartArea: { width: '80%', height: '80%' },
		series: lines.map((line, i) => ({
			targetAxisIndex: i,
			// axis: units.join('\n')
		})),
		colors,
		vAxes: {},
		hAxis: {
			viewWindow: { max: end, min: start },
			format: 'dd日\nHH:mm',

			textStyle: {
				fontSize: 8,
			},
		},
	}

	lines.forEach((line, i) => {
		options.vAxes[i] = {
			// viewWindow: { max: size.max, min: size.min },
			...{ ...(hideTicks ? { textPosition: 'none' } : {}) },
			textStyle: {
				color: line.stroke,
			},
		}
	})

	return (
		<>
			<Chart
				chartType="LineChart"
				data={[header, ...data]}
				loader={<div>ロード中</div>}
				width="100%"
				height="400px"
				options={options}
			/>
		</>
	)
}

DayChartGraphNew.defaultProps = {
	yTicks: undefined,
}

export default DayChartGraphNew
