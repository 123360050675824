import { connect } from 'react-redux'
import HouseTabs from '../../components/HouseTabs'
import {
	changeTabAndCloseDrawer,
	closeDrawer,
	openDrawer,
} from '../../store/Drawer/operations'
import { getDrawerIsOpen } from '../../store/Drawer/selectors'
import { getSelectedTab } from '../../store/SettingContainer/selectors'
import { State } from '../../types'

const ms = (state: State) => {
	return {
		isOpen: getDrawerIsOpen(state),
		selectedTab: getSelectedTab(state),
	}
}

const conn = connect(ms, {
	onSelectTab: changeTabAndCloseDrawer,
	onCloseDrawer: closeDrawer,
	onOpenDrawer: openDrawer,
})

export default conn(HouseTabs)
