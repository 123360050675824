import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import * as React from 'react'

type Props = {
	day: string
	handleSubmit: (day: string) => void
	handleSubmitToday: () => void
	isToday: boolean
}

class LogDateSelector extends React.Component<Props> {
	render() {
		const { props } = this

		return (
			<>
				<TextField
					id="log_view_date"
					// style={{ width: '142px' }}
					type="date"
					value={props.day}
					InputLabelProps={{}}
					onChange={(e) => {
						props.handleSubmit(e.target.value)
					}}
				/>

				<Button
					onClick={props.handleSubmitToday}
					variant="contained"
					disabled={props.isToday}
				>
					今日に戻る
				</Button>
			</>
		)
	}
}

export default LogDateSelector
