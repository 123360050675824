import SettingsIcon from '@mui/icons-material/Settings'
import { Drawer, Fab } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import Modal from '../../components/Modal'
import { t } from '../../locale'
import { Control, EventFull, House, Scenario } from '../../types'
import ManageScenarioModal from '../HomePage/components/ManageScenarioModal'
import { useJump } from '../../store/App/operations'
import CreateEventFormContainer from './CreateEventFormContainer'
import CreateTriggerFormContainer from './CreateTriggerFormContainer'
import EventListContainer from './EventListContainer'
import TriggerListContainner from './TriggerListContainner'

type Props = {
	isLoading: boolean
	house: House
	control: Control | null
	scenario: Scenario | null
	eventss: EventFull[]
}

function EventManageTab({
	house,
	scenario,
	control,
	eventss,
	isLoading,
}: Props) {
	const [isDrawerOpen, setIsDrawerlOpen] = React.useState<boolean>(false)
	const [createEventModal, setCreateEventModal] = React.useState<boolean>(false)
	const [triggerListModal, setTriggerListModal] = React.useState<boolean>(false)
	const [selectedSensorId, setSelectedSensorId] = React.useState<number>(1)
	const [createTriggerModal, setCreateTriggerModal] =
		React.useState<boolean>(false)
	const { jumpHousePage } = useJump()

	if (!scenario || !control) {
		return (
			<div>
				<Typography variant="h4" style={{ flex: 1 }}>
					ページは削除されたか存在しません
				</Typography>
				<Button onClick={() => jumpHousePage(house.id)}>←{house.name}</Button>
			</div>
		)
	}
	const closeDrawer = () => setIsDrawerlOpen(false)

	return (
		<>
			<div>
				<Typography variant="h6">{t.event.base}一覧</Typography>
				<EventListContainer
					disabled={isLoading}
					eventss={eventss.filter((e) => e.subControl.controlId === control.id)}
				/>
			</div>
			<Fab
				size="medium"
				onClick={() => {
					setIsDrawerlOpen(true)
				}}
			>
				<SettingsIcon />
			</Fab>
			<Drawer open={isDrawerOpen} anchor="bottom" onClose={closeDrawer}>
				<ManageScenarioModal
					closeModal={closeDrawer}
					isOpen={isDrawerOpen}
					handleCreateEvent={() => {
						closeDrawer()
						setCreateEventModal(true)
					}}
					handleTriggerList={() => {
						closeDrawer()
						setTriggerListModal(true)
					}}
					handleCreateTrigger={() => {
						closeDrawer()
						setCreateTriggerModal(true)
					}}
				/>
			</Drawer>
			<Modal
				open={createEventModal}
				closeModal={() => setCreateEventModal(false)}
			>
				<CreateEventFormContainer
					house={house}
					scenarioId={scenario.id}
					controlId={control.id}
				/>
			</Modal>
			<Modal
				open={triggerListModal}
				closeModal={() => setTriggerListModal(false)}
			>
				<TriggerListContainner
					house={house}
					handleCreateTrigger={(sensorId) => {
						setSelectedSensorId(sensorId)
						setTriggerListModal(false)
						setCreateTriggerModal(true)
					}}
				/>
			</Modal>
			<Modal
				open={createTriggerModal}
				closeModal={() => setCreateTriggerModal(false)}
			>
				<CreateTriggerFormContainer
					house={house}
					initSensorId={selectedSensorId}
				/>
			</Modal>
		</>
	)
}

export default EventManageTab
