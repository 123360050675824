import { useNavigate } from 'react-router-dom'
import { ThunkAction } from '../../types'

export const refInit = (): ThunkAction => {
	return (dispatch) => {}
}

export const useJump = () => {
	const navigate = useNavigate()

	const jumpMachinePage = (
		houseId: number,
		scenarioId: number,
		machineId: number,
	) =>
		navigate(`/houses/${houseId}/scenarios/${scenarioId}/machines/${machineId}`)

	const jumpHousePage = (houseId: number) => navigate(`/houses/${houseId}`)

	const jumpScenarioPage = (houseId: number, scenarioId: number) =>
		navigate(`/houses/${houseId}/scenarios/${scenarioId}`)

	return { jumpMachinePage, jumpHousePage, jumpScenarioPage }
}
