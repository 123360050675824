const { NODE_ENV } = process.env
let { REACT_APP_API_URL } = process.env

if (!REACT_APP_API_URL) {
	console.warn('Configuration not completed. must setup envioraments.')
	REACT_APP_API_URL = '-'
}

const isDev = NODE_ENV === 'development'

const configDevelopment = {
	appName: 'pocket farm (Dev)',
}
const configProduction = {
	appName: 'pocket farm',
}

const config = {
	isDev,
	appPath: '/',
	dayGraphDecimatingMin: 10,
	activeJudgeSpan: { minutes: 5 },
	api: {
		url: REACT_APP_API_URL,
	},
	tabBarHeight: 40,
	stepCount: 6,
	...(isDev ? configDevelopment : configProduction),
}

export default config

export const RELEASE_NOTE_URL =
	'https://planckunits.notion.site/PocketFarm-cf6ef8e4b3194701aac5076184ddf1d0'
export const QA_URL =
	'https://planckunits.notion.site/PocketFarm-Q-A-8c6fa2f4b9fd42a5b1bcd1ec5713739f'

export const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION || '-'
