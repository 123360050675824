import React from 'react'
import { useDispatch } from 'react-redux'
import {
	deleteEvent,
	toggleEventEnabled,
} from '../../store/HouseContainer/operations'
import { Event, EventFull } from '../../types'
import EventList from './components/EventList'

type Props = {
	eventss: EventFull[]
	disabled: boolean
}

const EventListContainer = (props: Props) => {
	const dispatch = useDispatch()

	return (
		<EventList
			eventss={props.eventss}
			disabled={props.disabled}
			handleDeleteEvent={(e: Event) => dispatch(deleteEvent(e.id))}
			handleToggleEnabled={(event: Event) =>
				dispatch(toggleEventEnabled(event))
			}
		/>
	)
}

export default EventListContainer
