import * as React from 'react'
import { connect } from 'react-redux'
import { refInit } from '../../store/App/operations'

type Props = {
	refInit: () => void
}

class Init extends React.Component<Props> {
	componentDidMount() {
		this.props.refInit()
	}

	render() {
		return null
	}
}

const ms = () => ({})
const conn = connect(ms, { refInit })

export default conn(Init)
