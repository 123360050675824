import { Button } from '@mui/material'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { runSubControl } from '../../store/HouseContainer/operations'
import { getSubControls } from '../../store/SubControlById/selectors'
import { Control } from '../../types'

type Props = {
	control: Control
}

const BoolMachineToogleButton = (props: Props) => {
	const subControls = useSelector((s) => getSubControls(s, props.control.id))
	const dispatch = useDispatch()

	const onControl = subControls.find((v) => v.rate === 100)
	const offControl = subControls.find((v) => v.rate === 0)

	return (
		<>
			{onControl && (
				<Button
					variant="contained"
					onClick={() => {
						if (!window.confirm('自動制御がオフになりますが実行しますか？'))
							return
						dispatch(runSubControl(onControl))
					}}
				>
					ON
				</Button>
			)}
			{offControl && (
				<Button
					variant="contained"
					onClick={() => {
						if (!window.confirm('自動制御がオフになりますが実行しますか？'))
							return
						dispatch(runSubControl(offControl))
					}}
				>
					OFF
				</Button>
			)}
		</>
	)
}

export default BoolMachineToogleButton
