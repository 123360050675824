import _ from 'lodash'
import moment from 'moment'
import config from '../config'
import {
	BoolMachine,
	BoolMachineCategory,
	Control,
	Log,
	Machine,
	MachineCategory,
	Sensor,
	StepMachine,
	StepRate,
	Trigger,
} from '../types'

export function sleep(msec: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, msec))
}

export const SENSOR = {
	TMP: 1,
	HMD: 2,
	CO2: 3,
	PPM: 4,
	PSR: 5,
	WAT: 6,
	LUX: 7,
}
export const sensorLib: { [n: number]: Sensor } = {
	1: {
		id: 1,
		label: '気温',
		unit: '℃',
		key: 'tmp',
	},
	2: {
		id: 2,
		label: '湿度',
		unit: '%',
		key: 'hmd',
	},
	3: {
		id: 3,
		label: 'CO2',
		unit: 'ppm',
		key: 'co2',
	},
	4: {
		id: 4,
		label: '気圧',
		unit: 'hPa',
		key: 'psr',
	},
	5: {
		id: 5,
		label: '雨',
		unit: '',
		key: 'wat',
	},
	6: {
		id: 6,
		label: '日射量',
		unit: 'W',
		key: 'lux',
	},
}

export const sensorLibByKey: { [key: string]: Sensor } = _.keyBy(
	Object.values(sensorLib),
	'key',
)

export const graphSensorLabelByKey: Record<string, string> = {
	tmp: sensorLib[1].label,
	hmd: sensorLib[2].label,
	co2: sensorLib[3].label,
	psr: sensorLib[4].label,
	wat: sensorLib[5].label,
	lux: sensorLib[6].label,
	vpd: '飽差',
	dew: '露点',
}

export function makeDayLabel(daystring?: string): string {
	return moment(daystring).format('YYYY/MM/DD')
}

export function triggerToLabel(trigger: Trigger): string {
	return sensorLib[trigger.sensor].label
}

export function triggerToRuleText(trigger: Trigger): string {
	const { unit } = sensorLib[trigger.sensor]
	const { low, high } = trigger

	if (low && high) {
		return `${low}${unit}〜${high}${unit}`
	} else if (low) {
		return `${low}${unit}〜`
	} else if (high) {
		return `〜${high}${unit}`
	}
	return ``
}

export const isBoolMachineCategory = (
	key: MachineCategory | null,
): key is BoolMachineCategory =>
	['fan', 'heating', 'mist', 'co2', null].includes(key)
export const isStepMachineCategory = (key: MachineCategory | null) =>
	!isBoolMachineCategory(key)
export function isBoolMachine(machine: Machine): machine is BoolMachine {
	return isBoolMachineCategory(machine.category)
}

export function isStepControl(machine: Machine): machine is StepMachine {
	return !isBoolMachine(machine)
}

export const categories: MachineCategory[] = [
	'curtain',
	'window',
	'fan',
	'mist',
	'heating',
	'co2',
]
export function isMachineCategory(s: string): s is MachineCategory {
	return s in categories
}

export const categoryStr: Record<MachineCategory, string> = {
	curtain: 'カーテン',
	window: '窓',
	fan: 'ファン',
	mist: 'ミスト',
	heating: 'ヒーター',
	co2: 'CO2',
	none: '未設定',
}

export const compTrigger = (trigger: Trigger) => [
	trigger.sensor,
	trigger.low,
	trigger.high,
]

export const compControl = (control: Control) => [
	categories.indexOf(control.category),
	control.label,
]

function isStepRate(sr: number): sr is StepRate {
	return sr in [0, 1, 2, 3, 4, 5, 6]
}

export const toStepRate = (rate: number) => {
	const sr = Math.ceil((rate * config.stepCount) / 100)

	return isStepRate(sr) ? sr : 0
}

export const toj = (a: object) => JSON.stringify(a)

export function download(text: string, name: string) {
	const bom = '\uFEFF'
	const href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(bom + text)
	const a = document.createElement('a')

	a.setAttribute('href', href)
	a.setAttribute('download', name + '.csv')
	if (document.body) document.body.appendChild(a)
	a.click()
	a.remove()
}

export function makeCSV(logs: Log[]): string {
	const log = _.last(logs)

	if (!log) {
		return 'noLog'
	}
	const fieldKeys: (keyof typeof log)[] = [
		'tmp',
		'hmd',
		'co2',
		'dew',
		'lux',
		'psr',
	]

	const head = ['timestamp', 'timestampStr', ...fieldKeys]

	const toLine = (log: Log) => {
		return [
			log.timestamp,
			moment(log.timestamp).format('YYYY-MM-DD HH:mm:ss'),
			...fieldKeys.map((k) => (log[k] === undefined ? '' : log[k])),
		].map(String)
	}
	const cells = [head, ...logs.map(toLine)]

	return cells.map((vs) => vs.join(',')).join('\n')
}

export function downloadCSV(name: string, day: string, logs: Log[]) {
	download(makeCSV(logs), `pocketfarm_${name}_${day}`)
}

const { NODE_ENV } = process.env

export const isDev = NODE_ENV === 'development'
