import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	ListItemSecondaryAction,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { FormikErrors, useFormik } from 'formik'
import React, { useState } from 'react'
import { t } from '../../../locale'
import { EditEventCustomField, SubControl, Trigger } from '../../../types'

type Fields = EditEventCustomField
type Props = {
	scenarioId: number
	triggers: Trigger[]
	subControls: SubControl[]

	disabled: boolean
	handleSubmit: (fields: Fields) => void
}

const CreateEventForm = (props: Props) => {
	const { scenarioId, triggers, subControls, disabled } = props
	const [allDay, setAllDay] = useState<boolean>(false)
	// const [enableTrigger, setEnableTrigger] = useState<boolean>(false)
	// const [enableInterval, setEnableInterval] = useState<boolean>(false)

	const {
		values,
		errors,
		touched,
		handleChange,
		handleSubmit,
		setFieldTouched,
	} = useFormik<Fields>({
		initialValues: {
			scenarioId,
			triggerId: null,
			subControlId: subControls[0]?.id,
			activeInterval: null,
			negativeInterval: null,
			startTime: '00:00',
			endTime: '23:59',
			exclusive: false,
		},
		validate: (values) => {
			const errors: FormikErrors<Fields> = {}

			if (!allDay) {
				if (values.startTime > values.endTime) {
					errors.endTime = '開始時間より遅くなっています。'
				}
			}

			return errors
		},
		onSubmit: (fields: Fields) => {
			if (allDay) {
				fields.startTime = '00:00'
				fields.endTime = '23:59'
			}
			props.handleSubmit(fields)
		},
	})

	if (!subControls.length)
		return (
			<Typography color="error">
				{t.control.base}を作成してください。
			</Typography>
		)
	const change = (name: keyof Fields, e: React.ChangeEvent<unknown>) => {
		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
	}
	const select = (name: keyof Fields, e: SelectChangeEvent<unknown>) => {
		handleChange(e)
		setFieldTouched(name, true, false)
	}

	return (
		<div>
			<Typography variant="h6">{t.event.base}を作成</Typography>
			<List>
				<ListItem divider>
					<ListItemText secondary={'トリガー'} />
					<ListItemSecondaryAction>
						<FormControl>
							<Select
								value={values.triggerId}
								name="triggerId"
								disabled={disabled}
								onChange={select.bind(null, 'triggerId')}
								style={{ width: '200px' }}
							>
								{triggers.map((trigger, key) => {
									return (
										<MenuItem key={key} value={trigger.id}>
											<div
												style={{
													display: 'grid',
													justifyContent: 'space-between',
												}}
											>
												<Typography>{trigger.label}</Typography>
												<Typography>{trigger.ruleText}</Typography>
											</div>
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem divider>
					<ListItemText secondary={t.control.base} />
					<ListItemSecondaryAction>
						<FormControl>
							<Select
								required
								value={values.subControlId}
								name="subControlId"
								disabled={disabled}
								onChange={select.bind(null, 'subControlId')}
								error={touched.subControlId && Boolean(errors.subControlId)}
								style={{ width: '200px' }}
							>
								{props.subControls.map((subControl, key) => {
									return (
										<MenuItem key={key} value={subControl.id}>
											{subControl.label}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem>
					<ListItemText secondary={'時間'} />
					<ListItemSecondaryAction>
						<FormControlLabel
							control={
								<Checkbox onChange={(e) => setAllDay(e.target.checked)} />
							}
							label="全日"
						/>
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem>
					<ListItemText secondary={'開始時間'} />
					<ListItemSecondaryAction>
						<TextField
							type="time"
							name="startTime"
							value={values.startTime}
							disabled={disabled || allDay}
							onChange={change.bind(null, 'startTime')}
							inputProps={{
								step: 300, // 5 min
								textAlign: 'right',
								'data-test': 'event-start-time-input',
							}}
						/>
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem divider>
					<ListItemText secondary={'終了時間'} />
					<ListItemSecondaryAction>
						<TextField
							type="time"
							name="endTime"
							value={values.endTime}
							onChange={change.bind(null, 'endTime')}
							error={touched.endTime && Boolean(errors.endTime)}
							helperText={touched.endTime && errors.endTime}
							disabled={disabled || allDay}
							inputProps={{
								step: 300, // 5 min
								textAlign: 'right',
								'data-test': 'event-end-time-input',
							}}
						/>
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem divider>
					<ListItemText secondary={'オン時間(分)'} />
					<ListItemSecondaryAction>
						<TextField
							type="number"
							name="activeInterval"
							value={values.activeInterval}
							onChange={change.bind(null, 'activeInterval')}
						/>
					</ListItemSecondaryAction>
				</ListItem>
				<ListItem divider>
					<ListItemText secondary={'オフ時間(分)'} />
					<ListItemSecondaryAction>
						<TextField
							type="number"
							name="negativeInterval"
							value={values.negativeInterval}
							onChange={change.bind(null, 'negativeInterval')}
						/>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
			<div style={{ textAlign: 'right' }}>
				<Button onClick={() => handleSubmit()} color="primary" size={'large'}>
					追加する
				</Button>
			</div>
		</div>
	)
}

export default CreateEventForm
